var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "yxtbizricheditorbox",
      staticClass: "yxtbiz-richeditor-box-template",
    },
    [
      _c(
        "yxt-dialog",
        {
          attrs: {
            visible: _vm.centerDialogVisible,
            "show-close": false,
            width: "440px",
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "yxtbiz-richeditor-dialog" },
            [
              _c("h2", { staticClass: "yxtbiz-richeditor-dialog-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm
                        .$t("pc_biz_richeditor_tips_1")
                        .d("内容正在同步中，请耐心等待")
                    ) +
                    "\n      "
                ),
              ]),
              _c("p", { staticClass: "yxtbiz-richeditor-dialog-desc" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm
                        .$t("pc_biz_richeditor_tips_2")
                        .d(
                          "在同步过程中，请勿关闭或刷新界面，避免内容同步失败；"
                        )
                    ) +
                    "\n      "
                ),
              ]),
              _c("p", { staticClass: "yxtbiz-richeditor-dialog-desc" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm
                        .$t("pc_biz_richeditor_tips_3")
                        .d("若不需要同步，可点击取消关闭同步。")
                    ) +
                    "\n      "
                ),
              ]),
              _c("yxt-progress", {
                staticStyle: { "margin-top": "24px" },
                attrs: { percentage: _vm.speed, type: "circle" },
              }),
              _c(
                "div",
                { staticStyle: { "margin-top": "32px" } },
                [
                  _c("yxt-button", { on: { click: _vm.cancelSync } }, [
                    _vm._v(
                      _vm._s(_vm.$t("pc_biz_richeditor_tips_4").d("取消同步"))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { ref: "editor", staticClass: "yxtbiz-richeditor" }),
      _c("yxtbiz-upload", {
        ref: "bizUpload",
        attrs: {
          "config-key": "ImageConfigKey",
          "app-code": "kng",
          "module-name": "test_module",
          "function-name": "test_function",
          multipe: _vm.multipe,
          isV1: _vm.isV1,
          "on-uploaded": _vm.onUploaded,
          "on-progress": _vm.onProgress,
          "on-error": _vm.onError,
        },
      }),
      _vm.drawer
        ? _c(
            "div",
            [
              _c("yxtbiz-i18n-lang", {
                attrs: { visible: _vm.drawer, "data-list": _vm.dataObj },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                  confirm: _vm.getResult,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }