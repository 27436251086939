<template>
  <div class="yxtbiz-richeditor-box-template" ref="yxtbizricheditorbox">
    <yxt-dialog
      :visible.sync="centerDialogVisible"
      :show-close="false"
      width="440px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <div class="yxtbiz-richeditor-dialog">
        <h2 class="yxtbiz-richeditor-dialog-title">
          {{ $t('pc_biz_richeditor_tips_1').d('内容正在同步中，请耐心等待') }}
        </h2>
        <p class="yxtbiz-richeditor-dialog-desc">
          {{ $t('pc_biz_richeditor_tips_2').d('在同步过程中，请勿关闭或刷新界面，避免内容同步失败；') }}
        </p>
        <p class="yxtbiz-richeditor-dialog-desc">
          {{ $t('pc_biz_richeditor_tips_3').d('若不需要同步，可点击取消关闭同步。') }}
        </p>
        <yxt-progress style="margin-top: 24px;" :percentage="speed" type="circle"></yxt-progress>
        <div style="margin-top: 32px;">
          <yxt-button @click="cancelSync">{{ $t('pc_biz_richeditor_tips_4').d('取消同步') }}</yxt-button>
        </div>
      </div>
    </yxt-dialog>
      <div class="yxtbiz-richeditor" ref="editor"></div>
      <yxtbiz-upload
        ref="bizUpload"
        config-key="ImageConfigKey"
        app-code="kng"
        module-name="test_module"
        function-name="test_function"
        :multipe="multipe"
        :isV1="isV1"
        :on-uploaded="onUploaded"
        :on-progress="onProgress"
        :on-error="onError">
      </yxtbiz-upload>
      <div v-if="drawer">
        <yxtbiz-i18n-lang :visible.sync="drawer" :data-list="dataObj" @confirm="getResult"></yxtbiz-i18n-lang>
      </div>
  </div>
</template>

<script>
import { fileApi } from 'yxt-biz-pc/packages/api';
import { fileUpload } from './service';
import WangEditor from './wangEditor';
import { getLanguage } from 'yxt-i18n/es';
import XSS from './xss';

// fecenter上报错误-135错误监控
const fecentrLog_135 = (monitor_info = {}) => {
  try {
    window.yxtRPT && window.yxtRPT.report({
      category: 497,
      msg: '135编辑器图片地址转换监控',
      c1: JSON.stringify({
        info: monitor_info,
        device: 'pc',
        username: window.localStorage.username,
        orgId: window.localStorage.orgId
      })
    });
  } catch (error) {
    console.error(error);
  }
};

export default {
  name: 'YxtbizRicheditor',
  props: {
    source: String, // 产线source
    appCode: { // 微应用编号
      type: String,
      required: true
    },
    orgCode: {
      type: String,
      required: true
    },
    moduleName: {
      type: String,
      required: true
    },
    functionName: {
      type: String,
      required: true
    },
    content: String,
    menus: {
      type: Array,
      default: () => {
        return ['head', 'bold', 'fontSize', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript', 'foreColor', 'backColor', 'link', 'list', 'justify', 'image', 'table', 'undo', 'redo'];
      }
    },
    linkImg: Boolean,
    maxLength: {
      type: Number,
      validator: value => {
        return value > 0;
      }
    },
    minLength: {
      type: Number,
      validator: value => {
        return value > 0;
      }
    },
    // oppo私有化为true，sass为false
    filterContent: {
      type: Boolean,
      default: false
    },
    // 需要透传到upload
    isV1: {
      type: Boolean,
      default: false
    },
    toolsHeight: { // 工具条div高度
      type: String,
      default: ''
    },
    autoFocus: { type: Boolean, default: true }, // 设置编辑器文本内容时，编辑器是否自动获取焦点
    showI18nBtn: {
      type: Boolean,
      default: false
    },
    transObj: {
      type: Object,
      default: () => {}
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      speed: 0,
      isCancelSync: false,
      centerDialogVisible: false,
      multipe: true,
      wangEditor: null,
      drawer: false
    };
  },
  watch: {
    content(newContent) {
      const html = this.filterContent ? this.wangEditor.filterXss(newContent) : newContent;
      if (this.wangEditor.isFocus) {
        return;
      }
      this.setHtml(html);
    }
  },
  created() {
    window.editor = this;
    if (this.source) { // 如果使用上传组件时传入source，则覆盖项目引用业务组件时传入的source
      fileApi.defaults.headers.source = this.source;
    }
  },
  mounted() {
    window.showI18nBtn = this.showI18nBtn;
    this.wangEditor = new WangEditor(this.$refs.editor, null, this);
    this.wangEditor.customConfig.showLinkImg = !!this.linkImg;
    this.wangEditor.customConfig.minLength = this.minLength;
    this.wangEditor.customConfig.maxLength = this.maxLength;
    this.wangEditor.customConfig.autoFocus = this.autoFocus;
    this.wangEditor.customConfig.initSelection = false; // 是否初始化的时候默认出来光标

    if (this.menus) {
      this.wangEditor.customConfig.menus = this.menus;
    }

    this.wangEditor.onpasteImg = (files) => {
      this.$refs.bizUpload.addFileList(files);
    };

    // 复制粘贴
    this.wangEditor.transformImgUrl = this.transformImgUrl;

    this.wangEditor.customConfig.customUploadImg = (files, insert) => {
      if (typeof (files) === 'string') {
        insert(files);
      }
    };

    this.wangEditor.customConfig.onchange = (html) => {
      // html 即变化之后的内容
      // html 变化，触发事件之前，用 '<div class='reset-default-style-for-edit'></div>' 包裹一下html
      // 原因： html中的样式在展示的时候，需要有一个css命名空间，让富文本样式生效
      this.$emit('change', `<div class='reset-default-style-for-edit'>${html}</div>`);

      const formItem = this.getFormItem(this);
      formItem && formItem.$emit('yxt.form.change');
    };

    this.wangEditor.customConfig.onfocus = (html) => {
      this.$emit('focus');
    };

    this.wangEditor.customConfig.onblur = (html) => {
      // html 即编辑器中的内容
      this.$emit('blur', html);

      const formItem = this.getFormItem(this);
      formItem && formItem.$emit('yxt.form.blur');
    };

    this.wangEditor.create();
    // 获取父元素的class元素
    if (this.$refs.yxtbizricheditorbox.getElementsByClassName('w-e-icon-image')[0]) { // 防止未使用图片功能进行报错
      this.$refs.yxtbizricheditorbox.getElementsByClassName('w-e-icon-image')[0].onclick = () => {
        this.$refs.yxtbizricheditorbox.getElementsByClassName('yxtbiz-upload')[0].click();
      };
    }
    this.content && this.setHtml(this.content);
    // 是否只读
    if (this.disable) {
      this.wangEditor.menus._disabled = true;
      this.wangEditor.$textElem.attr('contentEditable', false);
    }
    this.i18nInit();
  },
  computed: {
    dataObj() {
      let trans = [];
      let dataObj = this.deepClone(this.transObj);
      if (typeof this.transObj.trans !== 'object') {
        trans = [
          {
            langTag: this.locale,
            transContent: this.transObj.trans || ''
          }
        ];
      } else {
        Array.isArray(this.transObj.trans) ? trans = this.transObj.trans : trans.push(this.transObj.trans);
      }
      dataObj.trans = trans;
      return dataObj;
    }
  },
  methods: {
    cancelSync() {
      this.centerDialogVisible = false;
      this.isCancelSync = true;
    },
    async sleep(time = 0) {
      return new Promise((res) => {
        setTimeout(() => {
          res();
        }, time);
      });
    },
    async transformImgUrl(pasteHtml) {
      const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+(135editor)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      const matchUrls = pasteHtml.match(reg);
      if (!matchUrls) return pasteHtml;
      let newPasteHtml = pasteHtml.replace(reg, '{-imgUrl-}');
      console.log('pasteHtml', pasteHtml);
      console.log('matchUrls', matchUrls);
      // 开始同步
      this.centerDialogVisible = true;
      this.speed = 0;
      const newUrls = Array(matchUrls.length).fill(undefined);
      const speed = Math.floor(100 / newUrls.length);
      for (let i = 0; i < matchUrls.length; i++) {
        if (this.isCancelSync) break; // 如果点了取消同步，则后续没执行的链接替换操作全部不执行
        const url = matchUrls[i];
        const param = `?configkey=ImageConfigKey&filefullpath=${url}&buckettype=1&isneedconvert=0&platform=baidu`;
        try {
          const res = await fileUpload(param);
          newUrls[i] = `${res.fileDomain}${res.fileKey}`;
          console.log(res, '12312321321312', '当前次数：', i);
          this.speed += speed;
          // await this.sleep(1000);
        } catch (error) {
          console.log(error);
          fecentrLog_135({
            pasteHtml,
            matchUrls,
            newUrls,
            index: i,
            error
          });
        }
      }
      // 同步完成关闭弹框
      this.centerDialogVisible = false;
      if (this.isCancelSync) {
        // 如果是取消同步，就不做任何处理
        this.isCancelSync = false;
        return null;
      }
      fecentrLog_135({
        pasteHtml,
        matchUrls,
        newUrls
      });
      if (newUrls.length) {
        let count = 0;
        newPasteHtml = newPasteHtml.replace(/{-imgUrl-}/g, () => {
          const url = newUrls[count];
          count++;
          if (!url) return '';
          return url;
        });
        console.log('newPasteHtml', newPasteHtml);
        return newPasteHtml;

      }
      return pasteHtml;
    },
    i18nInit() {
      let ele = document.getElementsByClassName('i18n-icon');
      if (ele.length) {
        ele[0].onclick = () => {
          this.drawer = true;
        };
      }
    },
    deepClone(target) {
      if (!target || typeof target !== 'object') {
        return target;
      }
      const resObj = Array.isArray(target) ? [] : {};
      for (const k in target) {
        resObj[k] = this.deepClone(target[k]);
      }
      return resObj;
    },
    getResult(data) {
      this.transObj.trans = data.keys[0].trans.find(item => item.langTag === getLanguage()).transContent;
      this.drawer = false;
      this.$emit('update:visible', false);
      this.$emit('confirm', data.keys[0].trans);
    },
    onProgress(file, progress) {
      const progressBar = new WangEditor.Progress(this.wangEditor);
      progressBar.show(progress);
    },
    getFormItem(vm) {
      let formItem = null;
      let root = vm.$root;
      let parent = vm.$parent;
      while (parent && parent !== root) {
        if (parent.$options.componentName === 'YxtFormItem') {
          formItem = parent;
          break;
        }
        parent = parent.$parent;
      }
      return formItem;
    },
    tmpFn() {
      console.log(XSS, 'XSS');
    },
    async setHtml(html) { // 设置编辑器html内容
      const res = await this.transformImgUrl(html);
      if (!res) return;
      html = res;
      if (html && html.indexOf('<') !== 0) {
        // 非富文本文字会导致不能换行
        html = '<p>' + html + '</p>';
      }
      // 由于上次编辑的文本内容  有可能会包裹 <div class='reset-default-style-for-edit'></div>,
      // 所以在需要把被包裹的内容拿出来，再放入 富文本中
      if (html.indexOf("<div class='reset-default-style-for-edit'>") === 0) {
        html = html.replace("<div class='reset-default-style-for-edit'>", '').replace(/<\/div>$/, '');
      }
      const content = this.filterContent ? (window.editorFilterXSS ? window.editorFilterXSS(html, {stripIgnoreTag: true}) : this.wangEditor.filterXss(html)) : html;
      this.wangEditor.txt.html(content);
    },
    append(content) { // 追加内容
      this.wangEditor.txt.append(this.getXssHtml(content));
    },
    getHtml(disabledXss) { // 获取编辑器区域完整html内容
      let result = this.wangEditor.txt.html();
      result = result.replace(/(<p><br><\/p>)+$/i, ''); // 去掉尾部空行
      result = result.replace(/(<i>)/g, '<i style="padding-right: 0.15em !important">'); // i标签加样式
      if (disabledXss) return result;
      result = this.wangEditor.filterXss(result); // 过滤Xss攻击代码
      return result;
    },
    getText() { // 获取编辑器纯文本内容
      return this.wangEditor.txt.text();
    },
    getXssHtml(content) {
      return window.editorFilterXSS ? window.editorFilterXSS(content, {stripIgnoreTag: true}) : content;
    },
    getInnerText() { // 获取编辑器未转义纯文本内容
      const html = this.getHtml();
      const elem = document.createElement('div');
      elem.innerHTML = this.getXssHtml(html);
      return elem.innerText;
    },
    getTextLength() { // 获取编辑器纯文本内容的长度，转义字符还原计算
      return this.getText().replace(/&.*?;/ig, '+').length; // 把&nbsp;类似的转义字符替换为+计算内容长度
    },
    onUploaded(file) {
      this.wangEditor.uploadImg.uploadImg(file.fullUrl);
    },
    onError() {}
  }
};
</script>

<style lang="scss">
.yxtbiz-richeditor-dialog {
  text-align: center;
  &-title {
    font-size: 20px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 28px;
    margin-bottom: 12px !important;
  }
  &-desc {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #757575;
    line-height: 22px;
    margin: 0;
  }
}

.yxtbiz-richeditor-box-template {
  height: 200px;
  .yxtbiz-richeditor {
    height: 100%;
  }
}
.w-e-text-container {
  i {
    padding-right: 0.15em !important;
  }
}
.yxt-form-item.is-error {
  .yxtbiz-richeditor {
    .w-e-text-container {
      border-color: red !important;
      i {
        padding-right: 0.15em !important;
      }
    }
    .w-e-toolbar {
      border-top-color: red!important;
      border-left-color: red!important;
      border-right-color: red!important;
    }
  }
}
.w-e-text-count-self {
  display: flex;
  .w-e-text-min,
  .w-e-text-max {
    flex: 1;
  }
  .i18n-icon-box {
    display: flex;
    align-items: center;
    .wordLimitSplit {
      // padding: 10px;
      // border-right: 1px solid red;
    }
    .i18n-icon {
      margin-left: 10px;
    }
  }
}
</style>
