var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-amap", style: _vm.style },
    [
      _c("div", {
        staticClass: "yxtbiz-amap__main map",
        attrs: { id: "yxtbiz-amap-container", tabindex: "0" },
      }),
      _vm.poi
        ? _c("yxt-scrollbar", { staticClass: "yxtbiz-amap__panel" }, [
            _c(
              "div",
              [
                _vm.poiInput
                  ? _c("yxt-input", {
                      attrs: {
                        id: "yxtbiz-amap-input",
                        placeholder: _vm.$t(
                          "pc_biz_amap_tip_search" /* 请输入内容 */
                        ),
                      },
                      on: { input: _vm.onPoiChange },
                      model: {
                        value: _vm.input,
                        callback: function ($$v) {
                          _vm.input = $$v
                        },
                        expression: "input",
                      },
                    })
                  : _vm._e(),
                _vm.poiResult
                  ? _c("div", {
                      staticClass: "yxtbiz-amap__results",
                      attrs: { id: "yxtbiz-amap-results" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }