class WaterMarkCanvas {
  constructor(options = {}) {
    this.canvas = null;
    this.canvasNodes = [];
    this.ctx = null;
    this.timer = null; // 定时器
    this.targetDom = options.el;
    const position = options.position;
    this.options = {
      text: options.text,
      randomRotate: options.randomRotate, // 是否随机滚动
      platformType: options.platformType, // 平台类型 pc / h5
      rotateAngle: 30,
      fontColor: options.fontColor,
      fontSize: options.fontSize,
      density: 1, // 密度等级，2: 高，1: 中，0: 低
      opacity: options.opacity, // 透明度，0 ～ 1
      speed: 0, // 动画速度等级
      width: options.width,
      height: options.height,
      currWidth: 0,
      currHeight: 0,
      position: {
        top: position && position.top || 0,
        left: position && position.left || 0
      }
    };
    this.init();
  }
  init() {
    this.createCanvasMap(this.options);
  }

  deleteCanvasNodes() {
    const canvasDoms = this.targetDom.querySelectorAll('.watermark_canvas_warp');
    canvasDoms.forEach(canvas => {
      if (canvas) canvas.remove();
    });
  }

  createCanvasMap({ randomRotate }) {
    // 创建画布
    this.deleteCanvasNodes();
    this.canvasNodes = [];
    const canvasheight = this.options.height || this.targetDom.offsetHeight;
    const count = Math.floor(canvasheight / 10000);
    const remainder = canvasheight % 10000;
    if (randomRotate) {
      // 随机滚动只允许一张画布
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      this.canvasNodes.push({
        canvas: canvas,
        ctx: ctx,
        height: canvasheight
      });
      this.appendToHtml();
      return;
    }
    for (let i = 0; i < count; i++) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      this.canvasNodes.push({
        canvas: canvas,
        ctx: ctx,
        height: 10000
      });
    }
    if (remainder > 0) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      this.canvasNodes.push({
        canvas: canvas,
        ctx: ctx,
        height: remainder
      });
    }
    let canvasTop = 0;
    this.canvasNodes.forEach(({ canvas, ctx, height }) => {
      this.updateCanvasStyle(canvas, ctx, height, canvasTop);
      canvasTop += height;
    });
    this.appendToHtml();
  }

  fillText(ctx, text = '', x = 0, y = 0, rotate = 0) {
    ctx.save();
    // ctx.textAlign = 'center'
    ctx.translate(x, y);
    ctx.rotate((-rotate * Math.PI) / 180);
    ctx.fillText(text, 0, 0);
    ctx.restore();
  }

  draw(options = {}) {
    if (this.timer) window.clearInterval(this.timer);
    Object.assign(this.options, options);
    console.log(this.options, '----');
    const { text, rotateAngle, density, randomRotate } = this.options;
    this.createCanvasMap({ randomRotate });
    let canvasTop = 0;
    this.canvasNodes.forEach(({ canvas, ctx, height }) => {
      this.clearMap(ctx);
      this.updateCanvasStyle(canvas, ctx, height, canvasTop);
      canvasTop += height;
      if (randomRotate) return this.randomRotateCanvas(canvas, ctx); // 随机滚动
      const points = this.densityPoints(density, ctx);
      points.forEach(point => {
        this.fillText(ctx, text, point[0], point[1], rotateAngle);
      });
    });
  }

  randomRotateCanvas(canvas, ctx) {
    const { text, currWidth, currHeight, speed = 0, fontSize } = this.options;
    const startDirection = Math.floor(Math.random() * 10 % 4); // 起始方向 0，1，2，3，表示上右下左

    const animationTime = [15, 12.5, 10, 7.5, 5];
    const fps = Math.floor(1000 / 60);
    const textWidth = ctx.measureText(text).width;
    const startX = Math.floor(Math.random() * currWidth);
    const startY = Math.floor(Math.random() * currHeight);
    const endX = Math.floor(Math.random() * (currWidth - startX));
    const endY = Math.floor(Math.random() * (currHeight - startY));

    let count = 0;
    let loopCount = animationTime[speed] * 60;

    let moveLenX = 0;
    let moveLenY = 0;

    let startPosition = [0, 0];

    let defaultSpeed = 1200 / loopCount;

    if ([0, 2].includes(startDirection)) {
      startPosition[0] = startX;
      startPosition[1] = 0 - fontSize; // 位置补正
      loopCount = Math.ceil((currHeight + fontSize) / defaultSpeed);
      moveLenX = (endX - startPosition[0]) / loopCount;
      moveLenY = defaultSpeed;
      if (startDirection === 2) {
        // 下方向
        startPosition[1] = currHeight;
        moveLenY = -defaultSpeed;
      }
    } else {
      startPosition[0] = 0 - textWidth; // 位置补正
      startPosition[1] = startY;
      loopCount = Math.ceil((currWidth + textWidth) / defaultSpeed);
      moveLenX = defaultSpeed;
      moveLenY = (endY - startPosition[1]) / loopCount;
      if (startDirection === 1) {
        // 右方向
        startPosition[0] = currWidth;
        moveLenX = -defaultSpeed;
      }
    }

    this.timer = window.setInterval(() => {
      if (count < loopCount) {
        count++;
        this.clearMap(ctx);
        this.fillText(ctx, text, startPosition[0], startPosition[1], 0);
        startPosition[0] = startPosition[0] + moveLenX;
        startPosition[1] = startPosition[1] + moveLenY;
      } else {
        window.clearInterval(this.timer);
        this.randomRotateCanvas(canvas, ctx);
      }
    }, fps);
  }

  updateCanvasStyle(canvas, ctx, elHeight, canvasTop) {
    let { offsetWidth: elWidth } = this.targetDom;
    this.targetDom.style.overflow = 'hidden';
    this.options.currWidth = this.options.width ? this.options.width : elWidth;
    this.options.currHeight = elHeight;
    const { fontColor, currWidth, currHeight, opacity, fontSize, position } = this.options;
    ctx.beginPath();
    // 设置canvas样式宽高
    canvas.style.width = (currWidth) + 'px';
    canvas.style.height = (currHeight) + 'px';
    canvas.style.opacity = opacity;

    canvas.style.position = 'absolute';
    canvas.style.top = position.top + canvasTop + 'px';
    canvas.style.left = position.left + 'px';
    canvas.style.zIndex = 9999;
    canvas.classList.add('watermark_canvas_warp');
    canvas.style.pointerEvents = 'none';

    // 设置canvas画布宽高为样式的2倍
    canvas.width = (currWidth) * 2;
    canvas.height = (currHeight) * 2;

    ctx.scale(2, 2); // 整体放大2倍
    ctx.textBaseline = 'top';
    ctx.fillStyle = fontColor;
    ctx.font = `${fontSize}px sans-serif`;
  }

  clearMap(ctx) {
    // 清空画布
    const { currWidth, currHeight } = this.options;
    ctx.clearRect(0, 0, currWidth, currHeight);
  }

  densityPoints(level, ctx) {
    switch (level) {
      case 2:
        return this.densityHeightPoints(ctx);
      case 1:
        return this.densityMidPoints(ctx);
      default:
        return this.densityLowPoints(ctx);
    }
  }

  densityH5Points(level) {
    const { currWidth, fontSize } = this.options;
    const pointConfig = {
      initPoint: [20, 100], // 第一个点位坐标，用于后续点位生成的基准坐标
      ySpace: 120, // 纵向间距
      xSpace: currWidth / 2 + 20, // 横向间隔
      col: 2, // 列数
      row: 20, // 行数
      topDiff: 120 / 2 - fontSize // y方向偏移量
    };

    if (level === 1) {
      pointConfig.initPoint = [20, 150];
      pointConfig.ySpace = 200;
      pointConfig.topDiff = 0;
    }

    if (level === 0) {
      pointConfig.initPoint = [100, 150];
      pointConfig.ySpace = 200;
      pointConfig.topDiff = 0;
      pointConfig.row = 20;
      pointConfig.col = 1;
    }

    const points = [];
    for (let i = 0; i < pointConfig.col; i++) {
      for (let j = 0; j < pointConfig.row; j++) {
        points.push([pointConfig.initPoint[0] + i * pointConfig.xSpace, pointConfig.initPoint[1] + j * pointConfig.ySpace - pointConfig.topDiff * i]);
      }
    }
    return points;
  }

  densityHeightPoints(ctx) {
    // 高密度
    const { platformType } = this.options;

    if (platformType === 'h5') {
      return this.densityH5Points(2);
    }

    const pointConfig = {
      initPoint: [100, 100], // 第一个点位坐标，用于后续点位生成的基准坐标
      ySpace: 300, // 纵向间距
      xSpace: 250, // 横向间隔
      col: 20, // 列数
      topDiff: 210 // y方向偏移量
    };

    const points = [];
    for (let i = 0; i < pointConfig.col; i++) {
      for (let j = 0; j < 400; j++) {
        points.push([pointConfig.initPoint[0] + i * pointConfig.xSpace, pointConfig.initPoint[1] + j * pointConfig.ySpace - pointConfig.topDiff * i]);
      }
    }
    return points;
  }

  densityMidPoints(ctx) {
    // 中密度
    const { currWidth, currHeight, platformType, text, rotateAngle } = this.options;
    const textWidth = ctx.measureText(text).width;
    const currTextWidth = Math.cos(Math.PI * rotateAngle / 180) * textWidth;
    const currTextHeight = Math.sin(Math.PI * rotateAngle / 180) * textWidth;
    // const diagonal = Math.sqrt(width * width + height * height)
    // const sX = Math.sin(Math.atan(width / height))
    // const sY = Math.cos(Math.atan(width / height))

    if (platformType === 'h5') {
      return this.densityH5Points(1);
    }

    const leftX = 10;
    const heightY = 200;
    const topDiff = 60; // 错位补正
    const points = [
      [leftX, heightY],
      [leftX, currHeight - topDiff],
      [(currWidth - currTextWidth) / 2, (currHeight + currTextHeight) / 2],
      [currWidth - 300, heightY],
      [currWidth - 300, currHeight - topDiff]
    ];
    return points;
  }

  densityLowPoints(ctx) {
    // 低密度
    const { currWidth, currHeight, platformType, text, rotateAngle } = this.options;
    const textWidth = this.ctx.measureText(text).width;
    const currTextWidth = Math.cos(Math.PI * rotateAngle / 180) * textWidth;
    const currTextHeight = Math.sin(Math.PI * rotateAngle / 180) * textWidth;
    const diagonal = Math.sqrt(currWidth * currWidth + currHeight * currHeight);
    const sX = Math.sin(Math.atan(currWidth / currHeight));
    const sY = Math.cos(Math.atan(currWidth / currHeight));

    if (platformType === 'h5') {
      return this.densityH5Points(0);
    }

    const offset = 200;
    const points = [
      [sX * offset - currTextWidth / 2, sY * offset + currTextHeight / 2],
      [(currWidth - currTextWidth) / 2, (currHeight + currTextHeight) / 2],
      [sX * (diagonal - offset) - currTextWidth / 2, sY * (diagonal - offset) + currTextHeight / 2]
    ];
    return points;
  }

  appendToHtml() {
    const fargment = document.createDocumentFragment();
    this.canvasNodes.forEach(node => {
      fargment.appendChild(node.canvas);
    });
    this.targetDom.appendChild(fargment);
  }

}

export default WaterMarkCanvas;
