<template>
  <div class="yxtbiz-doc-viewer-ppt" @copy="() => false">
    <div class="yxtbiz-doc-viewer-ppt__pages" ref="pages" :style="{width: scaleWidth ? scaleWidth + 'px' : null}">
      <div class="yxtbiz-doc-viewer-ppt__placeholder" :style="{'height': this.pageHeight ? this.pageHeight + 'px' : null, 'paddingBottom': count > 1 ? null : '10px', 'backgroundImage': loadingUrl ? `url(${loadingUrl})` : null}">
        <img :src="placeholderSrc" alt="">
      </div>
      <div class="yxtbiz-doc-viewer-ppt__page" :class="current === index + 1 ? 'yxtbiz-doc-viewer-ppt__page--show' : null"
        v-for="(item, index) in list" :ref="`page_${index + 1}`" :data-index="index" :key="`page_${index + 1}`">
        <img v-if="item.status === 'loading' || item.status === 'loaded'" :src="item.url" :alt="index + 1" @load="(e)=>{imageLoad(e,item, index)}">
      </div>
      <yxt-slider v-if="count > 1" v-model="silder" :min="1" :max="count"></yxt-slider>
      <yxtbiz-watermark :version="version" :app-code="appCode" :option="watermarkConfig"></yxtbiz-watermark>
    </div>
  </div>
</template>

<script>
import { Slider } from 'yxt-pc';
import YxtbizWatermark from 'yxt-biz-pc/packages/watermark';
import { throttle } from 'throttle-debounce';
import { getFileList } from '../service';

const cacheTime = 3000; // 混布链接缓存时间

/**
 * status 状态说明：
 *   waiting：等待获取地址
 *   pending：获取地址中
 *   canLoad：已有地址，可以加载
 *   loaded：已加载
 */
export default {
  name: 'PptViewer',
  components: {
    YxtbizWatermark,
    YxtSlider: Slider
  },
  props: {
    current: Number,
    scale: Number,
    showZoom: Boolean
  },
  model: {
    prop: 'current',
    event: 'currentChange'
  },
  data() {
    return {
      list: null, // 页面列表数据
      pageWidth: 0, // 页面宽度
      pageHeight: 0, // 页面高度，图片未加载时生效
      scaleWidth: 0, // 缩放宽度
      placeholderSrc: null, // 文档占位层图片
      loadingUrl: this.$parent.loadingUrl,
      count: this.$parent.count,
      silder: this.current,
      watermarkConfig: this.$parent.watermarkConfig,
      version: this.$parent.version,
      appCode: this.$parent.appCode
    };
  },
  computed: {
  },
  watch: {
    current() {
      this.silder = this.current;
      this.currentChange();
    },
    scale() {
      this.setScaleWidth();
      this.navigate(this.current);
    },
    silder() {
      this.$emit('currentChange', this.silder);
      this.setPlaceholderIndexSrc(this.silder - 1);
    }
  },
  created() {
    // 取得需要用到的父组件的一些值
    this.width = this.$parent.width;
    this.imageWidth = this.$parent.imageWidth;
    this.imageHeight = this.$parent.imageHeight;
    this.localPlay = this.$parent.localPlay;

    // 生成列表数据
    const temp = [];
    const initList = this.$parent.initList;
    const length = initList.length;
    if (this.localPlay) {
      this.fileId = this.$parent.fileInfo.fileId;
      this.serviceGroup = this.$parent.fileInfo.serverGroupId;
      for (let i = 0; i < this.count; i++) {
        temp.push({
          url: '',
          status: 'waiting'
        });
      }
      this.tempList = initList;
      setTimeout(() => {
        this.tempList = undefined;
      }, cacheTime);
    } else {
      for (let i = 0; i < this.count; i++) {
        if (i < length) {
          temp.push({
            url: initList[i].url,
            status: 'canLoad'
          });
        } else {
          temp.push({
            url: '',
            status: 'waiting'
          });
        }
      }
    }
    this.list = temp;
  },
  mounted() {
    this.container = this.$parent.$el; // 父容器
    this.pages = this.$refs.pages; // 页面容器
    this.bindEvent();
    this.setWidth();
    this.currentChange();
    this.throttleWheel = throttle(100, this.mouseWheelHandler);
    this.throttleLoadImage = throttle(300, this.loadImage);
  },
  beforeDestroy() {
    this.unBindEvent();
  },
  destroyed() {
    this.unBindEvent();
  },
  methods: {
    bindEvent() { // 绑定事件
      document.addEventListener('keydown', this.keydownEvent);
      window.addEventListener('resize', this.resizeEvent);
      // 添加鼠标滚轮事件
      this.pages.addEventListener('DOMMouseScroll', this.mousewheelEvent, false);
      this.pages.addEventListener('mousewheel', this.mousewheelEvent, false);
    },
    unBindEvent() { // 解除绑定事件
      document.removeEventListener('keydown', this.keydownEvent);
      window.removeEventListener('resize', this.resizeEvent);
    },
    currentChange() { // 当前页改变
      if (this.localPlay) {
        this.loadLocalPage(this.current);
      } else {
        this.loadPage(this.current);
        this.loadPage(this.current - 1);
        this.loadPage(this.current + 1);
      }
    },
    loadPage(num) { // 加载指定页
      if (!num || num < 0 || num > this.count) { // num值不合法
        return;
      }
      const page = this.list[num - 1];
      if (page.status === 'canLoad') {
        page.status = 'loaded';
      }
    },
    loadLocalPage(num) { // 加载指定页,混布播放用
      if (!num || num < 0 || num > this.count) { // num值不合法
        return;
      }

      const prevPage = this.list[num - 2];
      const nextPage = this.list[num];
      const currentPage = this.list[num - 1];
      const isWaiting = (page) => page && page.status === 'waiting';

      if (isWaiting(currentPage) || isWaiting(prevPage) || isWaiting(nextPage)) {
        const needLoadPage = [];
        if (isWaiting(currentPage)) {
          needLoadPage.push(num);
        }
        let preIndex = num - 1;
        let nextIndex = num + 1;
        while ((preIndex > 0 || nextIndex <= this.count) && needLoadPage.length < 5) {
          if (isWaiting(this.list[preIndex - 1])) {
            needLoadPage.push(preIndex);
          }
          if (isWaiting(this.list[nextIndex - 1])) {
            needLoadPage.push(nextIndex);
          }
          preIndex--;
          nextIndex++;
        }
        console.log('needLoadPage', needLoadPage);

        for (const idx of needLoadPage) {
          const page = this.list[idx - 1];
          if (isWaiting(page)) {
            page.status = 'pending';
          }
        }
        this.$nextTick(() => { this.throttleLoadImage(); });
      }
    },
    async loadImage() {
      if (!this.tempList) {
        try {
          const {playDetails} = await getFileList({
            fileId: this.fileId,
            serviceGroup: this.serviceGroup
          });
          this.tempList = playDetails;
          setTimeout(() => {
            this.tempList = undefined;
          }, cacheTime);
        } catch (error) {}
      }
      this.list.forEach((page, index) => {
        if (page.status === 'pending') {
          if (this.tempList && this.tempList.length > index) {
            page.url = this.tempList[index].url;
            page.status = 'loaded';
          }
        }
      });
    },
    setWidth() { // 设置页面宽度
      const containerWidth = this.container.clientWidth;
      const containerHeight = this.container.clientHeight - 70;
      const maxWidth = (containerHeight - 22) * this.imageWidth / this.imageHeight; // 根据容器高度计算得到页面最大宽度

      if (maxWidth < containerWidth) { // 最大宽度小于容器宽度，设置页面宽度为最大宽度
        this.pageWidth = maxWidth;
        this.setScaleWidth();
      } else if (maxWidth > this.width) { // 最大宽度大于文档宽度，文档居中，显示缩放、全屏按钮
        this.pageWidth = this.width;
        this.setScaleWidth();
        this.$emit('zoom-visible-change', true);
      } else { // 最大宽度大于容器宽度、小于文档宽度，页面宽度100%，隐藏缩放、全屏按钮，缩放级别还原为0
        this.pageWidth = maxWidth;
        this.setScaleWidth(0);
        this.$emit('zoom-visible-change', false);
      }
      if (this.pageHeight === 0) { // pageHeight 只计算一次，只在图片未加载时生效
        this.pageHeight = this.imageHeight / this.imageWidth * this.pageWidth - 2;
        console.log('pageHeight', this.pageHeight);
      }
    },
    setScaleWidth(value) { // 设置文档缩放宽度
      if (value !== undefined) {
        this.scaleWidth = value;
      } else {
        this.scaleWidth = Math.floor(this.pageWidth * (1 + this.scale * (this.scale > 0 ? 0.3 : 0.1)));
      }
    },
    imageLoad(e, item, index) { // 图片加载成功
      if (!this.placeholderSrc && this.current === index + 1) { // 首张图片加载成功，设置占位层图片为当前图片
        this.setPlaceholderSrc(e.target.src);
      }
      item.status = 'loading';
    },
    setPlaceholderIndexSrc(index) {
      if (!this.list[index]) return;
      this.setPlaceholderSrc(this.list[index].url);
    },
    setPlaceholderSrc(src) {
      if (!src) return;
      this.placeholderSrc = src;
      this.pageHeight = null;
    },
    navigate(num) { // 跳转到指定页
      // 空方法，给parent调用
    },
    keydownEvent(e) { // 键盘按下事件
      // 翻页笔翻页
      if (e && e.keyCode === 38 || e && e.keyCode === 37) { // 上,左
        this.prevPage();
        e.preventDefault();
        return false;
      }
      if (e && e.keyCode === 40 || e && e.keyCode === 39) { // 下,右
        this.nextPage();
        e.preventDefault();
        return false;
      }
    },
    mousewheelEvent(e) { // 鼠标滚轮事件
      e = e || window.event;
      e.stopPropagation();
      e.preventDefault();
      this.throttleWheel(e);
    },
    mouseWheelHandler(e) {
      const PAGE_OFFSET = 10;
      let dir = '';
      if (e.wheelDelta) {
        // IE/Opera/Chrome
        if (e.wheelDelta > PAGE_OFFSET) {
          dir = 'up';
        } else if (e.wheelDelta < PAGE_OFFSET * -1) {
          dir = 'down';
        }
      } else if (e.detail) {
        // Firefox
        if (e.detail < -1 * PAGE_OFFSET) {
          dir = 'up';
        } else if (e.wheelDelta > PAGE_OFFSET) {
          dir = 'down';
        }
      }
      if (dir === 'up') {
        // 向上滚动
        this.prevPage();
      } else if (dir === 'down') {
        // 向下滚动
        this.nextPage();
      }
    },
    prevPage() { // 上一页
      if (this.current === 1) return;
      const prev = this.current - 1;
      this.$emit('currentChange', prev);
    },
    nextPage() { // 下一页
      if (this.current === this.count) return;
      const next = this.current + 1;
      this.$emit('currentChange', next);
    },
    resizeEvent() { // 窗口大小改变
      this.resizeTimeout && clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(this.setWidth, 50);
    }
  }
};
</script>
