import Richeditor from './src/main';

// examples/assets/richeditor/fonts 下的文件为备份文件，以后想要扩展菜单，使用svg文件到 https://icomoon.io/app/#/select 新增图标

/* istanbul ignore next */
Richeditor.install = function(Vue) {
  Vue.component(Richeditor.name, Richeditor);
};

export default Richeditor;
