<template>
  <div class="yxtbiz-ai-project-assistant">
   <div class="yxtbiz-ai-project-assistant__main" ref="aiRefs">
     <div v-if="showTitle" class="yxtbiz-ai-project-assistant__title">
      <yxt-svg
        :remote-url='mediaPath'
        icon-class="o2o-ai-project-assistant-avatar"
        class="yxtbiz-ai-project-assistant__title-icon"
     ></yxt-svg>
     {{$t('pc_biz_ai_project_assistant' /* 智能项目助手 */)}}
    </div>
    <div v-if="tipVisible" class="yxtbiz-ai-project-assistant__tip">
      <i @click="handleTipCloseClick" class="yxt-icon-close yxtbiz-ai-project-assistant__tip-close"></i>
      <ClampText ref="ClampText" :limitHeight="44" textClassName="yxtbiz-ai-project-assistant__tip-text" :text="$t('pc_biz_ai_project_tip' /* 智能项目助手可以生成培训方案、设计项目名称等，建议描述你的培训目标、培训时间、培训对象、培训评估等培训相关信息，帮助项目助手更好的为你生成结果。 */)" />
    </div>
    <BuiltInQuestions class="yxtbiz-ai-project-assistant__question" @click="handleQuestionClick" />
    <InputChat :send-disabled="sendDisabled" class="yxtbiz-ai-project-assistant__input-chat" v-model="inputVal" @send="handleSendClick" />
    <div class="yxtbiz-ai-project-assistant__answer" v-if="answerShow">
       <div class="yxtbiz-ai-project-assistant__outputting-title ellipsis">
          {{currentQuestion}}
        </div>
        <pre class="yxtbiz-ai-project-assistant__outputting-content">
          {{currentAnswer}}
        </pre>
    </div>
   </div>
  <div v-if="operateShow" class="yxtbiz-ai-project-assistant__operate" :style="qaStatus === 'preparing' ? {'border-top-left-radius': '4px','border-top-right-radius': '4px'} : {}">
    <div>
      <div v-if="['preparing','outputting'].includes(qaStatus)" class="yxtbiz-ai-project-assistant__operate-outputting">
        <div class="yxtbiz-ai-project-assistant__outputting-text">{{$t('pc_biz_outputting' /* 正在输出 */)}}</div>
        <OutputtingDot />
      </div>
      <PageTurning v-else-if="qaStatus==='outputted' && answerList.length > 1" :current="currentPage" @update:current="handleCurrentPageChange" :total="answerList.length" :style="{visibility: qaStatus !== 'outputted' ? 'hidden' : 'visible'}" />
    </div>
    <div class="yxtbiz-ai-project-assistant__operate-button">
      <OperateButton v-if="['preparing','outputting'].includes(qaStatus)" @click="handleStopClick" icon-class="yxt-icon-video-pause" :text="$t('pc_biz_stop' /* 停止 */)" />
      <OperateButton v-if="qaStatus === 'outputted'" class="color-bff" icon-class="yxt-icon-video-pause" :text="$t('pc_biz_build_project').d('生成项目')">
        <yxt-svg slot="icon" class="mr8 color-bff" width="16px" height="16px" icon-class="language" />
      </OperateButton>
      <OperateButton v-if="qaStatus !== 'preparing'" @click="handleRetryClick" icon-class="yxt-icon-refresh" :text="$t('pc_biz_retry' /* 重试 */)" />
    </div>
  </div>
  </div>
</template>

<script>
import PageTurning from './page-turning.vue';
import InputChat from './input-chat/index.vue';
import OutputtingDot from './outputting-dot.vue';
import OperateButton from './operate-button.vue';
import BuiltInQuestions from './built-in-questions/index.vue';
import ClampText from './clamp-text.vue';
import {propsMixin} from '../common.js';
import { addGuide, checkGuide, stopQuestion} from '../service.js';
// import axios from 'axios';
import CreateSocket from './websocket/index.js';
import { apiBaseUrl } from './websocket/domain.js';
import { getQuestionsOnTrainingContent } from './built-in-questions/questions.js';

export default {
  name: 'YxtbizAiProjectAssistant',
  mixins: [propsMixin],
  provide() {
    return {
      highPriorityQuestionType: this.highPriorityQuestionType
    };
  },
  components: {
    PageTurning,
    InputChat,
    OutputtingDot,
    OperateButton,
    BuiltInQuestions,
    ClampText
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputVal: '',
      qaStatus: 'outputted',
      answerList: [],
      currentQuestion: '',
      currentAnswer: '',
      currentPage: 1,
      currentData: [],
      wsInstance: null,
      time: null,
      done: false,
      isManualStop: false,
      messageId: '',
      // lastStreamIndex: 0,
      tipVisible: false // 需要接口控制
    };
  },
  created() {
    this.createWebsocket();
  },
  mounted() {
    this.checkGuide();
  },
  computed: {
    answerShow() {
      return ['outputting'].includes(this.qaStatus) || this.qaStatus === 'outputted' && this.answerList.length > 0;
    },
    operateShow() {
      return ['outputting', 'preparing'].includes(this.qaStatus) || this.qaStatus === 'outputted' && this.answerList.length > 0;
    },
    sendDisabled() {
      return !this.inputVal.trim() || this.qaStatus !== 'outputted';
    },
    mediaPath() {
      return `${this.$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`;
    }
  },
  beforeDestroy() {
    this.detorySocket();
  },
  methods: {
    createWebsocket() {
      this.detorySocket();
      const wsInfo = {
        wsUrl: `${apiBaseUrl('wsBaseUrl')}websocketsvc`,
        type: 'openai',
        project: 'o2o'
      };

      this.wsInstance = new CreateSocket(wsInfo.wsUrl, this.wsCallback, `${wsInfo.project}_${wsInfo.type}`);
      this.wsInstance.connect();
    },
    socketMessage() {
      return {
        type: 'openai',
        jwt: window.getLocalStorage('token'),
        body: {
          stream: true,
          messages: [
            {role: 'system', content: this.chatContext},
            {role: 'user', content: this.currentQuestion}
          ]
        }
      };

    },
    detorySocket() {
      if (this.wsInstance) {
        this.wsInstance.closeMyself();
        this.wsInstance = null;
      }
    },
    wsCallback(content) {
      if (!content) return;
      if (this.isManualStop) return;

      try {
        const data = JSON.parse(content);
        const msg = JSON.parse(data.result || '{}');
        const finish = msg && msg.choices && msg.choices[0].finish_reason;

        this.accResult(msg && msg.choices && msg.choices[0].delta && msg.choices[0].delta.content);

        if (finish === 'stop') {
          this.done = true;
          this.handleTypewriterEffect();
        }
      } catch (e) {}
    },
    accResult(info) {
      if (!info) return;
      const len = this.currentData.length;
      this.currentData.push(...info.split(''));
      if (len === 0) {
        this.handleTypewriterEffect();
      }
    },
    updateClamp() { // 外部方法
      this.$refs.ClampText && this.$refs.ClampText.updateClamp();
    },
    getCurrentQa() { // 外部方法
      return {
        question: this.currentQuestion,
        answerList: [...this.answerList]
      };
    },
    setCurrentQa(qa) { // 外部方法
      this.currentQuestion = qa.question;
      this.currentPage = qa.answerList.length;
      this.currentAnswer = qa.answerList[this.currentPage - 1];
      this.answerList = qa.answerList;
    },
    checkGuide() {
      checkGuide({type: 14})
        .then(res => {
          if (res) {
            this.tipVisible = true;
          }
        });
    },
    handleQuestionClick(question) {
      this.inputVal = question;
    },
    handleCurrentPageChange(current) {
      this.currentPage = current;
      this.currentAnswer = this.answerList[current - 1];
    },
    handleSendClick() {
      if (this.inputVal.trim() !== this.currentQuestion) {
        this.answerList = [];
        this.$emit('answer-list-change');
      }
      this.currentQuestion = this.inputVal.trim();

      this.sendQuestion();
    },

    sendQuestion() {
      this.currentAnswer = '';
      // this.lastStreamIndex = 0;
      this.qaStatus = 'preparing';
      this.isManualStop = false;

      if (getQuestionsOnTrainingContent[this.currentQuestion]) {
        // 内置答案
        setTimeout(()=>{
          this.done = true;
          this.accResult(getQuestionsOnTrainingContent[this.currentQuestion]);
        }, 3000);
        return;
      }

      this.wsInstance.sendHandle(this.socketMessage(), (data)=>{
        this.messageId = data.messageId;
      });

      // this.source = axios.CancelToken.source();
      // this.done = false;
      // askQuestion({
      //   'stream': true,
      //   'messages': [
      //     {'role': 'system', 'content': this.chatContext},
      //     {'role': 'user', 'content': this.currentQuestion}
      //   ]
      // },
      // {
      //   cancelToken: this.source.token,
      //   responseType: 'stream',
      //   onDownloadProgress: this.onDownloadProgress
      // }).then(() => {
      //   this.done = true;
      //   this.handleTypewriterEffect();
      // })
      //   .catch(() => {
      //     if (this.isManualStop) {
      //       this.isManualStop = false;
      //       return;
      //     }

      //     Object.assign(this, {
      //       done: false,
      //       qaStatus: 'outputted'
      //     });
      //   });
    },
    // onDownloadProgress(progressEvent) {
    //   const content = progressEvent.currentTarget.responseText;
    //   if (!content || !content.length) return;

    //   const lastOf = content.lastIndexOf('\n\n');
    //   const messages = content.slice(this.lastStreamIndex, lastOf).split('\n\n');

    //   this.lastStreamIndex = lastOf;

    //   messages.forEach(message => {
    //     try {
    //       if (message) {
    //         const msg = JSON.parse(message.replace('data: ', ''));
    //         this.accResult(msg && msg.choices && msg.choices[0].delta && msg.choices[0].delta.content);
    //       }
    //     } catch (e) {}
    //   });
    // },
    // accResult(info) {
    //   if (!info) return;
    //   const len = this.currentData.length;
    //   this.currentData.push(...info.split(''));
    //   if (len === 0) {
    //     this.handleTypewriterEffect();
    //   }
    // },
    handleTypewriterEffect() {
      clearTimeout(this.time);
      if (this.currentData.length === 0) {
        if (this.done) {
          this.qaStatus = 'outputted';
          if (this.currentAnswer.trim()) {
            this.answerList.push(this.currentAnswer);
          }
          this.messageId = '';
          this.currentPage = this.answerList.length;
          this.currentAnswer = this.answerList[this.currentPage - 1]; // 当停止的时候，无答案则当前展示上一次答案
          this.done = false;
          this.$emit('answer-list-change');
        }
        return;
      };
      this.time = setTimeout(() => {
        this.qaStatus = 'outputting';
        this.currentAnswer += this.currentData.shift();
        this.handleTypewriterEffect();
        this.$refs.aiRefs.scrollTop = this.$refs.aiRefs.scrollHeight;
      }, 20);
    },
    addGuide() {
      addGuide({typeNoviceGuidance: 14, projectId: 0});
    },
    handleTipCloseClick() {
      this.tipVisible = false;
      this.addGuide();
    },
    handleStopClick() {
      this.isManualStop = true;
      if (this.messageId && !this.done) {
        stopQuestion(this.messageId).then(()=>{
          // 2. 答案停止
          this.stopTypewriter();
        });
      } else {
        this.stopTypewriter();
      }
    },
    // stopQaRequest() {
    //   this.source && this.source.cancel();
    //   this.isManualStop = true;
    // },
    stopTypewriter() {
      this.messageId = '';
      this.currentData = [];
      this.done = true;
      this.handleTypewriterEffect();
    },
    handleRetryClick() {
      if (this.qaStatus === 'outputted') {
        this.currentAnswer = '';
      }
      this.handleStopClick();
      this.sendQuestion();
    },
    handleShortcutChange(item) {
      this.inputVal = item.name;
      this.$refs.textarea.focus();
    }
  }
};
</script>