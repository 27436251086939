var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "yxt-dialog",
        {
          attrs: {
            title: _vm.title,
            "custom-class": "enroll-view-doc-container",
            visible: _vm.visible,
            width: "100%",
            cutline: false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("div", { staticClass: "yxt-course-player" }, [
                _c(
                  "section",
                  {
                    ref: "headerContainer",
                    staticClass: "preview-header-container",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close-btn",
                        on: { click: _vm.closePopup },
                      },
                      [
                        _c("span", { staticClass: "close-svg" }, [
                          _c("i", {
                            staticClass:
                              "yxtf-dialog__close yxtf-icon yxt-icon-close",
                          }),
                        ]),
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.$t("pc_biz_quit" /* 退出 */))),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "line" }),
                    _c("div", { staticClass: "preview-header-title" }, [
                      _c(
                        "span",
                        { staticClass: "icon-doc icon-doc-type" },
                        [
                          _c("yxt-svg", {
                            staticClass: "mr8 v-mid",
                            attrs: {
                              "remote-url": `${_vm.$staticBaseUrl}ufd/407a24/kng/pc/svg/PC`,
                              width: "32px",
                              height: "32px",
                              "icon-class": _vm.getFileType(_vm.currentFileObj),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "file-info" },
                        [
                          _c(
                            "yxtf-tooltip",
                            {
                              attrs: {
                                content: _vm.currentFileObj.originName,
                                placement: "top",
                                "open-filter": true,
                                effect: "ellipsis",
                              },
                            },
                            [
                              _c("div", { staticClass: "file-name" }, [
                                _vm._v(_vm._s(_vm.currentFileObj.originName)),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "file-size" }, [
                            _vm._v(
                              _vm._s(_vm.getFileSize(_vm.currentFileObj.size))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "preview-header-btns" },
                      [
                        _c(
                          "yxt-button",
                          { attrs: { plain: "" }, on: { click: _vm.download } },
                          [
                            _c("i", { staticClass: "yxt-icon-download mr8" }),
                            _vm._v(
                              _vm._s(
                                _vm.$t("pc_biz_import_btn_download" /* 下载 */)
                              )
                            ),
                          ]
                        ),
                        _c(
                          "yxt-button",
                          {
                            attrs: { plain: "" },
                            on: { click: _vm.addToKngStore },
                          },
                          [
                            _c("i", { staticClass: "yxt-icon-folder-add mr8" }),
                            _vm._v(
                              _vm._s(
                                _vm.$t("pc_biz_lbl_joinlib" /* 加入知识库 */)
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "preview-body-container" }, [
                  _vm.type === "doc" && !_vm.isLocal
                    ? _c(
                        "div",
                        { staticClass: "doc-info" },
                        [
                          _vm.type === "doc" && _vm.currentFileId
                            ? _c("yxtbiz-doc-viewer", {
                                staticStyle: { height: "100%" },
                                attrs: {
                                  fileId: _vm.currentFileId,
                                  fileType: _vm.getFileType(_vm.currentFileObj),
                                  nopad: true,
                                  preview: true,
                                  downloadCb: _vm.downloadCb,
                                  addToKngStoreCb: _vm.addToKngStoreCb,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : (_vm.type === "doc" || _vm.type === "image") &&
                      _vm.fileInfo.list &&
                      _vm.fileInfo.list.length
                    ? _c(
                        "div",
                        { staticClass: "doc-info" },
                        [
                          _c("yxtbiz-doc-viewer", {
                            attrs: {
                              fileIdCopy: _vm.currentFileId,
                              fileInfo: _vm.fileInfo,
                              previewSingleImg: _vm.type === "image",
                              nopad: true,
                              preview: true,
                              downloadCb: _vm.downloadCb,
                              addToKngStoreCb: _vm.addToKngStoreCb,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.error && !_vm.loading
                    ? _c("div", { staticClass: "transform-fail" }, [
                        _c(
                          "div",
                          { staticClass: "transform-fail-info" },
                          [
                            _c("img", {
                              attrs: {
                                src: `${_vm.$staticBaseUrl}assets/76226b62/333e072c/transform-fail.png`,
                              },
                            }),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.fileError
                                    ? _vm.$t(
                                        "pc_biz_show_file_type_fail" /* 暂不支持此类型文件预览，请下载查看 */
                                      )
                                    : _vm.$t(
                                        "pc_biz_trans_file_fail" /* 文件转码失败，请下载查看 */
                                      )
                                )
                              ),
                            ]),
                            _c(
                              "yxtf-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.download },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "pc_biz_lbl_click_to_download" /* 下载查看 */
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.fileIdList.length && _vm.current !== 0
                    ? _c(
                        "div",
                        {
                          ref: "prev",
                          staticClass: "turn-left",
                          on: { click: _vm.prev },
                        },
                        [
                          _c(
                            "yxtf-tooltip",
                            {
                              attrs: {
                                content: _vm.$t(
                                  "pc_biz_imgviewer_btn_pre" /* 上一个 */
                                ),
                                placement: "top",
                                effect: "ellipsis",
                                "open-filter": false,
                                "max-width": 200,
                              },
                            },
                            [_c("i", { staticClass: "yxt-icon-arrow-left" })]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.fileIdList.length &&
                  _vm.current !== _vm.fileIdList.length - 1
                    ? _c(
                        "div",
                        {
                          ref: "next",
                          staticClass: "turn-right",
                          on: { click: _vm.next },
                        },
                        [
                          _c(
                            "yxtf-tooltip",
                            {
                              attrs: {
                                content: _vm.$t(
                                  "pc_biz_imgviewer_btn_next" /* 下一个 */
                                ),
                                placement: "top",
                                effect: "ellipsis",
                                "open-filter": false,
                                "max-width": 200,
                              },
                            },
                            [_c("i", { staticClass: "yxt-icon-arrow-right" })]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.loading && !_vm.type
                  ? _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "floading",
                            rawName: "v-floading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass:
                          "yxtbiz-joinlib_loading pa right-top text-center",
                      },
                      [
                        _c("yxtf-svg", {
                          attrs: {
                            width: "20px",
                            height: "20px",
                            "icon-class": "loading",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }