var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "yxtbiz-enroll-settings__inputerror": _vm.error } },
    [
      _c(
        "yxt-scrollbar",
        {
          staticClass: "yxtbiz-enroll-settings__checked yxt-input__inner",
          class: {
            "yxtbiz-enroll-settings__checked-disabled": _vm.disabled,
            "yxtbiz-enroll-settings__checked-error": _vm.error,
          },
          attrs: {
            "wrap-class": "yxtbiz-enroll-settings__checked-wrap",
            "view-class": "yxtbiz-enroll-settings__checked-view",
          },
        },
        [
          !_vm.list || _vm.list.length === 0
            ? _c(
                "div",
                { staticClass: "yxtbiz-enroll-settings__checked-placeholder" },
                [_vm._v(_vm._s(_vm.placeholder))]
              )
            : _vm._e(),
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "yxt-tag",
              {
                key: item.id,
                attrs: {
                  title: item.name || item.fullname,
                  type: "info",
                  size: "small",
                  closable: "",
                },
                on: {
                  close: function ($event) {
                    return _vm.handleClose(item, index)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return (() => {}).apply(null, arguments)
                  },
                },
              },
              [
                item._type === 0
                  ? _c("yxtbiz-dept-name", {
                      attrs: { name: item.name || item.fullname },
                    })
                  : _c("yxtbiz-user-name", {
                      attrs: { name: item.name || item.fullname },
                    }),
                item.includeAll === 1
                  ? _c("span", { staticClass: "color-gray-6 ml8" }, [
                      _vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_allfollowup"))),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
          _c("yxt-svg", {
            staticClass: "yxtbiz-enroll-settings__checked-icon color-gray-6",
            class: { "hover-primary-6 hand": !_vm.disabled },
            attrs: { "icon-class": "three-dot", "remote-url": _vm.mediaUrl },
            nativeOn: {
              click: function ($event) {
                return _vm.handClick.apply(null, arguments)
              },
            },
          }),
        ],
        2
      ),
      _vm.error
        ? _c("span", { staticClass: "input-error-text" }, [
            _vm._v(_vm._s(_vm.errorText)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }