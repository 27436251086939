<template>
  <div :class="{'yxtbiz-enroll-settings__inputerror': error}">
    <yxt-scrollbar class="yxtbiz-enroll-settings__checked yxt-input__inner"
                wrap-class="yxtbiz-enroll-settings__checked-wrap"
                view-class="yxtbiz-enroll-settings__checked-view"
                :class="{'yxtbiz-enroll-settings__checked-disabled': disabled, 'yxtbiz-enroll-settings__checked-error': error}">
      <div v-if="!list || list.length === 0" class="yxtbiz-enroll-settings__checked-placeholder">{{ placeholder }}</div>
      <yxt-tag v-for="(item, index) in list"
              :key="item.id"
              :title="item.name || item.fullname"
              type="info"
              size="small"
              closable
              @close="handleClose(item, index)"
              @click.stop="() => {}">
      <yxtbiz-dept-name v-if="item._type === 0" :name="item.name || item.fullname"/>
      <yxtbiz-user-name v-else :name="item.name || item.fullname"/>
      <span v-if="item.includeAll === 1" class="color-gray-6 ml8">{{ $t('pc_biz_enroll_lbl_allfollowup') }}</span>
      </yxt-tag>
      <yxt-svg class="yxtbiz-enroll-settings__checked-icon color-gray-6"
                :class="{'hover-primary-6 hand': !disabled}"
                icon-class="three-dot"
                :remote-url='mediaUrl'
                @click.native="handClick"></yxt-svg>
    </yxt-scrollbar>
    <span v-if="error" class="input-error-text">{{ errorText }}</span>
  </div>

</template>

<script>
import {
  Scrollbar,
  Tag
} from 'yxt-pc';
import YxtBizUserName from 'yxt-biz-pc/packages/user-name';
import YxtBizDeptName from 'yxt-biz-pc/packages/dept-name';

export default {
  name: 'EnrollSettingsChecked',
  components: {
    YxtScrollbar: Scrollbar,
    YxtTag: Tag,
    YxtBizUserName,
    YxtBizDeptName
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: true
    },
    errorText: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.$el.querySelector('.yxtbiz-enroll-settings__checked-view').addEventListener('click', this.handClick);
  },

  methods: {
    handleClose(tag, index) {
      this.$emit('delete', { tag, index });
    },
    handClick() {
      if (this.disabled) return;
      this.$emit('click');
    }
  },

  watch: {
    list(v) {
      if (v && v.length > 0) {
        this.$emit('update:error', false);
      }
    }
  }
};
</script>

