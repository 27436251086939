<template>
  <div>
    <div ref="text" :class="[textClassName]" :style="{maxHeight: showAll ? `${limitHeight}px` : 'none', overflow: 'hidden'}">{{text}}</div>
    <yxt-button v-if="showOperate" @click="showAll = !showAll" type="text">{{this.$t(showAll ? 'pc_biz_ai_expand_all' /* 展开全部 */  : 'pc_biz_ai_retract' /* 收起 */)}}</yxt-button>
  </div>
</template>

<script>
export default {
  props: {
    limitHeight: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    textClassName: String
  },
  data() {
    return {
      showAll: true,
      showOperate: false
    };
  },
  methods: {
    updateClamp() {
      const textDom = this.$refs.text;
      if (!textDom) return;
      this.showOperate = textDom.scrollHeight > this.limitHeight;
    }
  },
  watch: {
    text: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.updateClamp();
          this.showAll = true;
        });
      }
    }
  }
};
</script>