<template>
  <div class="yxtbiz-ai-project-assistant-page-turning">
    <i @click="prev" class="yxt-icon-arrow-left yxtbiz-ai-project-assistant-page-turning__icon" :class="{'yxtbiz-ai-project-assistant-page-turning__icon--disabled': current === 1}"></i>
    <div class="yxtbiz-ai-project-assistant-page-turning__text">{{current}}/{{total}}</div>
    <i @click="next" class="yxt-icon-arrow-right yxtbiz-ai-project-assistant-page-turning__icon" :class="{'yxtbiz-ai-project-assistant-page-turning__icon--disabled': current === total}"></i>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      typeof: Number,
      default: 1
    },
    current: {
      typeof: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  methods: {
    prev() {
      if (this.current > 1) {
        this.$emit('update:current', this.current - 1);
      }
    },
    next() {
      if (this.current < this.total) {
        this.$emit('update:current', this.current + 1);
      }
    }
  }

};
</script>