var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tencent-info" },
    [
      _c("div", { ref: "doc", staticClass: "yxtbiz-doc-viewer--tencent" }),
      _vm.isMarquee
        ? _c("yxtbiz-watermark", {
            attrs: {
              version: _vm.version,
              "app-code": _vm.appCode,
              option: _vm.watermarkConfig,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }