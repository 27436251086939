<template>
  <yxt-dialog
    v-if="show"
    :title="title"
    custom-class="enroll-view-doc-container"
    :visible.sync="visible"
    width="100%"
    :cutline="false"
    :append-to-body="true">
    <div v-if="visible" class="yxt-course-player">
      <section ref="headerContainer" class="preview-header-container">
        <div class="close-btn" @click="closePopup">
          <span class="close-svg">
            <i class="yxtf-dialog__close yxtf-icon yxt-icon-close"></i>
          </span>
          <span class="text">{{ $t('pc_biz_quit'/* 退出 */) }}</span>
        </div>
        <div class="line"></div>
        <div class="preview-header-title">
          <span class="icon-doc icon-doc-type">
            <yxt-svg
              :remote-url="`${$staticBaseUrl}ufd/407a24/kng/pc/svg/PC`"
              class="mr8 v-mid"
              width="32px"
              height="32px"
              :icon-class="getFileType(currentFileObj)" 
            />
          </span>
          <div class="file-info">
            <yxtf-tooltip
              :content="currentFileObj.originName"
              placement="top"
              :open-filter="true"
              effect="ellipsis"
            >
              <div class="file-name">{{currentFileObj.originName}}</div>
            </yxtf-tooltip>
            <div class="file-size">{{getFileSize(currentFileObj.size)}}</div>
          </div>
        </div>
        <div class="preview-header-btns">
          <yxt-button @click="download" plain><i class="yxt-icon-download mr8"/>{{ $t('pc_biz_import_btn_download' /* 下载 */)}}</yxt-button>
          <yxt-button @click="addToKngStore" plain><i class="yxt-icon-folder-add mr8"/>{{ $t('pc_biz_lbl_joinlib' /* 加入知识库 */)}}</yxt-button>
        </div>
      </section>
      <div class="preview-body-container">
        <div class="doc-info" v-if="type === 'doc' && !isLocal">
          <yxtbiz-doc-viewer
            v-if="type === 'doc' && currentFileId"
            :fileId="currentFileId"
            :fileType="getFileType(currentFileObj)"
            :nopad="true"
            :preview="true"
            :downloadCb="downloadCb"
            :addToKngStoreCb="addToKngStoreCb"
            style="height: 100%"
          ></yxtbiz-doc-viewer>
        </div>
        <div v-else-if="(type === 'doc' || type === 'image') && fileInfo.list && fileInfo.list.length" class="doc-info" >
          <yxtbiz-doc-viewer
            :fileIdCopy="currentFileId"
            :fileInfo="fileInfo"
            :previewSingleImg="type === 'image'"
            :nopad="true"
            :preview="true"
            :downloadCb="downloadCb"
            :addToKngStoreCb="addToKngStoreCb"
        ></yxtbiz-doc-viewer>
        </div>
        <div v-if="error && !loading" class="transform-fail">
          <div class="transform-fail-info">
            <img :src="`${$staticBaseUrl}assets/76226b62/333e072c/transform-fail.png`" />
            <div>{{ fileError ? $t('pc_biz_show_file_type_fail' /* 暂不支持此类型文件预览，请下载查看 */) : $t('pc_biz_trans_file_fail' /* 文件转码失败，请下载查看 */)}}</div>
            <yxtf-button @click="download" type="primary">{{ $t('pc_biz_lbl_click_to_download' /* 下载查看 */)}}</yxtf-button>
          </div>
        </div>
        <div v-if="fileIdList.length && (current !== 0)" ref="prev" class="turn-left" @click="prev">
          <yxtf-tooltip
            :content="$t('pc_biz_imgviewer_btn_pre' /* 上一个 */ )"
            placement="top"
            effect="ellipsis"
            :open-filter="false"
            :max-width="200">
            <i class="yxt-icon-arrow-left"/>
          </yxtf-tooltip>
        </div>
        <div v-if="fileIdList.length && (current !== fileIdList.length - 1)" ref="next" class="turn-right" @click="next">
          <yxtf-tooltip
            :content="$t('pc_biz_imgviewer_btn_next' /* 下一个 */ )"
            placement="top"
            effect="ellipsis"
            :open-filter="false"
            :max-width="200">
            <i class="yxt-icon-arrow-right"/>
          </yxtf-tooltip>
        </div>
      </div>
      <i v-if="loading && !type" v-floading="loading" class="yxtbiz-joinlib_loading pa right-top text-center">
        <yxtf-svg
          width="20px"
          height="20px"
          icon-class="loading"
        />
      </i>
    </div>
  </yxt-dialog>
</template>

<script>
// import imgView from './imgView';
import { getFileInfo, getPlayInfo, getWatermarkConfig, getMixingInfo, getFileTransformState } from './service';
import utils from '../../common-util';

export default {
  name: 'YxtbizCoursePlayer',
  props: {
    // 文件ID List
    fileIdList: {
      type: Array,
      default: []
    },
    // 文件ID List
    configKey: {
      type: String,
      default: 'KnowledgeConfigKey'
    },
    start: {
      type: Number,
      default: 0
    },
    closePreview: {
      type: Function,
      default: () => {}
    },
    downloadCb: {
      type: Function,
      default: () => {}
    },
    addToKngStoreCb: {
      type: Function,
      default: () => {}
    },
    visible: Boolean
  },
  components: {
    // imgView
  },
  data() {
    return {
      fileInfo: {},
      width: 0,
      title: '',
      currentFileObj: {},
      loading: true,
      show: true,
      filters: [],
      type: '',
      current: 0,
      currentFileId: '',
      fileType: {
        // .pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.zip
        '.doc': {
          type: 'doc',
          showType: 'word'
        },
        '.docx': {
          type: 'doc',
          showType: 'word'
        },
        '.ppt': {
          type: 'doc',
          showType: 'ppt'
        },
        '.pptx': {
          type: 'doc',
          showType: 'ppt'
        },
        '.xls': {
          type: 'doc',
          showType: 'excel'
        },
        '.xlsx': {
          type: 'doc',
          showType: 'excel'
        },
        '.pps': {
          type: 'doc',
          showType: 'ppt'
        },
        '.pdf': {
          type: 'doc',
          showType: 'pdf'
        },
        '.jpg': {
          type: 'image',
          showType: 'image'
        },
        '.jpeg': {
          type: 'image',
          showType: 'image'
        },
        '.gif': {
          type: 'image',
          showType: 'image'
        },
        '.png': {
          type: 'image',
          showType: 'image'
        },
        '.bmp': {
          type: 'image',
          showType: 'image'
        },
        '.ico': {
          type: 'image',
          showType: 'image'
        },
        '.w4v': {
          type: 'audio',
          showType: 'audio'
        },
        '.m4a': {
          type: 'audio',
          showType: 'audio'
        },
        '.wma': {
          type: 'audio',
          showType: 'audio'
        },
        '.wav': {
          type: 'audio',
          showType: 'audio'
        },
        '.mp3': {
          type: 'audio',
          showType: 'audio'
        },
        '.amr': {
          type: 'audio',
          showType: 'audio'
        },
        '.ogg': {
          type: 'audio',
          showType: 'audio'
        },
        '.wmv': {
          type: 'video',
          showType: 'video'
        },
        '.mp4': {
          type: 'video',
          showType: 'video'
        },
        '.flv': {
          type: 'video',
          showType: 'video'
        },
        '.avi': {
          type: 'video',
          showType: 'video'
        },
        '.rmvb': {
          type: 'video',
          showType: 'video'
        },
        '.mpg': {
          type: 'video',
          showType: 'video'
        },
        '.mkv': {
          type: 'video',
          showType: 'video'
        },
        '.mov': {
          type: 'video',
          showType: 'video'
        },
        '.mts': {
          type: 'video',
          showType: 'video'
        },
        '.zip': {
          type: 'zip',
          showType: 'zip'
        },
        '.rar': {
          type: 'zip',
          showType: 'zip'
        }
      },
      isCanClick: true,
      error: false,
      fileError: false,
      watermark: {},
      isLocal: false,
      fileStatus: true
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    visible(value) {
      if (value) {
        this.init();
      } else {
        this.current = 0;
        this.currentFileId = '';
        this.fileInfo = {};
        this.currentFileObj = {};
        this.type = '';
        this.error = false;
        this.isLocal = false;
        this.$nextTick(() => {
          document.removeEventListener('keyup', this.escKeyUpListener);
        });
      }
    },
    show(value) {
      value ? this.init() : this.initParams();
    }
  },
  methods: {
    initParams() {
      this.current = 0;
      this.currentFileId = '';
      this.fileInfo = {};
      this.currentFileObj = {};
      this.type = '';
      this.error = false;
      this.isLocal = false;
      this.$nextTick(() => {
        document.removeEventListener('keyup', this.escKeyUpListener);
      });
    },
    download() {
      if (!this.fileStatus) return;
      this.downloadCb(this.currentFileId);
    },
    addToKngStore() {
      if (!this.fileStatus) return;
      this.addToKngStoreCb(this.currentFileId);
    },
    getFileType(file) {
      return this.fileType[file.ext] ? this.fileType[file.ext].showType : 'other';
    },
    closePopup() {
      this.show = false;
      this.$emit('update:visible', false);
      this.closePreview();
    },
    next() {
      if (!this.isCanClick || this.current === this.fileIdList.length - 1) return;
      let current = this.current;
      this.current = current + 1;
      this.type = '';
      this.currentFileId = this.fileIdList[this.current];
      this.getFileInfoByApi(this.current);
      // if (this.current === this.fileIdList.length - 1) {
      //   this.$fmessage({
      //     showClose: true,
      //     message: '已是最后一页，按 ESC 退出'
      //   });
      // }
    },
    prev() {
      if (!this.isCanClick || this.current === 0) return;
      let current = this.current;
      this.type = '';
      this.current = current - 1;
      this.currentFileId = this.fileIdList[this.current];
      this.getFileInfoByApi(this.current);
    },
    init() {
      const body = document.getElementsByTagName('body');
      body[0].classList.add('course-player-body');
      this.current = this.start;
      this.currentFileId = this.fileIdList[this.current];
      this.getFileInfoByApi(this.start);
      this.getWatermarkConfig();

      this.$nextTick(() => {
        this.show = true;
        document.addEventListener('keyup', this.escKeyUpListener);
      });
    },
    escKeyUpListener(e) {
      if (e.keyCode === 27) {
        console.log('close');
        this.closePopup();
      } else if (e.keyCode === 37) {
        this.prev();
      } else if (e.keyCode === 39) {
        this.next();
      }
    },
    async getFileInfoByApi(start = 0) { // 调用api获取fileInfo
      this.loading = true;
      this.isCanClick = false;
      this.fileError = false;
      this.fileInfo.list = [];
      this.fileStatus = true;
      try {
        const fileInfo = await getFileInfo(this.fileIdList[start]);
        this.currentFileObj = fileInfo;
        const fileType = this.fileType[fileInfo.ext] ? this.fileType[fileInfo.ext].type : '';
        this.type = fileType;
        if (fileType === 'doc') {
          const fileStatus = await getFileTransformState(this.fileIdList[start]);
          if (fileStatus && fileStatus[0] && fileStatus[0].status === 0) {
            // 转码成功
            this.getFileSource(fileInfo, start);
          } else {
            // 未转码成功
            this.isCanClick = true;
            this.loading = false;
            this.error = true;
            this.type = '';
            this.fileStatus = false;
          }
        } else {
          this.getFileSource(fileInfo, start);
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
        console.error(e, 'getFileInfo error');
      }
    },
    getFileSource(fileInfo, start) {
      if (fileInfo.platform === 'tencent') {
        this.isLocal = false;
        this.currentFileId = this.fileIdList[start];
        this.$nextTick(()=>{
          this.type = 'doc';
          this.isCanClick = true;
          this.error = false;
        });
      } else {
        this.isLocal = fileInfo.platform === 'local';
        let fileType = this.type;
        if (fileType === 'doc') {
          this.loading = false;
          this.isCanClick = true;
          if (fileInfo.platform === 'local') {
            // 私有化逻辑
            this.getPrivatePlayInfoByApi();
          } else {
            // 百度
            this.getPlayInfoByApi();
          }
        } else if (fileType === 'image') {
          // 不区分是否公有云私有云
          this.getPrivatePlayInfoByApi();
        } else if (fileType === 'zip') {
          this.loading = false;
          this.error = true;
          this.fileError = true;
          this.type = '';
          this.isCanClick = true;
        } else {
          this.loading = false;
          this.error = true;
          this.type = '';
          this.isCanClick = true;
        }
      }
    },
    getPrivatePlayInfoByApi() {
      getMixingInfo().then(res => {
        // 获取 serviceGroup id
        let params = {
          fileId: this.currentFileId
        };
        if (this.type === 'doc') {
          params.clientType = 2;
        }
        getPlayInfo(params, { headers: { serviceGroup: res.id }}).then(res => {
          // 获取私有化资源地址
          let {playDetails} = res;
          this.fileInfo.list = [];
          if (playDetails && playDetails.length) {
            const desc = JSON.parse(playDetails[0].desc);
            this.width = desc.width;
            this.fileInfo = {
              list: playDetails.map((v, i) => {
                return {
                  url: v.url,
                  pageIndex: i + 1
                };
              })
            };
            this.error = false;
            this.fileError = false;
          } else {
            this.error = true;
            this.fileError = true;
          }
          this.loading = false;
          this.isCanClick = true;
        });
      }).catch(() => {
        this.loading = false;
        this.isCanClick = true;
      });
    },
    getPlayInfoByApi() {
      getPlayInfo({fileId: this.currentFileId}).then(res => {
        if (res.length || (res.playDetails && res.playDetails.length)) {
          this.error = false;
          const desc = JSON.parse(res[0].desc);
          this.width = desc.width;
          this.fileInfo = {
            // width: desc.width,
            // height: desc.height,
            list: [
              {
                url: res[0].url,
                name: this.imgName,
                'pageIndex': 1
              }
            ]
          };
        } else {
          this.error = true;
        }
        this.loading = false;
        this.isCanClick = true;
      }).catch(() => {
        this.loading = false;
        this.isCanClick = true;
      });
    },
    getFileSize(size) {
      return utils.fileUtils.getFileSize(size * 1024);
    },
    async getWatermarkConfig() {
      const res = await getWatermarkConfig();
      if (res.enabled && res.type === 1) {
        this.watermark.enabled = 0;
        this.watermark.type = res.type;
        this.watermark.text = res.watermarkContent;
        this.watermark.color = '#' + res.wordColor;
        this.watermark.opacity = res.wordAlpha;
        this.watermark.fontSize = res.wordFontSize;
      }
    }
  },
  beforeDestroy() {
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('course-player-body');
  }
};
</script>
<style lang="scss">
.yxt-course-player {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  
  .preview-body-container {
    height: calc(100% - 64px);
    margin-top: 64px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;

    .transform-fail {
      text-align: center;
      height: 100%;

      .transform-fail-info {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        div {
          margin-bottom: 32px;
        }
      }
      
      img {
        width: 300px;
        height: 160px;
        margin-bottom: 16px;
      }

      & > span {
        height: 28px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: #595959;
        display: block;
      }
    }

    .doc-info {
      height: 100%;
    }
  }
}
.course-player-body{
  .preview-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px 16px 10px;
    border-bottom: 1px solid #f0f0f0;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3000;
    height: 64px;
    box-sizing: border-box;

    .close-btn {
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 4px 8px;
      margin-left: 12px;
      cursor: pointer;
      font-size: 16px;
      
      .close-svg {
        box-sizing: border-box;
        display: inline-block;
        line-height: 0;
        margin-right: 8px;
        // background-color: #f5f6f7;
        height: 16px;
        width: 16px;
      }
    }

    .line {
      border-right: 1px solid #f5f6f7;
      margin-left: 8px;
      margin-right: 16px;
      height: 36px;
    }

    .preview-header-title {
      max-height: 48px;
      overflow: hidden;
      max-width: calc(100% - 120px);
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;

      .icon-doc {
        width: 36px;
        height: 36px;
        float: left;
        margin: 6px 14px 6px 0;
        // background-color: red;
      }

      .file-info {
        position: relative;
        top: 6px;
      }

      .file-name {
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #262626;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        margin-right: 32px;
      }

      .file-size {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #8c8c8c;
      }
    }

    .preview-header-btns {
      .yxt-button {
        padding: 4px 20px 4px 12px;
      }
    }

  }
  .turn-left,
  .turn-right {
    position: fixed;
    z-index: 3000;
    top: 50%;
    transform: translate(0, -50%);
    width: 24px;
    height: 24px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    font-size: 24px;
    padding: 12px;
    &:hover {
      color: #436bff;
    }
  }
  .turn-left {
    left: 112px;
    
  }
  .turn-right {
    right: 112px;
  }
  .yxtf-tooltip__popper {
    z-index: 3001 !important;
  }
} 
</style>
