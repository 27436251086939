var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-enroll-settings-person_set" },
    [
      _c(
        "yxt-popover",
        {
          attrs: {
            placement: "bottom-start",
            "popper-class": "yxtbiz-enroll-settings-custom_popover",
            width: "348",
            trigger: "manual",
            "visible-arrow": false,
          },
          model: {
            value: _vm.setVisible,
            callback: function ($$v) {
              _vm.setVisible = $$v
            },
            expression: "setVisible",
          },
        },
        [
          _c(
            "yxt-form",
            {
              staticClass: "yxtbiz-enroll-settings-form_val",
              attrs: { model: _vm.personForm },
            },
            [
              _c(
                "yxt-form-item",
                { attrs: { label: "人数设置", prop: "numSet" } },
                [
                  _c(
                    "yxt-select",
                    {
                      staticClass: "wline",
                      attrs: {
                        placeholder: _vm.$t("pc_biz_ote_lbl_pleaseselect"),
                      },
                      model: {
                        value: _vm.personForm.numSet,
                        callback: function ($$v) {
                          _vm.$set(_vm.personForm, "numSet", $$v)
                        },
                        expression: "personForm.numSet",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("yxt-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "yxt-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.personForm.numSet === 1,
                      expression: "personForm.numSet === 1",
                    },
                  ],
                  staticClass: "mt8",
                },
                [
                  _c("yxt-input-number", {
                    staticClass: "wline",
                    attrs: {
                      "controls-position": "right",
                      "step-strictly": "",
                      min: 1,
                      max: 999999999,
                    },
                    model: {
                      value: _vm.personForm.maxPersonCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "maxPersonCount", $$v)
                      },
                      expression: "personForm.maxPersonCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "yxt-form-item",
                { staticClass: "text-right mt24" },
                [
                  _c(
                    "yxt-button",
                    { attrs: { plain: "" }, on: { click: _vm.cancelPopover } },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
                  ),
                  _c(
                    "yxt-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmPopover },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "yxt-button",
            {
              attrs: {
                slot: "reference",
                size: "large",
                type: "primary",
                disabled: _vm.disabledBtn,
              },
              on: { click: _vm.showDrawer },
              slot: "reference",
            },
            [
              _vm._v("统一设置"),
              _c("yxt-svg", {
                staticClass: "v-mid ml8",
                attrs: {
                  width: "14px",
                  height: "14px",
                  "icon-class": _vm.setIconClass,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-table",
        {
          ref: "personTable",
          staticClass: "mt16",
          attrs: { data: _vm.personSetTableData, "row-key": _vm.getRowKey },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("yxt-table-column", {
            attrs: {
              type: "selection",
              width: "38",
              align: "right",
              "clear-padding": "left-right",
            },
          }),
          _c("yxt-table-column", {
            attrs: {
              prop: "name",
              label: "部门/用户组",
              "show-overflow-tooltip": true,
              "min-width": "340",
            },
          }),
          _c("yxt-table-column", {
            attrs: {
              prop: "userCount",
              label: "总人数",
              align: "right",
              width: "120",
            },
          }),
          _c("yxt-table-column", { attrs: { width: "120" } }),
          _c("yxt-table-column", {
            attrs: { prop: "userSet", label: "人数设置", "min-width": "320" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "yxt-select",
                      {
                        staticClass: "yxtbiz-enroll-settings__person-select",
                        attrs: {
                          placeholder: _vm.$t("pc_biz_ote_lbl_pleaseselect"),
                        },
                        model: {
                          value: scope.row.userSet,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "userSet", $$v)
                          },
                          expression: "scope.row.userSet",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("yxt-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    scope.row.userSet === 1
                      ? [
                          _c("yxt-input-number", {
                            staticClass: "yxtbiz-enroll-settings__person-input",
                            attrs: {
                              "controls-position": "right",
                              "step-strictly": "",
                              min: 1,
                              max: scope.row.userCount,
                            },
                            model: {
                              value: scope.row.userSetCount,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "userSetCount", $$v)
                              },
                              expression: "scope.row.userSetCount",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "yxt-row",
        { staticClass: "mt16", attrs: { type: "flex", justify: "end" } },
        [
          _c("yxt-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
              "pager-count": 5,
              total: _vm.pages,
              layout: "total, prev, pager, next",
              "simple-total": "",
              "show-on-single-page": "",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.handlerCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }