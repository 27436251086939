var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-image-viewer__container" }, [
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.noList,
            expression: "!noList",
          },
        ],
        ref: "container",
        staticClass: "yxtbiz-image-viewer__image-wrapper",
      },
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "yxtbiz-image-viewer__slide",
            class: [
              _vm.imageLiClass ? _vm.imageLiClass : "",
              _vm.previewLimit && index > _vm.previewLimit - 1
                ? "yxtbiz-image-viewer__slide--hide"
                : "",
              _vm.previewLimit && index === _vm.previewLimit - 1
                ? "yxtbiz-image-viewer__slide--last"
                : "",
            ],
          },
          [
            _c("img", {
              attrs: {
                "data-original": item.url,
                fit: "cover",
                src: item.previewUrl || item.url,
                alt: item.name || "",
              },
            }),
            _vm._t("extra", null, { item: item, index: index }),
          ],
          2
        )
      }),
      0
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show && _vm.showToolbar,
            expression: "show && showToolbar",
          },
        ],
        ref: "toolbar",
        staticClass: "yxtbiz-image-viewer__toolbar",
      },
      [
        _c("div", { staticClass: "yxtbiz-image-viewer__viewer-toolbar" }, [
          _c("div", [
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-fullscreen",
              attrs: { "data-viewer-action": "play" },
            }),
            _c("span", { attrs: { "data-viewer-action": "play" } }, [
              _vm._v(
                _vm._s(_vm.$t("pc_biz_imgviewer_btn_fullscreen" /* 全屏 */))
              ),
            ]),
          ]),
          _c("div", { on: { click: _vm.onReset } }, [
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-reset",
              attrs: { "data-viewer-action": "reset" },
            }),
            _c("span", { attrs: { "data-viewer-action": "reset" } }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_imgviewer_btn_recover" /* 还原 */))),
            ]),
          ]),
          _c("div", [
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-prev",
              attrs: { "data-viewer-action": "prev" },
            }),
            _c("span", { attrs: { "data-viewer-action": "prev" } }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_imgviewer_btn_pre" /* 上一个 */))),
            ]),
          ]),
          _c("div", { staticClass: "tool-scale" }, [
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-zoomIn",
              attrs: { "data-viewer-action": "zoom-in" },
            }),
            _c("label", { ref: "scaleText" }, [_vm._v("100%")]),
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-zoomOut",
              attrs: { "data-viewer-action": "zoom-out" },
            }),
          ]),
          _c("div", [
            _c("span", { attrs: { "data-viewer-action": "next" } }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_imgviewer_btn_next" /* 下一个 */))),
            ]),
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-next",
              attrs: { "data-viewer-action": "next" },
            }),
          ]),
          _c("div", [
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-rotateR",
              attrs: { "data-viewer-action": "rotate-right" },
            }),
            _c("span", { attrs: { "data-viewer-action": "rotate-right" } }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_imgviewer_btn_right" /* 右旋 */))),
            ]),
          ]),
          _c("div", [
            _c("i", {
              staticClass: "yxtbiz-image-viewer__viewer-icon tool-rotateL",
              attrs: { "data-viewer-action": "rotate-left" },
            }),
            _c("span", { attrs: { "data-viewer-action": "rotate-left" } }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_imgviewer_btn_left" /* 左旋 */))),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show && _vm.showNavbar,
            expression: "show && showNavbar",
          },
        ],
        ref: "navbar",
      },
      [
        _c("i", {
          staticClass:
            "yxtbiz-image-viewer__viewer-icon yxtbiz-image-viewer__nav-prev",
          attrs: { "data-viewer-action": "prev" },
        }),
        _c("div", { staticClass: "yxtbiz-image-viewer__nav-center" }, [
          _c(
            "div",
            {
              ref: "navlist",
              staticClass: "yxtbiz-image-viewer__viewer-list viewer-transition",
            },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "div",
                { key: index, class: _vm.activeIndex == index ? "active" : "" },
                [
                  _c("img", {
                    attrs: {
                      "data-viewer-action": "view",
                      "data-index": index,
                      src: item.previewUrl || item.url,
                      alt: item.name || "",
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ]),
        _c("i", {
          staticClass:
            "yxtbiz-image-viewer__viewer-icon yxtbiz-image-viewer__nav-next",
          attrs: { "data-viewer-action": "next" },
        }),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isFull,
            expression: "isFull",
          },
        ],
        ref: "switchDiv",
        staticClass: "yxtbiz-image-viewer__player-switch",
      },
      [
        _c("i", {
          staticClass: "switch-prev",
          attrs: { "data-viewer-action": "play" },
          on: {
            click: function ($event) {
              return _vm.fullscreenSwitch(-1)
            },
            mouseleave: _vm.onMouseleave,
            mouseenter: _vm.onMouseEnter,
          },
        }),
        _c("i", {
          staticClass: "switch-next",
          attrs: { "data-viewer-action": "play" },
          on: {
            click: function ($event) {
              return _vm.fullscreenSwitch(1)
            },
            mouseleave: _vm.onMouseleave,
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }