<template>
  <yxt-container>
    <yxt-aside width="524px">
      <div class="yxtbiz-image-cropper__left">
        <div
          class="yxtbiz-image-cropper__left-preview"
          v-show="status==3"
        >          
          <yxt-image
            :src="previewUrl"
            fit="contain"
          >
            <div slot="error" class="image-slot"></div>
          </yxt-image>
        </div>
        <yxt-image class="yxtbiz-image-cropper__left-main" v-show="status != 3"
            v-loading="loading"
            ref="cropper-elm"
            :src="imgUrl"
            fit="contain"
          >
          <yxtbiz-upload
            slot="error" 
            class="mr10"
            v-if="!noUpdate"
            ref="bizUpload"
            config-key="ImageConfigKey"
            :appCode="appCode"
            :source="source"
            :module-name="moduleName"
            :function-name="functionName"
            :filters="filters"
            :max-size="size"
            :files-added="onFileAdded"
            :file-filtered="onFileAdded"
            :on-uploaded="onUploaded"
            :isV1="isV1"
          >
            <div class="yxtbiz-image-cropper__tips image-slot hand">{{$t('pc_biz_imgcropper_tip_upload'/* 请上传图片 */)}}...</div>
          </yxtbiz-upload>
        </yxt-image>
        <span
          v-show="status == 2"
          class="yxtbiz-image-cropper__left-coordinate"
          >{{ coordinate.w }}*{{ coordinate.h }}</span>
      </div>
    </yxt-aside>

    <yxt-main class="yxtbiz-image-cropper__main">
      <yxt-row class="yxtbiz-image-cropper__info">
        <div>
          <label class="mr5">{{$t('pc_biz_imgcropper_lbl_suggest'/* 建议尺寸 */)}}：</label>
          <span class="color-primary-6" v-text="suggestSize"></span>
        </div>
        <!-- <div>
          <label class="mr5">原图尺寸大小：</label>
          <span class="color-primary-6" v-text="imageStr"></span>
        </div> -->
        <div>
          <label class="mr5">{{$t('pc_biz_imgcropper_lbl_filter'/* 格式 */)}}：</label>
          <span v-text="filterStr"></span>
        </div>
        <div>
          <label class="mr5">{{$t('pc_biz_imgcropper_lbl_size'/* 大小限制 */)}}：</label>
          <span v-text="sizeStr"></span>
        </div>
      </yxt-row>
      <yxt-row class="mt10">
        <yxtbiz-upload
          class="mr8"
          v-if="!noUpdate"
          ref="bizUpload"
          config-key="ImageConfigKey"
          :appCode="appCode"
          :source="source"
          :module-name="moduleName"
          :function-name="functionName"
          :filters="filters"
          :max-size="size"
          :files-added="onFileAdded"
          :file-filtered="onFileAdded"
          :on-uploaded="onUploaded"
          :isV1="isV1"
        >
          <yxt-button 
            :type="btnDisable.upload || status == 0 ? 'primary': ''" 
            :disabled="btnDisable.upload" 
            v-text="uploadBtnStr" 
            :plain="status > 0"
          ></yxt-button>
        </yxtbiz-upload>
        <yxt-button v-if="!forceCrop && !isLocal"
          :type="btnDisable.crop || status == 1 ? 'primary' : ''"
          :disabled="btnDisable.crop"
          @click="startOrResetCrop"
          v-text="cropBtnStr"
          :plain="status != 1"
        ></yxt-button>
        <yxt-button v-if="!forceCrop" class="yxtbiz-image-cropper__btn-preview"
          :type="btnDisable.preview || status == 2 ? 'primary' : ''"
          :disabled="btnDisable.preview"
          @click="showPreview"
          v-text="previewBtnStr"
          :plain="status != 2"
        ></yxt-button>
      </yxt-row>
      <yxt-row v-if="!noRate" class="mt16" v-show="status === 2 && !this.forceCrop">
        <yxt-button-group>
          <yxt-button size="medium" :type="scaleType === 1 ? 'primary' : ''" @click="changeScale(1)">1:1</yxt-button>
          <yxt-button size="medium" :type="scaleType === 2 ? 'primary' : ''" @click="changeScale(2)">4:3</yxt-button>
          <yxt-button size="medium" :type="scaleType === 3 ? 'primary' : ''" @click="changeScale(3)">16:9</yxt-button>
          <yxt-button v-show="scaleType !== 4" size="medium" @click="changeScale(4)">{{$t('pc_biz_imgcropper_lbl_custom'/* 自定义 */)}}</yxt-button>             
          <yxt-input
            size="medium"
            class="yxtbiz-image-cropper__number"
            v-model="cropBoxData"
            @change="setCropBox"
            v-show="scaleType === 4"
          ></yxt-input>
        </yxt-button-group>
      </yxt-row>
      <div class="yxtbiz-image-cropper__zoom" v-show="status == 2">
        <div class="yxtbiz-image-cropper__zoom-minus">
          <i class="yxt-icon-zoom-in" @click="zoomPlus"></i>
        </div>          
        <yxt-slider
          class="yxtbiz-image-cropper__zoom-slider"
          v-model="scale"
          vertical
          :min="0"
          :max="1"
          :step="0.1"
          height="152px"
          :show-tooltip="false"
          @change="sliderChange"
        ></yxt-slider>
        <div class="yxtbiz-image-cropper__zoom-plus">
          <i class="yxt-icon-zoom-out" @click="zoomMinus"></i>
        </div>          
      </div>
    </yxt-main>
  </yxt-container>
</template>

<script>
import Cropper from 'cropperjs';
import { fileUpload, getMixingInfo } from './service';
import YxtbizUpload from 'yxt-biz-pc/packages/upload';
import {
  Container,
  Main,
  Slider,
  Image as YxtImage,
  Aside,
  ButtonGroup
} from 'yxt-pc';
const ScaleType = {
  None: 0,
  T11: 1,
  T43: 2,
  T169: 3,
  Tcustom: 4, // 用户自定义
  Trandom: 5 // 用户输入比例
};
const CropStatus = {
  Default: 0,
  Uploaded: 1,
  Cropping: 2,
  Preview: 3
};
let fileName = '';
let zoom = 0;
export default {
  name: 'Cropper',
  components: {
    YxtbizUpload,
    YxtContainer: Container,
    YxtMain: Main,
    YxtSlider: Slider,
    YxtImage,
    YxtAside: Aside,
    YxtButtonGroup: ButtonGroup
  },
  props: {
    source: {
      type: String,
      default: '501'
    },
    appCode: {
      type: String,
      default: 'demo'
    },
    moduleName: {
      type: String,
      default: 'test_module'
    },
    functionName: {
      type: String,
      default: 'test_function'
    },
    src: {
      type: String,
      default: ''
    },
    suggest: {
      type: Object,
      default() {
        return {
          w: 750,
          h: 348
        };
      }
    },
    rate: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 512
    },
    filters: {
      type: String,
      default: '.jpg,.gif,.png,.jpeg,.bmp'
    },
    noUpdate: {
      type: Boolean,
      default: false
    },
    oversizeMsg: String,
    filterMsg: String,
    isV1: Boolean,
    forceCrop: Boolean,
    fixedOutputSize: Boolean,
    noRate: Boolean
  },
  data() {
    return {
      saveLoaing: false,
      isOrigin: true,
      imgUrl: this.src,
      previewUrl: this.src,
      scaleType: ScaleType.None,
      status: CropStatus.Default,
      uploadBtnStr: this.$t('pc_biz_imgcropper_title'/* 上传图片 */),
      previewBtnStr: this.$t('pc_biz_imgcropper_btn_preview'/* 预览 */),
      cropBtnStr: this.$t('pc_biz_imgcropper_btn_crop'/* 裁剪 */),
      btnDisable: {
        upload: false,
        crop: true,
        preview: true
      },
      cropper: null,
      coordinate: {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      },
      cropBoxData: this.$t('pc_biz_imgcropper_lbl_custom'/* 自定义 */),
      cropBox: {
        w: 0,
        h: 0
      },
      imageSize: {
        w: 0,
        h: 0
      },
      imageInnerSize: {
        w: 0,
        h: 0
      },
      scale: 0,
      loading: false,
      noEdit: true,
      // 是否私有化
      isLocal: false,
      file: {}
    };
  },
  computed: {
    imageStr() {
      return `${this.imageSize.w}*${this.imageSize.h}`;
    },
    suggestSize() {
      return `${this.suggest.w}*${this.suggest.h} ${this.rate ? '(' + this.rate + ')' : ''}`;
    },
    filterStr() {
      return this.filters.replace(/\./g, '').replace(/,/g, '、');
    },
    sizeStr() {
      let size = this.size;
      if (size < 1024) return size.toFixed(2) + 'K';
      if ((size = size / 1024) >= 1024) {
        return parseInt(size / 1024, 10) + 'GB';
      }
      return parseInt(size, 10) + 'MB';
    }
  },
  async mounted() {
    this.init(this.imgUrl);
    this.isLocal = await this.getUploadBucketType();
  },
  methods: {
    init(url) {
      this.imageSize = {w: 0, h: 0};
      this.imgUrl = url;
      this.previewUrl = this.imgUrl;
      if (this.imgUrl) {
        this.setBtnStatus(CropStatus.Uploaded);
        this.getImageWidth(this.imgUrl);
      } else {
        this.setBtnStatus(CropStatus.Default);
      }
      switch (this.rate) {
        case '1:1':
          this.scaleType = ScaleType.T11;
          break;
        case '4:3':
          this.scaleType = ScaleType.T43;
          break;
        case '16:9':
          this.scaleType = ScaleType.T169;
          break;
        default:
          this.scaleType = this.isvalid(this.rate) && this.forceCrop
            ? ScaleType.Trandom
            : this.scaleType = ScaleType.Tcustom;
          break;
      }
    },
    isvalid(rate = '') {
      const { w, h } = this.getRamdonRate(rate);
      return w > 0 && h > 0;
    },
    getRamdonRate(rate = '') {
      const [ w, h ] = rate.split(':');
      return { w: w || 0, h: h || 0 };
    },
    changeScale(val) {
      if (this.cropper && val === this.scaleType) return;
      this.noEdit = false;
      let imageElm = this.$refs['cropper-elm'].$el.getElementsByTagName('img').item(0);
      let data = this.cropper ? this.cropper.getCropBoxData() : {};
      this.cropper && this.cropper.destroy();
      let options = this.getDefaultCropOption();
      this.scaleType = val;
      const numRound = num => Math.round(num * 10e3) / 10e3;
      const getCropBox = rate => {
        let imageRate = this.imageSize.w / this.imageSize.h;
        let size = { width: this.imageSize.w, height: this.imageSize.h };
        if (rate > imageRate) { // 建议尺寸宽高比大于图片实际宽高比
          // 定宽，宽取实际 & 建议 较小值
          // 传给插件值宽，还需要经过一轮显示宽与实际图宽的比例计算
          size.width = this.imageInnerSize.w * Math.min(this.suggest.w, size.width) / size.width;
          // 按建议宽高比计算高度
          size.height = numRound(size.width / rate);
          size.width = numRound(size.width);
        } else {
          size.height = this.imageInnerSize.h * Math.min(this.suggest.h, size.height) / size.height;
          size.width = numRound(size.height * rate);
          size.height = numRound(size.height);
        }
        // 显示区域宽高500，计算左 & 上留白
        size.left = 250 - size.width / 2;
        size.top = 250 - size.height / 2;
        return size;
      };
      switch (val) {
        case ScaleType.T11:
          options.aspectRatio = 1;
          data = getCropBox(options.aspectRatio);
          break;
        case ScaleType.T43:
          options.aspectRatio = 4 / 3;
          data = getCropBox(options.aspectRatio);
          break;
        case ScaleType.T169:
          options.aspectRatio = 16 / 9;
          data = getCropBox(options.aspectRatio);
          break;
        case ScaleType.Trandom:
          const c = this.getRamdonRate(this.rate);
          options.aspectRatio = c.w / c.h;
          data = getCropBox(options.aspectRatio);
          break;
        case ScaleType.Tcustom:
          data = getCropBox(this.suggest.w / this.suggest.h);
          this.cropBox = {
            w: data.width,
            h: data.height
          };
          options.aspectRatio = 'NaN';
          break;
        default:
          break;
      }
      options.ready = () => {
        this.cropper.setCropBoxData(data);
        this.scale > 0 && this.cropper.zoom(this.scale);
      };
      this.cropper = new Cropper(imageElm, options);
    },
    setCropBox(val) {
      val = val.split('*');
      if (val.length > 1) {
        if (val[0] >= this.imageSize.w) {
          this.cropBox.w = this.imageSize.w;
        } else {
          this.cropBox.w = +val[0]
            .replace(/\D/g, '')
            .replace(/^0[0-9]*/g, '0');
        }
        if (val[1] >= this.imageSize.h) {
          this.cropBox.h = this.imageSize.h;
        } else {
          this.cropBox.h = +val[1]
            .replace(/\D/g, '')
            .replace(/^0[0-9]*/g, '0');
        }
      }
      const imageData = this.cropper.getImageData();
      const ratio = imageData.naturalWidth / imageData.width;
      let data = {
        width: parseFloat(this.cropBox.w / ratio, 10),
        height: parseFloat(this.cropBox.h / ratio, 10)
      };
      this.cropper.setCropBoxData(data);
    },
    startOrResetCrop() {
      this.noEdit = false;
      if (this.status === CropStatus.Uploaded) {
        this.changeScale(this.scaleType);
        this.setBtnStatus(CropStatus.Cropping);
        this.isOrigin = false;
      } else {
        this.scale = 0;
        this.coordinate = {
          x: 0,
          y: 0,
          w: this.imageSize.w,
          h: this.imageSize.h
        };
        zoom = 0;
        this.cropper.destroy();
        this.cropper = null;
        this.setBtnStatus(CropStatus.Uploaded);
        this.isOrigin = true;
      }
    },
    showPreview() {
      if (this.status === CropStatus.Cropping) {
        this.setBtnStatus(CropStatus.Preview);
      } else {
        this.setBtnStatus(CropStatus.Cropping);
      }
    },
    getDefaultCropOption() {
      let _this = this;
      return {
        background: false,
        zoomOnWheel: false,
        viewMode: 2,
        crop: e => {
          _this.updateCoords(e.detail);
        }
      };
    },
    updateCoords(c) {
      this.coordinate = {
        x: Math.round(c.x),
        y: Math.round(c.y),
        w: Math.round(c.width),
        h: Math.round(c.height)
      };
      this.cropBox = {
        w: this.coordinate.w,
        h: this.coordinate.h
      };
      this.cropBoxData = `${this.cropBox.w}*${this.cropBox.h}`;
    },
    async getUploadBucketType() {
      const mixinRes = await getMixingInfo();
      let isLocalApi = false;
      let { localSwith, uploadToCloud } = mixinRes;
      if (!uploadToCloud) {
        uploadToCloud = '0';
      }
      if (!localSwith) {
        localSwith = 0;
      }
      // isLocalApi = !(localSwith === 1 && uploadToCloud === '1');
      if (localSwith === 1 && uploadToCloud === '1') {
        // 私有云
        isLocalApi = true;
      } else {
        // 公有云
        isLocalApi = false;
      }
      return isLocalApi;
    },
    save() {
      if (this.saveLoaing) return;
      if (!this.isOrigin) {
        if (this.coordinate.w > 4096 && this.coordinate.w >= this.coordinate.h) {
          this.coordinate.h = Math.round(this.coordinate.h * 4096 / this.coordinate.w);
          this.coordinate.w = 4096;
          this.$message(this.$t('pc_biz_imgcropper_msg_size'/* 图片尺寸过大，保存后可能会有部分内容缺失！ */));
        } else if (this.coordinate.h > 4096 && this.coordinate.h >= this.coordinate.w) {
          this.coordinate.w = Math.round(this.coordinate.w * 4096 / this.coordinate.h);
          this.coordinate.h = 4096;
          this.$message(this.$t('pc_biz_imgcropper_msg_size'/* 图片尺寸过大，保存后可能会有部分内容缺失！ */));
        }
      }
      let url = this.getPreviewUrl();
      const orgCode = window.localStorage.getItem('orgCode') || 'xx-phx';
      let isLocal = this.isLocal;
      const param = `?configkey=ImageConfigKey&filefullpath=${url}&fileName=${window.encodeURIComponent(fileName)}&buckettype=${isLocal ? 2 : 1}&isneedconvert=0&platform=${isLocal ? 'local' : 'baidu'}`;
      this.saveLoaing = true;
      if (isLocal) {
        this.saveLoaing = false;
        console.log({
          fileDomain: this.file.fileDomain,
          fileKey: this.file.fileKey,
          width: this.suggest.w,
          height: this.suggest.h
        }, '私有化场景 参数');
        this.$emit('complete', url, {
          fileDomain: this.file.fileDomain,
          fileKey: this.file.fileKey,
          width: this.suggest.w,
          height: this.suggest.h
        });
      } else {
        this.$emit('loading', true);
        fileUpload(this.appCode, {
          orgCode,
          moduleName: this.moduleName,
          funcName: this.functionName,
          isV1: this.isV1
        }, param).then(res => {
          if (res) {
            url = res.fileDomain + res.fileKey;
            res.id = res.fileId;
            res.name = fileName;
          }
          this.saveLoaing = false;
          this.$emit('loading', false);
          this.$emit('complete', url, res);
        }, err => {
          this.saveLoaing = false;
          this.$emit('loading', false);
          console.error(err);
          this.$emit('complete', url);
        });
      }
    },
    onUploaded(file) {
      this.imgUrl = file.fullUrl;
      this.file = file;
      this.getImageWidth(this.imgUrl);
      this.loading = false;
    },
    getImageWidth(url) {
      // 得到图片实际宽高
      var img = new Image();
      img.src = url;
      let _this = this;
      // 如果图片被缓存，则直接返回缓存数据
      const solveImgSize = () => {
        _this.imageSize = {
          w: img.width,
          h: img.height
        };
        _this.setInnerSize();
        _this.coordinate = {
          x: 0,
          y: 0,
          w: img.width,
          h: img.height
        };
        _this.setBtnStatus(CropStatus.Uploaded);
        if (this.forceCrop) setTimeout(() => _this.startOrResetCrop());
      };
      if (img.complete) {
        solveImgSize();
      } else {
        // 完全加载完毕的事件
        img.onload = () => {
          solveImgSize();
        };
        img.onerror = (e) => {
          this.status = CropStatus.Default;
          this.$emit('save-disable', true);
          this.$message(this.$t('pc_biz_imgcropper_msg_loaderror'/* 图片加载异常，请更换图片！*/));
        };
      }
    },
    setInnerSize() {
      this.imageInnerSize.w = this.imageSize.w;
      this.imageInnerSize.h = this.imageSize.h;
      let imageRate = this.imageSize.w / this.imageSize.h;
      if (imageRate > 1) {
        this.imageInnerSize.w = 500;
        this.imageInnerSize.h = this.imageInnerSize.w / imageRate;
      } else {
        this.imageInnerSize.h = 500;
        this.imageInnerSize.w = this.imageInnerSize.h * imageRate;
      }
    },
    htmlEncode(str) {
      return str ? str.replace(/</g, '&lt;').replace(/>/g, '&gt;') : '';
    },
    setBtnStatus(status) {
      switch (status) {
        case CropStatus.Uploaded:
          if (this.cropper) {
            this.cropper.destroy();
            this.cropper = null;
          }
          this.btnDisable = {
            upload: false,
            preview: true,
            crop: false
          };
          this.uploadBtnStr = this.$t('pc_biz_imgcropper_btn_change'/* 更换图片 */);
          this.cropBtnStr = this.$t('pc_biz_imgcropper_btn_crop'/* 裁剪 */);
          break;
        case CropStatus.Cropping:
          this.btnDisable = {
            upload: false,
            preview: false,
            crop: false
          };
          this.cropBtnStr = this.$t('pc_biz_imgcropper_btn_recover'/* 恢复原图 */);
          this.previewBtnStr = this.$t('pc_biz_imgcropper_btn_preview'/* 预览 */);
          break;
        case CropStatus.Preview:
          this.btnDisable = {
            upload: true,
            preview: false,
            crop: true
          };
          this.previewBtnStr = this.$t('pc_biz_imgcropper_btn_cancel'/* 取消预览 */);
          this.previewUrl = this.getPreviewUrl();
          break;
        default:
          this.btnDisable = {
            upload: false,
            crop: true,
            preview: true
          };
          this.previewBtnStr = this.$t('pc_biz_imgcropper_btn_preview'/* 预览 */);
          this.cropBtnStr = this.$t('pc_biz_imgcropper_btn_crop'/* 裁剪 */);
          this.uploadBtnStr = this.$t('pc_biz_imgcropper_title'/* 上传图片 */);
          this.previewUrl = '';
          this.imgUrl = '';
          this.scale = 0;
          zoom = 0;
          if (this.cropper) {
            this.cropper.destroy();
            this.cropper = null;
          }
          break;
      }
      this.status = status;
      this.$emit('save-disable', this.status === 0);
    },
    getPreviewUrl() {
      if (this.isLocal) {
        return this.imgUrl;
      }
      return this.isOrigin ? this.imgUrl : (this.htmlEncode(this.imgUrl) +
        '?x-bce-process=image/auto-orient,o_1/crop,x_' +
        Math.round(this.coordinate.x) +
        ',y_' +
        Math.round(this.coordinate.y) +
        ',w_' +
        Math.round(this.coordinate.w) +
        ',h_' +
        Math.round(this.coordinate.h) +
        (this.fixedOutputSize && this.suggest.w && this.suggest.h ? `/resize,w_${this.suggest.w},h_${this.suggest.h}` : ''));
    },
    zoomPlus() {
      if (this.scale < 0.99) {
        this.scale += 0.1;
        zoom = this.scale;
        this.cropper && this.cropper.zoom(0.1);
      }
    },
    zoomMinus() {
      if (this.scale > 0) {
        this.scale -= 0.1;
        zoom = this.scale;
        this.cropper && this.cropper.zoom(-0.1);
      }
    },
    sliderChange(v) {
      this.cropper && this.cropper.zoom(v - zoom);
      zoom = v;
    },
    onFileAdded(file) {
      if (file instanceof Array) file = file[0];
      if (file) {
        if (file.error === 'oversize') {
          this.$message.error(this.oversizeMsg || this.$t('pc_biz_imgcropper_msg_oversize'/* 您选择的内容大小已超过允许的大小，请确认! */));
        } else if (file.error === 'forbidden') {
          this.$message.error(this.filterMsg || this.$t('pc_biz_imgcropper_msg_type'/* 您选择的内容格式不符合，请确认! */));
        } else {
          fileName = file.name;
          this.loading = true;
        }
      }
    }
  },
  destroyed() {
    this.cropper && this.cropper.destroy();
  }
};
</script>
