import { fileApi } from 'yxt-biz-pc/packages/api';

export const getFileInfo = id => {
  return fileApi.get('/fileInfo/detail', {params: {id}});
};

export const getPlayInfo = fileId => {
  return fileApi.get('/play/detail', {params: {fileId}});
};

export const getFileHtml = fileId => {
  return fileApi.get('/config/getParam4Html', {params: {fileId}});
};

export const getFileList = params => fileApi.get('/play/detailAndWatermark', params);
