<template>
<div class="yxtbiz-ai-project-assistant-input-chat">
    <InputShortcut @change="handleShortcutChange" />
    <div class="yxtbiz-ai-project-assistant-input-chat__suffix">
     <label v-if="showClear"  
      @mousedown.prevent
      @mouseenter.stop.prevent="isHover = true;"
      @mouseleave="isHover = false" @click="handleClear" class="yxtbiz-ai-project-assistant-input-chat__clear" for="projectAssistantTextarea"><i  class="yxt-icon-close font-size-16"></i></label>
     <yxt-tooltip :content="$t('pc_biz_ai_send' /* 发送 */)" placement="top">
      <yxt-svg
        @click.native="handleSendClick"
        :remote-url='mediaPath'
        icon-class="ai-project-assistant-send"
        class="yxtbiz-ai-project-assistant-input-chat__send"
        :class="sendDisabled ? 'yxtbiz-ai-project-assistant-input-chat__send--disabled' : 'yxtbiz-ai-project-assistant-input-chat__send--primary'"
      ></yxt-svg>
    </yxt-tooltip>
    </div>
    <yxt-input
    @focus="focused = true"
    @blur="focused = false"
    ref="textarea"
    type="textarea"
    id="projectAssistantTextarea"
    :autosize="{minRows: 1}"
    :clearable="true"
    :placeholder="$t('pc_biz_express_your_requirement' /* 向助手描述你的诉求… */)"
    @input="$emit('input', $event)"
    :value="value"
     />
</div>
</template>

<script>
import InputShortcut from './input-shortcut.vue';

export default {
  props: {
    sendDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isHover: false,
      focused: false
    };
  },
  components: {
    InputShortcut
  },
  computed: {
    showClear: ({focused, isHover, value}) => (focused || isHover) && value,
    mediaPath: ({$staticBaseUrl}) => `${$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`
  },
  methods: {
    handleClear() {
      this.$emit('input', '');
    },
    handleShortcutChange(item) {
      this.$emit('input', item.name + ' : ');
      this.$refs.textarea.focus();
    },
    handleSendClick() {
      if (this.sendDisabled) return;
      this.$emit('send');
    }
  }
};
</script>
<style scoped lang="scss">
$root: '.yxtbiz-ai-project-assistant-input-chat';
#{$root} {
  position: relative;

  &__send {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    user-select: none;

    &:hover {
      background-color: #F5F5F5;
    }

    &--primary {
      color: var(--color-primary-6);
      cursor: pointer;
    }

    &--disabled {
      color: #d9d9dd;
      cursor: not-allowed;
    }
    
  }

  &__suffix {
    position: absolute;
    right: 12px;
    bottom: 6px;
    z-index: 1;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    .yxt-icon-close {
      color: #8c8c8c;
      user-select: none;
      cursor: pointer;
    }
  }

  &__clear {
    margin-right: 8px;
    &:hover {
      background: #F0F0F0;
    }
    height: 16px;
    width: 16px;
    line-height: 16px;
  }

  /deep/ textarea {
    padding-left: 40px;
    padding-right: 72px;
    word-break: break-all;
  }
}
</style>