<template>
  <div class="yxtbiz-enroll-settings-person_set">
    <yxt-popover
      placement="bottom-start"
      popper-class="yxtbiz-enroll-settings-custom_popover"
      width="348"
      v-model="setVisible"
      trigger="manual"
      :visible-arrow="false">
      <yxt-form :model="personForm" class="yxtbiz-enroll-settings-form_val">
        <yxt-form-item label="人数设置" prop="numSet">
          <yxt-select v-model="personForm.numSet" :placeholder="$t('pc_biz_ote_lbl_pleaseselect')" class="wline">
            <yxt-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </yxt-option>
          </yxt-select>
        </yxt-form-item>

        <yxt-form-item v-show="personForm.numSet === 1" class="mt8">
          <yxt-input-number v-model="personForm.maxPersonCount" class="wline" controls-position="right" step-strictly :min="1" :max="999999999">
          </yxt-input-number>
        </yxt-form-item>

        <yxt-form-item class="text-right mt24">
          <yxt-button plain @click="cancelPopover">{{ $t('pc_biz_enroll_btn_cancle') }}</yxt-button>
          <yxt-button type="primary" @click="confirmPopover">{{ $t('pc_biz_enroll_btn_confirm') }}</yxt-button>
        </yxt-form-item>
      </yxt-form>
      <yxt-button slot="reference" size="large" type="primary" @click="showDrawer" :disabled="disabledBtn">统一设置<yxt-svg class="v-mid ml8" width="14px" height="14px" :icon-class="setIconClass" /></yxt-button>
    </yxt-popover>


    <yxt-table
      ref="personTable"
      :data="personSetTableData"
      class="mt16"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange">
      <yxt-table-column
        type="selection"
        width="38"
        align="right"
        clear-padding="left-right">
      </yxt-table-column>
      <yxt-table-column
        prop="name"
        label="部门/用户组"
        :show-overflow-tooltip="true"
        min-width="340">
      </yxt-table-column>
      <yxt-table-column
        prop="userCount"
        label="总人数"
        align="right"
        width="120">
      </yxt-table-column>
      <yxt-table-column width="120"></yxt-table-column>
      <yxt-table-column
        prop="userSet"
        label="人数设置"
        min-width="320">
        <template slot-scope="scope">
          <yxt-select v-model="scope.row.userSet" class="yxtbiz-enroll-settings__person-select" :placeholder="$t('pc_biz_ote_lbl_pleaseselect')">
            <yxt-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </yxt-option>
          </yxt-select>

          <template v-if="scope.row.userSet === 1">
            <yxt-input-number v-model="scope.row.userSetCount" class="yxtbiz-enroll-settings__person-input" controls-position="right" step-strictly :min="1" :max="scope.row.userCount">
            </yxt-input-number>
          </template>
        </template>
      </yxt-table-column>
    </yxt-table>

    <yxt-row class="mt16" type="flex" justify="end">
      <yxt-pagination
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pager-count="5"
        :total="pages"
        layout="total, prev, pager, next"
        simple-total
        show-on-single-page
        @current-change="handlerCurrentChange">
      </yxt-pagination>
    </yxt-row>
  </div>
</template>

<script>
export default {
  name: 'PersonSet',
  props: {
    list: {
      type: Boolean,
      default: []
    }
  },
  components: {},
  data() {
    return {
      personForm: {
        numSet: 0,
        maxPersonCount: 0
      },

      setVisible: false,
      iconName: 'arrow-down',

      options: [{
        value: 0,
        label: this.$t('pc_biz_udp_lbl_unlimited')
      }, {
        value: 1,
        label: '最大人数'
      }],

      selectionLists: [],
      personSetTableData: [],

      currentPage: 1,
      pageSize: 20
    };
  },
  computed: {
    setIconClass() {
      return this.setVisible ? 'arrow-up' : 'arrow-down';
    },

    disabledBtn() {
      return this.selectionLists.length === 0;
    },

    pages() {
      return this.list.length || 0;
    }
  },
  created() {
    this.handlerTableData();
  },
  methods: {
    // 获取table的row-key
    getRowKey(row) {
      return row.id;
    },

    handlerTableData() {
      this.personSetTableData = this.list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize).map(item => {
        return {
          ...item,
          userSet: 0,
          userSetCount: item.userCount
        };
      });
    },

    cancelPopover() {
      this.setVisible = false;
      setTimeout(() => {
        this.personForm.numSet = 0;
      }, 500);
    },

    showDrawer() {
      this.setVisible = !this.setVisible;
    },

    handlerCurrentChange(v) {
      this.currentPage = v;
      this.handlerTableData();
    },

    // 选择选中的部门
    handleSelectionChange(v) {
      this.selectionLists = v;
    },

    // 确认人数设置
    confirmPopover() {
      let isMaxUserCount = this.selectionLists.filter(item => {
        return this.personForm.maxPersonCount > item.userCount;
      });

      this.selectionLists.forEach(list => {
        list.userSet = this.personForm.numSet;

        if (this.personForm.numSet === 1) {
          list.userSetCount = this.personForm.maxPersonCount;
        }
      });

      if (isMaxUserCount.length) {
        this.$message.info('有部分部门或用户组人数不足，已设置为总人数');
      }

      this.cancelPopover();
      this.$refs.personTable.clearSelection();
    }
  }
};
</script>
