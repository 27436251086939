(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("yxt-biz-pc/packages/api"), require("YXTPC"), require("Vue"), require("__hack_yxtbiz_I18n"), require("axios"), require("__hack_yxtbiz_OpenData"), require("__hack_yxtbiz_Factor"), require("Vuex"));
	else if(typeof define === 'function' && define.amd)
		define([, "YXTPC", "Vue", "__hack_yxtbiz_I18n", "axios", "__hack_yxtbiz_OpenData", "__hack_yxtbiz_Factor", "Vuex"], factory);
	else if(typeof exports === 'object')
		exports["YXTBIZTOOL"] = factory(require("yxt-biz-pc/packages/api"), require("YXTPC"), require("Vue"), require("__hack_yxtbiz_I18n"), require("axios"), require("__hack_yxtbiz_OpenData"), require("__hack_yxtbiz_Factor"), require("Vuex"));
	else
		root["YXTBIZTOOL"] = factory(root["YXTBIZ"]["Api"], root["YXTPC"], root["Vue"], root["__hack_yxtbiz_I18n"], root["axios"], root["__hack_yxtbiz_OpenData"], root["__hack_yxtbiz_Factor"], root["Vuex"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__35__) {
return 