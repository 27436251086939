var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-project-assistant-page-turning" },
    [
      _c("i", {
        staticClass:
          "yxt-icon-arrow-left yxtbiz-ai-project-assistant-page-turning__icon",
        class: {
          "yxtbiz-ai-project-assistant-page-turning__icon--disabled":
            _vm.current === 1,
        },
        on: { click: _vm.prev },
      }),
      _c(
        "div",
        { staticClass: "yxtbiz-ai-project-assistant-page-turning__text" },
        [_vm._v(_vm._s(_vm.current) + "/" + _vm._s(_vm.total))]
      ),
      _c("i", {
        staticClass:
          "yxt-icon-arrow-right yxtbiz-ai-project-assistant-page-turning__icon",
        class: {
          "yxtbiz-ai-project-assistant-page-turning__icon--disabled":
            _vm.current === _vm.total,
        },
        on: { click: _vm.next },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }