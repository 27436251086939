<template>
<div class="yxtbiz-ai-project-assistant-button">
  <div @click="handleClick">
    <yxt-svg
      :remote-url='mediaPath'
      icon-class="o2o-ai-project-assistant-avatar"
      class="yxtbiz-ai-project-assistant-button__avatar"
    ></yxt-svg>{{$t('pc_biz_ai_assistant' /* 智能助手 */)}}
    <!-- 新手引导先去除 -->
    <!-- <div class="yxtbiz-ai-project-assistant-button__dot"></div> -->
  </div>
  <ModalMode ::highPriorityQuestionType="highPriorityQuestionType" :zIndex="modalZIndex" :visible.sync="modalVisible" />
</div>
  
</template>

<script>
import ModalMode from '../modal/index.vue';
import {propsMixin} from '../common.js';

export default {
  mixins: [propsMixin],
  components: {
    ModalMode
  },
  props: {
    modalZIndex: Number
  },
  data() {
    return {
      modalVisible: false
    };
  },
  computed: {
    mediaPath() {
      return `${this.$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`;
    }
  },
  methods: {
    handleClick() {
      this.modalVisible = true;
    }
  }
};
</script>
<style scoped lang="scss">
$root: '.yxtbiz-ai-project-assistant-button';
#{$root} {
  > :first-child {
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    color: #595959;
    position: relative;
    padding-left: 12px;
    padding-right: 16px; 
    cursor: pointer;
    user-select: none;
    border-radius: 4px;

    &:hover {
      background: #F5F5F5;
    }
  }

  &__avatar {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  &__dot {
    position: absolute;
    top: 4px;
    right: 10px;
    width: 6px;
    height: 6px;
    background: #F5222D;
    border-radius: 50%;
  }
}

</style>