var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-project-assistant-operate-button",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.$slots.icon
        ? _vm._t("icon")
        : _c("i", {
            class: [
              _vm.iconClass,
              "yxtbiz-ai-project-assistant-operate-button__icon",
            ],
          }),
      _vm._v("\n  " + _vm._s(_vm.text) + "\n"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }