var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-project-assistant-input-chat" },
    [
      _c("InputShortcut", { on: { change: _vm.handleShortcutChange } }),
      _c(
        "div",
        { staticClass: "yxtbiz-ai-project-assistant-input-chat__suffix" },
        [
          _vm.showClear
            ? _c(
                "label",
                {
                  staticClass: "yxtbiz-ai-project-assistant-input-chat__clear",
                  attrs: { for: "projectAssistantTextarea" },
                  on: {
                    mousedown: function ($event) {
                      $event.preventDefault()
                    },
                    mouseenter: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.isHover = true
                    },
                    mouseleave: function ($event) {
                      _vm.isHover = false
                    },
                    click: _vm.handleClear,
                  },
                },
                [_c("i", { staticClass: "yxt-icon-close font-size-16" })]
              )
            : _vm._e(),
          _c(
            "yxt-tooltip",
            {
              attrs: {
                content: _vm.$t("pc_biz_ai_send" /* 发送 */),
                placement: "top",
              },
            },
            [
              _c("yxt-svg", {
                staticClass: "yxtbiz-ai-project-assistant-input-chat__send",
                class: _vm.sendDisabled
                  ? "yxtbiz-ai-project-assistant-input-chat__send--disabled"
                  : "yxtbiz-ai-project-assistant-input-chat__send--primary",
                attrs: {
                  "remote-url": _vm.mediaPath,
                  "icon-class": "ai-project-assistant-send",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleSendClick.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("yxt-input", {
        ref: "textarea",
        attrs: {
          type: "textarea",
          id: "projectAssistantTextarea",
          autosize: { minRows: 1 },
          clearable: true,
          placeholder: _vm.$t(
            "pc_biz_express_your_requirement" /* 向助手描述你的诉求… */
          ),
          value: _vm.value,
        },
        on: {
          focus: function ($event) {
            _vm.focused = true
          },
          blur: function ($event) {
            _vm.focused = false
          },
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }