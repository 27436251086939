<template>
  <div class="yxtbiz-enroll-settings color-gray-9">
    <yxt-form
      ref="settingsForm"
      :model="settings"
      :rules="rules"
      label-width="100px"
      label-position="right"
    >
      <!-- 正式名额 -->
      <yxt-form-item
        v-if="fillActualUser"
        :label="$t('pc_biz_enroll_lbl_officialquota')"
        prop="actualUserCount"
      >
        <yxt-input-number
          v-model="settings.actualUserCount"
          class="w128"
          controls-position="right"
          step-strictly
          :min="1"
          :max="999999999"
        >
        </yxt-input-number>
      </yxt-form-item>
      <!-- 允许报名数 -->
      <yxt-form-item
        v-if="fillMaxUser"
        :label="$t('pc_biz_enroll_lbl_numallowed')"
        prop="maxUserCount"
      >
        <yxt-input-number
          v-model="settings.maxUserCount"
          class="w128"
          controls-position="right"
          step-strictly
          :min="1"
          :max="999999999"
        >
        </yxt-input-number>
      </yxt-form-item>
      <!-- 积分 -->
      <yxt-form-item
        v-if="fillPoint"
        :label="$t('pc_biz_enroll_lbl_integral')"
        prop="point"
        :label-tooltip="$t('pc_biz_enroll_tip_zeroforfree')"
        label-tooltip-width="200"
      >
        <yxt-input-number
          v-model="settings.point"
          class="w128"
          controls-position="right"
          :min="0"
          :max="99999"
          step-strictly
          :placeholder="$t('pc_biz_enroll_tip_zeroforfree')"
        >
        </yxt-input-number>
      </yxt-form-item>
      <!-- 报名范围 -->
      <yxt-form-item
        :label="$t('pc_biz_enroll_lbl_enrollrange')"
        prop="enrollRange"
      >
        <settings-checked
          :list="settings.enrollRange"
          :placeholder="
            $t('pc_biz_enroll_lbl_errollrange_placeholder' /*请选择报名范围*/)
          "
          class="w700"
          :error.sync="errorOptions.enrollRangeError"
          :errorText="
            $t('pc_biz_enroll_msg_allfollowup' /* 报名范围不能为空 */)
          "
          @click="showDrawer('enrollRange')"
          @delete="deleteChecked(settings.enrollRange, $event, 'enrollRange')"
        ></settings-checked>
        <!-- 暂时不做 V5做 先隐藏 -->
        <!-- <span class="ml12" v-show="showPersonSet">
        <yxt-button type="text" class="v-mid" @click="showPersonSetDrawer">人数设置</yxt-button>

        <yxt-tooltip placement="top" content="设置部门与用户组的报名人数范围，当报名列表内学员人数超过限制时对应部门或用户组则不可报名。" max-width="360">
          <yxt-svg :remote-url='mediaPath' class="color-gray-6 v-mid ml2" width="16px" height="16px" icon-class="icons/question-cirlce-o" />
        </yxt-tooltip>
      </span> -->
      </yxt-form-item>
      <yxt-form-item label="">
        <div class="lh22">
          <yxt-checkbox v-model="showExclude" @change="changeExclude">{{
            $t('pc_biz_enroll_btn_addexclusions')
          }}</yxt-checkbox>
        </div>
      </yxt-form-item>
      <!-- 排除人员 -->
      <yxt-form-item
        v-show="showExclude"
        :label="$t('pc_biz_enroll_lbl_exclude_user' /* 排除人员 */)"
        prop="excludeRange"
      >
        <settings-checked
          :placeholder="
            $t('pc_biz_enroll_lbl_exclude_user_placeholder' /*请选择排除人员*/)
          "
          :list="settings.excludeRange"
          class="w700"
          :error.sync="errorOptions.enrollExcludeRange"
          :errorText="
            $t(
              'pc_biz_enroll_lbl_exclude_user_placeholder' /* 报名范围不能为空 */
            )
          "
          @click="showDrawer('excludeRange')"
          @delete="deleteChecked(settings.excludeRange, $event, 'excludeRange')"
        ></settings-checked>
      </yxt-form-item>
      <!-- 报名时间 -->
      <yxt-form-item
        :label="$t('pc_biz_enroll_lbl_enrolltime')"
        prop="enrollTime"
      >
        <div>
          <yxt-date-picker
            v-model="settings.enrollTime"
            class="w424"
            type="datetimerange"
            :range-separator="$t('pc_biz_enroll_to')"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
          ></yxt-date-picker>
        </div>
      </yxt-form-item>
      <!-- 代报名设置 -->
      <yxt-form-item
        v-if="fillReplaceEnabled"
        :label="$t('pc_biz_enroll_lbl_open_agent' /* 代报名设置 */)"
        prop="replaceEnabled"
        :label-tooltip="$t('pc_biz_enroll_tip_replaceEnroll')"
        label-tooltip-width="200"
      >
        <yxt-switch
          v-model="settings.replaceEnabled"
          :active-value="1"
          :inactive-value="0"
          :width="28"
        ></yxt-switch>
        <span>{{
          $t('pc_biz_enroll_lbl_open_agent_set' /* 开启代报名 */)
        }}</span>
        <div
          v-show="settings.replaceEnabled"
          class="yxtbiz-enroll-settings__agent mt6"
          :class="{ 'pb24-impt': settings.replaceType === 3 }"
        >
          <yxt-radio-group v-model="settings.replaceType" :direction="'row'">
            <!-- <yxt-radio :label="1" class="lh22">{{ $t('pc_biz_enroll_lbl_check_type1' /* 部门经理代报名 */) }}</yxt-radio>
            <yxt-radio :label="2" class="lh22">{{ $t('pc_biz_enroll_lbl_check_type2' /* 直属经理代报名 */) }}</yxt-radio>
            <yxt-radio :label="3" class="lh22">{{ $t('pc_biz_enroll_lbl_check_type3' /* 指定人员代报名 */) }}</yxt-radio> -->
            <yxt-radio :label="1" class="lh22">{{
              $t('pc_biz_enroll_lbl_check_type1' /* 部门经理代报名 */)
            }}</yxt-radio>
            <yxt-radio :label="2" class="lh22 mt16">{{
              $t('pc_biz_enroll_lbl_check_type2' /* 直属经理代报名 */)
            }}</yxt-radio>
            <yxt-radio :label="3" class="lh22 mt16">{{
              $t('pc_biz_enroll_lbl_check_type3' /* 指定人员代报名 */)
            }}</yxt-radio>
          </yxt-radio-group>

          <settings-checked
            v-show="settings.replaceType === 3"
            class="mt16 yxtbiz-enroll-settings__audituser"
            :placeholder="
              $t(
                'pc_biz_enroll_lbl_check_type_placeholder' /* 请选择代报名人员，最多30人 */
              )
            "
            :error.sync="errorOptions.replaceEnrollError"
            :errorText="
              $t('pc_biz_enroll_msg_replaceUsersnotnull' /* 请设置代报名人员 */)
            "
            :list="settings.agentCheckTypeRange"
            @click="showDrawer('agentCheckTypeRange')"
            @delete="
              deleteChecked(
                settings.agentCheckTypeRange,
                $event,
                'agentCheckTypeRange'
              )
            "
          ></settings-checked>
        </div>
      </yxt-form-item>
      <!-- 审核设置 -->
      <yxt-form-item :label="$t('pc_biz_enroll_lbl_auditsettings')">
        <yxt-switch
          v-model="settings.checkEnabled"
          :active-value="1"
          :inactive-value="0"
          :width="28"
        ></yxt-switch>
        <span>{{ $t('pc_biz_enroll_btn_openaudit') }}</span>
        <div
          v-show="settings.checkEnabled"
          class="yxtbiz-enroll-settings__agent mt6"
          :class="{ 'pb24-impt': settings.checkType === 3 }"
        >
          <yxt-radio-group v-model="settings.checkType" :direction="'row'">
            <yxt-radio :label="1" class="lh22">{{
              $t('pc_biz_enroll_lbl_auditdepartment')
            }}</yxt-radio>
            <yxt-radio :label="2" class="lh22 mt16">{{
              $t('pc_biz_enroll_lbl_auditdirectmanager')
            }}</yxt-radio>
            <yxt-radio :label="3" class="lh22 mt16">{{
              $t('pc_biz_enroll_lbl_auditdesignatedpeop')
            }}</yxt-radio>
          </yxt-radio-group>
          <div v-show="settings.checkType === 3" class="mt16">
            <!-- <settings-checked :list="settings.checkUsers"
                              :placeholder="$t('pc_biz_enroll_tip_maxforauditpeop', [10])"
                              :error.sync="errorOptions.auditEnrollError"
                              :errorText="$t('pc_biz_enroll_msg_auditusernotnull' /* 请设置审核人员 */)"
                              @click="showDrawer('checkUsers')"
                              @delete="deleteChecked(settings.checkUsers, $event)"></settings-checked> -->
            <settings-checked
              class="yxtbiz-enroll-settings__audituser"
              :list="settings.checkUsers"
              :placeholder="$t('pc_biz_enroll_tip_maxcheckusers', [10])"
              :error.sync="errorOptions.auditEnrollError"
              :errorText="
                $t('pc_biz_enroll_msg_auditusernotnull' /* 请设置审核人员 */)
              "
              @click="showDrawer('checkUsers')"
              @delete="deleteChecked(settings.checkUsers, $event)"
            ></settings-checked>
          </div>
        </div>
      </yxt-form-item>
      <!-- 前置条件 -->
      <yxt-form-item
        v-if="fillPreconditions"
        :label="$t('pc_biz_enroll_lbl_preconditions')"
      >
        <div>
          <yxt-checkbox
            v-model="settings.positionEnabled"
            :true-label="1"
            :false-label="0"
            >{{ $t('pc_biz_enroll_lbl_position') }}</yxt-checkbox
          >
          <yxt-checkbox
            v-model="settings.gradeEnabled"
            :true-label="1"
            :false-label="0"
            >{{ $t('pc_biz_enroll_lbl_positionlevel') }}</yxt-checkbox
          >
          <yxt-checkbox
            v-if="!enrollCerShow.hidden"
            v-model="settings.cerEnabled"
            :true-label="1"
            :false-label="0"
            :disabled="showDisabled"
          >
            {{ $t('pc_biz_enroll_lbl_hadcer') }}
          </yxt-checkbox>
          <yxt-checkbox
            v-model="settings.examEnabled"
            :true-label="1"
            :false-label="0"
            >{{ $t('pc_biz_enroll_lbl_passexam') }}</yxt-checkbox
          >
          <yxt-checkbox
            v-model="settings.studyEnabled"
            :true-label="1"
            :false-label="0"
            >{{ $t('pc_biz_enroll_lbl_studyscore') }}</yxt-checkbox
          >
          <!-- 暂时隐藏 -->
          <!-- <yxt-checkbox v-model="settings.pointEnabled" :true-label="1" :false-label="0">{{ $t('pc_biz_enroll_lbl_integral') }}</yxt-checkbox> -->
          <yxt-checkbox
            v-model="settings.hireEnabled"
            :true-label="1"
            :false-label="0"
            >{{ $t('pc_biz_enroll_lbl_entrydate') }}</yxt-checkbox
          >
        </div>
        <div class="yxtbiz-enroll-settings__tip color-gray-7">
          {{ $t('pc_biz_enroll_lbl_enrollconditionstip') }}
        </div>

        <div
          v-if="showEnrollConditions"
          class="yxtbiz-enroll-settings__condition"
        >
          <div v-show="settings.positionEnabled" class="mt24">
            <span class="yxtbiz-enroll-settings__content-label">{{
              $t('pc_biz_enroll_lbl_jobscope')
            }}</span>
            <span class="yxt-form-item__required mr5"
              ><i class="required-dot"></i
            ></span>
            <settings-checked
              :list="settings.positions"
              class="w566 display-in-block"
              :placeholder="$t('pc_biz_enroll_tip_maxforauditpeop', [10])"
              :error.sync="errorOptions.positionError"
              :errorText="
                $t('pc_biz_enroll_msg_jobnotnull' /* 请选择岗位范围 */)
              "
              @click="showDrawer('positions')"
              @delete="deleteChecked(settings.positions, $event)"
            ></settings-checked>
          </div>
          <div v-show="settings.gradeEnabled" class="mt24">
            <span class="yxtbiz-enroll-settings__content-label">{{
              $t('pc_biz_enroll_lbl_positionlevel')
            }}</span>
            <span class="yxt-form-item__required mr5"
              ><i class="required-dot"></i
            ></span>
            <settings-checked
              :list="settings.grades"
              class="w566 display-in-block"
              :placeholder="$t('pc_biz_enroll_tip_maxforauditpeop', [10])"
              :error.sync="errorOptions.gradeError"
              :errorText="
                $t(
                  'pc_biz_enroll_msg_positionlevelnotnull' /* 请选择所在职级 */
                )
              "
              @click="showDrawer('grades')"
              @delete="deleteChecked(settings.grades, $event)"
            ></settings-checked>
          </div>
          <div
            v-show="settings.cerEnabled && !enrollCerShow.hidden"
            class="mt24"
          >
            <span class="yxtbiz-enroll-settings__content-label">{{
              $t('pc_biz_enroll_lbl_hadcer')
            }}</span>
            <span class="yxt-form-item__required mr5"
              ><i class="required-dot"></i
            ></span>
            <settings-checked
              :list="settings.cers"
              class="w566 display-in-block"
              :disabled="enrollCerShow.disabled"
              :placeholder="$t('pc_biz_enroll_tip_maxforauditpeop', [10])"
              :error.sync="errorOptions.cerError"
              :errorText="
                $t('pc_biz_enroll_msg_cernotnull' /* 请选择拥有证书 */)
              "
              @click="showDrawer('cers')"
              @delete="deleteChecked(settings.cers, $event)"
            ></settings-checked>
          </div>
          <div v-show="settings.examEnabled" class="mt24">
            <span class="yxtbiz-enroll-settings__content-label">{{
              $t('pc_biz_enroll_lbl_passexam')
            }}</span>
            <span class="yxt-form-item__required mr5"
              ><i class="required-dot"></i
            ></span>
            <settings-checked
              :list="settings.exams"
              class="w566 display-in-block"
              :placeholder="$t('pc_biz_enroll_tip_maxforauditpeop', [10])"
              :error.sync="errorOptions.examError"
              :errorText="
                $t('pc_biz_enroll_msg_passexamnotnull' /* 请选择通过考试 */)
              "
              @click="showDrawer('exams')"
              @delete="deleteChecked(settings.exams, $event)"
            ></settings-checked>
          </div>
          <div v-show="settings.studyEnabled" class="mt24">
            <span class="yxtbiz-enroll-settings__content-label">{{
              $t('pc_biz_enroll_lbl_studyscore')
            }}</span>
            <span class="yxt-form-item__required mr5"
              ><i class="required-dot"></i
            ></span>

            <div
              class="display-in-block"
              :class="{
                'yxtbiz-enroll-settings__inputerror': errorOptions.scoreError
              }"
            >
              <yxt-input-number
                v-model="settings.studyScoreVo.value"
                step-strictly
                class="w263"
                :class="{ 'border-error': errorOptions.scoreError }"
                controls-position="right"
                :min="0"
                :max="999999"
                @change="changeScoreNumber"
              >
              </yxt-input-number>
              <span class="yxtbiz-enroll-settings__content-separator">{{
                $t('pc_biz_enroll_lbl_and')
              }}</span>
              <yxt-select
                v-model="settings.studyScoreVo.around"
                class="w263"
                :class="{ 'border-error': errorOptions.scoreError }"
              >
                <yxt-option
                  :value="0"
                  :label="$t('pc_biz_enroll_lbl_above')"
                ></yxt-option>
                <yxt-option
                  :value="1"
                  :label="$t('pc_biz_enroll_lbl_following')"
                ></yxt-option>
              </yxt-select>
              <span v-if="errorOptions.scoreError" class="input-error-text">{{
                $t('pc_biz_ote_msg_studyscorenotnull' /* 请输入学分 */)
              }}</span>
            </div>
          </div>
          <!-- 暂时积分是隐藏的 -->
          <!-- <div v-show="settings.pointEnabled" class="mt24">
            <span class="yxtbiz-enroll-settings__content-label">{{ $t('pc_biz_enroll_lbl_integrallevel') }}</span>
            <yxt-select v-model="settings.pointLevelVo.value" class="w263">
              <yxt-option v-for="item in pointLevels"
                          :key="item.id"
                          :label="item.name"
                          :value="item.minPoint"></yxt-option>
            </yxt-select>
            <span class="yxtbiz-enroll-settings__content-separator">{{ $t('pc_biz_enroll_lbl_and') }}</span>
            <yxt-select v-model="settings.pointLevelVo.around" class="w263">
              <yxt-option :value="0" :label="$t('pc_biz_enroll_lbl_above')"></yxt-option>
              <yxt-option :value="1" :label="$t('pc_biz_enroll_lbl_following')"></yxt-option>
            </yxt-select>
          </div> -->
          <div v-show="settings.hireEnabled" class="mt24">
            <span class="yxtbiz-enroll-settings__content-label">{{
              $t('pc_biz_enroll_lbl_entrydate')
            }}</span>
            <span class="yxt-form-item__required mr5"
              ><i class="required-dot"></i
            ></span>
            <div
              class="display-in-block"
              :class="{
                'yxtbiz-enroll-settings__inputerror': errorOptions.hireError
              }"
            >
              <yxt-date-picker
                v-model="settings.hireDateVo.value"
                type="date"
                class="w263"
                :class="{ 'border-error': errorOptions.hireError }"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="changeHireTimeEvent"
                @blur="blurHireTimeEvent"
                clearable
              ></yxt-date-picker>
              <span class="yxtbiz-enroll-settings__content-separator">{{
                $t('pc_biz_enroll_lbl_and')
              }}</span>
              <yxt-select
                v-model="settings.hireDateVo.around"
                class="w263"
                :class="{ 'border-error': errorOptions.hireError }"
              >
                <yxt-option
                  :value="1"
                  :label="$t('pc_biz_enroll_lbl_before')"
                ></yxt-option>
                <yxt-option
                  :value="0"
                  :label="$t('pc_biz_enroll_lbl_after')"
                ></yxt-option>
              </yxt-select>

              <span v-if="errorOptions.hireError" class="input-error-text">{{
                $t('pc_biz_enroll_msg_jointimenotnull' /* 请设置入职日期 */)
              }}</span>
            </div>
          </div>
        </div>
      </yxt-form-item>
      <!-- 报名表单 -->
      <yxt-form-item
        v-if="
          fillRegistrationForm &&
            !enrollSurveyShow.hidden &&
            !enrollSurveyShow.disabled &&
            !settings.replaceEnabled
        "
        :label="$t('pc_biz_enroll_lbl_registrationform')"
        :label-tooltip="$t('pc_biz_enroll_lbl_registrationformforstu')"
        label-tooltip-width="200"
      >
        <yxt-switch
          v-model="settings.surveyEnabled"
          :active-value="1"
          :inactive-value="0"
          :width="28"
        ></yxt-switch>
        <span>{{ $t('pc_biz_enroll_lbl_needregistrationform') }}</span>
        <div
          v-show="settings.surveyEnabled"
          class="mt6 yxtbiz-enroll-settings__bg"
        >
          <span class="color-gray-10 yxtbiz-enroll-settings__tip">{{
            $t('pc_biz_enroll_lbl_registrationform')
          }}</span>
          <span
            class="yxt-form-item__required mr5 yxtbiz-enroll-settings__center"
            ><i class="required-dot"></i
          ></span>
          <settings-checked
            :list="settings.surveies"
            class="w566"
            :placeholder="$t('pc_biz_enroll_msg_enrollformnotnull')"
            :error.sync="errorOptions.surveyFormError"
            :errorText="
              $t('pc_biz_enroll_msg_enrollformnotnull' /* 请选择报名表单 */)
            "
            @click="showDrawer('surveies')"
            @delete="deleteChecked(settings.surveies, $event)"
          ></settings-checked>
        </div>
      </yxt-form-item>
      <!-- 放弃资格 -->
      <yxt-form-item v-if="fillGiveup" :label="$t('pc_biz_enroll_lbl_giveup')">
        <yxt-switch
          v-model="settings.abandonEnabled"
          :active-value="1"
          :inactive-value="0"
          :width="28"
        ></yxt-switch>
        <span>{{ $t('pc_biz_enroll_lbl_allowgiveup') }}</span>
        <div
          v-show="settings.abandonEnabled"
          class="mt6 yxtbiz-enroll-settings__bg"
        >
          <span class="color-gray-10 yxtbiz-enroll-settings__tip">{{
            $t('pc_biz_enroll_lbl_endtime')
          }}</span>
          <span
            class="yxt-form-item__required mr5 yxtbiz-enroll-settings__center"
            ><i class="required-dot"></i
          ></span>
          <span class="yxtbiz-enroll-settings__inputerror">
            <yxt-date-picker
              v-model="settings.abandonEndTime"
              :placeholder="$t('pc_biz_enroll_lbl_endtime')"
              class="w200"
              type="datetime"
              :class="{ 'border-error': errorOptions.abandonEndTimeError }"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              clearable
              @change="changeTimeEvent"
              @blur="blurTimeEvent"
            ></yxt-date-picker>

            <span
              v-if="errorOptions.abandonEndTimeError"
              class="input-error-text"
              >{{
                $t('pc_biz_enroll_lbl_abandontime' /* 请输入截止时间 */)
              }}</span
            >
          </span>

          <span class="ml12 color-gray-7">{{
            $t('pc_biz_enroll_lbl_intimeallowgiveup')
          }}</span>
        </div>
      </yxt-form-item>
    </yxt-form>

    <!-- 选择报名范围 -->
    <yxt-drawer
      :destroy-on-close="true"
      :visible.sync="drawers.enrollRange.show"
      size="960px"
      :header-border="false"
      :before-close="rangeBeforeClose"
    >
      <yxtbiz-check-person-range
        ref="enrollRange"
        :options="personRangeOptions"
        class="pb24"
        :tabs="['department', 'usergroup', 'person', 'import']"
        :personRange.sync="drawers.enrollRange.value"
        :functionCode="functionCode"
        :dataPermissionCode="rangePermissionCode"
        :enableGroupCorp="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-check-person-range>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('enrollRange')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button type="primary" @click="confirmDrawer('enrollRange')">{{
          $t('pc_biz_enroll_btn_confirm')
        }}</yxt-button>
      </div>
    </yxt-drawer>
    <!-- 选择排除人员 :title="$t('pc_biz_enroll_tit_selectexclusions')" -->
    <yxt-drawer
      :destroy-on-close="true"
      :visible.sync="drawers.excludeRange.show"
      size="960px"
      :before-close="rangeBeforeClose"
    >
      <yxtbiz-check-person-range
        ref="excludeRange"
        class="pb24"
        :tabs="['person', 'import']"
        :personRange.sync="drawers.excludeRange.value"
        :functionCode="functionCode"
        :dataPermissionCode="rangePermissionCode"
        :enableGroupCorp="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-check-person-range>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('excludeRange')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button type="primary" @click="confirmDrawer('excludeRange')">{{
          $t('pc_biz_enroll_btn_confirm')
        }}</yxt-button>
      </div>
    </yxt-drawer>

    <!-- 指定审核人 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectaudit')"
      :destroy-on-close="true"
      :visible.sync="drawers.checkUsers.show"
      size="960px"
      :before-close="rangeBeforeClose"
    >
      <yxtbiz-check-person-range
        ref="checkUsers"
        class="pb24"
        :tabs="['person']"
        :personRange.sync="drawers.checkUsers.value"
        :functionCode="functionCode"
        :dataPermissionCode="rangePermissionCode"
        :enableGroupCorp="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-check-person-range>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('checkUsers')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button type="primary" @click="confirmDrawer('checkUsers', 10)">{{
          $t('pc_biz_enroll_btn_confirm')
        }}</yxt-button>
      </div>
    </yxt-drawer>

    <!-- 选择岗位范围 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectjob')"
      :destroy-on-close="true"
      :visible.sync="drawers.positions.show"
      size="960px"
      :before-close="rangeBeforeClose"
    >
      <yxtbiz-check-person-range
        ref="positions"
        class="pb24"
        :tabs="['position']"
        :personRange.sync="drawers.positions.value"
        :functionCode="functionCode"
        :dataPermissionCode="rangePermissionCode"
        :enableGroupCorp="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-check-person-range>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('positions')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button type="primary" @click="confirmDrawer('positions', 10)">{{
          $t('pc_biz_enroll_btn_confirm')
        }}</yxt-button>
      </div>
    </yxt-drawer>

    <!-- 选择职级 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectpositionlevel')"
      :destroy-on-close="true"
      :visible.sync="drawers.grades.show"
      size="960px"
      :before-close="rangeBeforeClose"
    >
      <yxtbiz-check-person-range
        ref="grades"
        class="pb24"
        :tabs="['level']"
        :personRange.sync="drawers.grades.value"
        :functionCode="functionCode"
        :dataPermissionCode="rangePermissionCode"
        :enableGroupCorp="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-check-person-range>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('grades')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button type="primary" @click="confirmDrawer('grades', 10)">{{
          $t('pc_biz_enroll_btn_confirm')
        }}</yxt-button>
      </div>
    </yxt-drawer>

    <!-- 选择证书 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectcer')"
      :destroy-on-close="true"
      :visible.sync="drawers.cers.show"
      size="960px"
    >
      <yxtbiz-certificate-selector
        v-model="drawers.cers.value"
        class="pb24"
        :functionCode="functionCode"
        :dataPermissionCode="cerPermissionCode"
        :groupSelect="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-certificate-selector>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('cers')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button
          type="primary"
          @click="confirmDrawer('cers', 10, drawers.cers.value)"
          >{{ $t('pc_biz_enroll_btn_confirm') }}</yxt-button
        >
      </div>
    </yxt-drawer>

    <!-- 选择考试 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectexam')"
      :destroy-on-close="true"
      :visible.sync="drawers.exams.show"
      size="960px"
    >
      <yxtbiz-exam-arrange-selector
        v-model="drawers.exams.value"
        class="pb24"
        :functionCode="functionCode"
        :dataPermissionCode="examPermissionCode"
        :groupSelect="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-exam-arrange-selector>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('exams')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button
          type="primary"
          @click="confirmDrawer('exams', 10, drawers.exams.value)"
          >{{ $t('pc_biz_enroll_btn_confirm') }}</yxt-button
        >
      </div>
    </yxt-drawer>

    <!-- 选择报名表单 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectenroll')"
      :destroy-on-close="true"
      :visible.sync="drawers.surveies.show"
      size="960px"
    >
      <yxtbiz-survey-template
        class="pb24"
        :type="0"
        :selected="selectedSurveyId"
        :functionCode="functionCode"
        :dataPermissionCode="surveyPermissionCode"
        @select="confirmDrawer('surveies', null, $event)"
      ></yxtbiz-survey-template>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('surveies')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
      </div>
    </yxt-drawer>

    <!-- 人数设置drawer -->
    <!-- <yxt-drawer :title="$t('pc_biz_enroll_tit_user_set' /* 报名人数范围设置 */)" :destroy-on-close="true" :visible.sync="drawers.personSet.show" size="960px">
    <settings-person :list="settings.enrollRange"></settings-person>

    <div slot="footer">
      <yxt-button plain @click="drawers.personSet.show = false">{{ $t('pc_biz_enroll_btn_cancle') }}</yxt-button>
      <yxt-button type="primary" @click="confirmPersonSet">{{ $t('pc_biz_enroll_btn_confirm') }}</yxt-button>
    </div>
  </yxt-drawer> -->

    <!-- 选择代报名人员 -->
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_select_person_enroll' /* 选择人员代报名 */)"
      :destroy-on-close="true"
      :visible.sync="drawers.agentCheckTypeRange.show"
      size="960px"
      :before-close="rangeBeforeClose"
    >
      <yxtbiz-check-person-range
        ref="agentCheckTypeRange"
        class="pb24"
        :tabs="['person']"
        :personRange.sync="drawers.agentCheckTypeRange.value"
        :functionCode="functionCode"
        :dataPermissionCode="rangePermissionCode"
        :enableGroupCorp="isGroup"
        :targetOrgId="orgId"
      ></yxtbiz-check-person-range>
      <div slot="footer">
        <yxt-button plain @click="closeDrawer('agentCheckTypeRange')">{{
          $t('pc_biz_enroll_btn_cancle')
        }}</yxt-button>
        <yxt-button
          type="primary"
          @click="confirmDrawer('agentCheckTypeRange', 30)"
          >{{ $t('pc_biz_enroll_btn_confirm') }}</yxt-button
        >
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import { getMedia } from 'yxt-biz-pc/packages/api';
import SettingsChecked from './components/Checked';
import SettingsPerson from './components/PersonSet';
import { getPointLevels, getSettings, saveSettings } from './service';
import YxtbizSurveyTemplate from 'yxt-biz-pc/packages/survey-template';
import {
  Form,
  FormItem,
  Drawer,
  Tooltip,
  InputNumber,
  DatePicker,
  RadioGroup,
  Radio,
  Select,
  Option,
  Switch
} from 'yxt-pc';
import { toDate } from 'yxt-biz-pc/src/utils/date-util';
import { enmuFunctions } from '../../function-points';
import { getFunStatus } from '../../common-util/factorUtils';
import _ from 'lodash';

export default {
  name: 'YxtbizEnrollSettings',
  components: {
    YxtbizSurveyTemplate,
    SettingsChecked,
    SettingsPerson,
    YxtForm: Form,
    YxtFormItem: FormItem,
    YxtDrawer: Drawer,
    YxtTooltip: Tooltip,
    YxtInputNumber: InputNumber,
    YxtDatePicker: DatePicker,
    YxtRadioGroup: RadioGroup,
    YxtSelect: Select,
    YxtOption: Option,
    YxtSwitch: Switch,
    YxtRadio: Radio
  },
  props: {
    masterId: {
      // 来源主项目id
      type: String,
      required: true
    },
    isGroup: {
      // 集团版flag
      type: Boolean,
      default: false
    },
    createOrgId: {
      // 创建机构Id
      type: String,
      default: ''
    },
    orgId: {
      // 机构id
      type: String,
      default: ''
    },
    masterType: {
      // 来源主项目类型，1：项目设计器
      type: Number,
      default: 1
    },
    name: {
      // 报名名称
      type: String,
      required: true
    },
    projectTime: {
      // 项目时间
      type: Array,
      default: () => {
        return [];
      }
    },
    enrollId: String, // 报名Id，有报名Id为编辑，否则为创建
    functionCode: String, // 导航code
    rangePermissionCode: String, // 选人范围权限
    cerPermissionCode: String, // 选择证书权限
    examPermissionCode: String, // 选择考试权限
    surveyPermissionCode: String, // 选择调查权限
    fillActualUser: {
      // 是否有“正式名额”项
      type: Boolean,
      default: true
    },
    fillMaxUser: {
      // 是否有“允许报名数”项
      type: Boolean,
      default: true
    },
    fillPoint: {
      // 是否有“积分”项
      type: Boolean,
      default: true
    },
    enrollTimeRequired: {
      // “报名时间”是否必填
      type: Boolean,
      default: true
    },
    fillReplaceEnabled: {
      // 是否有“代报名设置”项
      type: Boolean,
      default: true
    },
    fillPreconditions: {
      // 是否有“前置条件”项
      type: Boolean,
      default: true
    },
    fillRegistrationForm: {
      // 是否有“报名表单”项
      type: Boolean,
      default: true
    },
    fillGiveup: {
      // 是否有“放弃资格”项
      type: Boolean,
      default: true
    }
  },
  data() {
    // 校验 允许报名数不能小于正式名额
    const validActualUserCount = (rule, value, callback) => {
      this.$refs.settingsForm.validateField('maxUserCount');
    };
    const validMaxUserCount = (rule, value, callback) => {
      if (
        value > 0 &&
        this.settings.actualUserCount > 0 &&
        value < this.settings.actualUserCount
      ) {
        callback(new Error(this.$t('pc_biz_enroll_msg_numallowedlimit')));
      } else {
        callback();
      }
    };
    const validateTime = (rule, value, callback) => {
      let currentTime = new Date().getTime();
      let deadlineTime = value && toDate(value[1].replace(/-/g, '/')).getTime();
      // 报名时间必填时做必填校验
      if (this.enrollTimeRequired && (!value || value.length === 0)) {
        return callback(
          new Error(this.$t('pc_biz_enroll_msg_enrolltimenotnull'))
        );
      }
      // 报名时间有值时做截止时间校验
      if (value && value.length > 0 && currentTime > deadlineTime) {
        return callback(
          new Error(this.$t('pc_biz_enroll_msg_currentLessthanDeadline'))
        );
      }

      callback();
    };
    return {
      mediaPath: getMedia() + 'common/pc_back_svg',
      pointLevels: [], // 积分等级
      settings: {
        masterId: null,
        masterType: null,
        actualUserCount: null, // 正式名额
        maxUserCount: null, // 允许报名数
        point: null, // 需要积分
        enrollRange: [], // 报名范围，非接口字段，上传到oss后地址赋到 draftRangeStr
        excludeRange: [], // 报名排除范围，非接口字段，上传到oss后地址赋到 draftRangeStr
        draftRangeStr: null, // 报名范围oss地址
        scheduleTimeEnabled: 0, // 项目开始前均可报名
        enrollTime: null, // 自定义报名时间，非接口字段，转换为 startTime, endTime
        replaceEnabled: 0, // 是否开启代报名 0 未开启 1开启
        replaceType: 1, // 代报名类型 1 部门经理代报名 2 直属经理代报名 3 指定人员代报名
        agentCheckTypeRange: [], // 代报名指定人员报名范围，非接口字段
        checkEnabled: null, // 是否开启审核，非接口字段，转换为 checkType
        checkType: 0, // 审核类型,0：免审，1：部门经理（直接领导）审核，2：直属经理审核，3：指定人员审核
        checkUsers: [], // 审核人，转换为json字符串
        positionEnabled: null, // 前置条件 所在岗位
        positions: [], // 所在岗位列表，转换为json字符串
        gradeEnabled: null, // 前置条件 所在职级
        grades: [], // 所在职级列表，转换为json字符串
        cerEnabled: null, // 前置条件 拥有证书
        cers: [], // 拥有证书列表，转换为json字符串
        examEnabled: null, // 前置条件 通过考试
        exams: [], // 通过考试列表，转换为json字符串
        studyEnabled: null, // 前置条件 学分
        studyScoreVo: {
          // 学分阀值
          value: null,
          around: 0
        },
        pointEnabled: null, // 前置条件 积分
        pointLevelVo: {
          // 积分等级阀值
          value: null,
          around: 0
        },
        hireEnabled: null, // 前置条件 入职日期
        hireDateVo: {
          // 入职日期阀值
          value: null,
          around: 1
        },
        surveyEnabled: null, // 是否需要报名表单
        surveies: [], // 报名表单列表，非接口字段，转换为 surveyTemplateId, surveyTemplateName
        abandonEnabled: null, // 是否允许放弃报名
        abandonEndTime: null // 允许放弃报名的截止时间,超过这个时间，不允许再放弃报名
      },
      initialSetting: {
        showExclude: false,
        enrollRange: [], // 报名范围，非接口字段，上传到oss后地址赋到 draftRangeUrl
        excludeRange: [], // 报名排除范围，非接口字段，上传到oss后地址赋到 draftRangeUrl
        enrollTime: null, // 自定义报名时间，非接口字段，转换为 startTime, endTime
        checkEnabled: 0, // 是否开启审核，非接口字段，转换为 checkType
        checkType: 0, // 审核类型,0：免审，1：部门经理（直接领导）审核，2：直属经理审核，3：指定人员审核
        checkUsers: [] // 审核人，转换为json字符串
      },
      rules: {
        actualUserCount: [
          {
            required: true,
            message: this.$t('pc_biz_enroll_msg_officialquotanotnull'),
            trigger: ['blur', 'change']
          },
          { validator: validActualUserCount, trigger: ['blur', 'change'] }
        ],
        maxUserCount: [
          {
            required: true,
            message: this.$t('pc_biz_enroll_msg_numallowednotnull'),
            trigger: ['blur', 'change']
          },
          { validator: validMaxUserCount, trigger: ['blur', 'change'] }
        ],
        enrollTime: [
          {
            type: 'array',
            required: true,
            validator: validateTime.bind(this),
            trigger: ['change', 'blur']
          }
        ],
        enrollRange: [{ required: true }],
        excludeRange: [{ required: true }]
      },
      showExclude: false, // 显示报名排除范围
      enrollRangeChanged: false, // 报名范围是否改变
      drawers: {
        enrollRange: {
          show: false,
          value: []
        },
        excludeRange: {
          show: false,
          value: []
        },
        checkUsers: {
          show: false,
          value: []
        },
        positions: {
          show: false,
          value: []
        },
        grades: {
          show: false,
          value: []
        },
        cers: {
          show: false,
          value: []
        },
        exams: {
          show: false,
          value: []
        },
        surveies: {
          show: false
        },

        personSet: {
          show: false,
          value: []
        },

        agentCheckTypeRange: {
          show: false,
          value: []
        }
      },
      personRangeOptions: {
        department: {
          childrenIncluded: true
        }
      },

      errorOptions: {
        enrollRangeError: false, // 报名范围
        enrollExcludeRange: false, // 排除人员
        replaceEnrollError: false, // 代报名未设置人员
        auditEnrollError: false, // 审核未设置人员
        positionError: false, // 未选择岗位
        gradeError: false, // 未选择职级
        cerError: false, // 未选择证书
        examError: false, // 未选择考试
        scoreError: false, // 未输入学分
        hireError: false, // 入职日期
        surveyFormError: false, // 报名表单
        abandonEndTimeError: false // 放弃资格
      }
    };
  },
  computed: {
    selectedSurveyId() {
      return this.settings.surveies && this.settings.surveies.length > 0
        ? this.settings.surveies[0].id
        : null;
    },

    // 展示人数设置
    showPersonSet() {
      return this.settings.enrollRange.filter(item => {
        return item['_type'] === 0 || item['_type'] === 2;
      }).length;
    },

    showDisabled() {
      return this.settings.cerEnabled ? false : this.enrollCerShow.disabled;
    },

    enrollSurveyShow() {
      return getFunStatus(enmuFunctions.PROJECT_SIGNUP);
    },

    enrollCerShow() {
      return getFunStatus(enmuFunctions.PROJECT_CERTIFICATE_CONDITION);
    },

    showEnrollConditions() {
      return (
        this.settings.positionEnabled ||
        this.settings.gradeEnabled ||
        (this.settings.cerEnabled && !this.enrollCerShow.hidden) ||
        this.settings.examEnabled ||
        this.settings.studyEnabled ||
        this.settings.hireEnabled
      );
    }
  },
  created() {
    this.rules.enrollTime[0].required = this.enrollTimeRequired;

    if (!Object.setPrototypeOf) {
      this.addedSetPrototypeOf = true;
      Object.setPrototypeOf = function(obj, proto) {
        obj.__proto__ = proto;
        return obj;
      };
    }

    if (this.enrollId) {
      this.getSettings(this.enrollId);
    } else {
      this.settings.masterId = this.masterId;
      this.settings.masterType = this.masterType;
      if (this.projectTime && this.projectTime.length === 2) {
        this.settings.enrollTime = [...this.projectTime];
        this.initialSetting.enrollTime = [...this.projectTime];
      }
    }
    getPointLevels().then(res => {
      // 获取积分等级数据
      if (res.datas && res.datas.length > 0) {
        this.pointLevels = res.datas;
      }
    });
  },
  beforeDestroy() {
    if (this.addedSetPrototypeOf) {
      Object.setPrototypeOf = null;
    }
  },
  watch: {
    'settings.replaceEnabled': {
      handler(val) {
        // 开启代报名的时候 隐藏开启填写表单 重置表单数据
        this.settings.surveyEnabled = 0;
        this.settings.surveies = [];

        if (!val && this.settings.replaceType === 3) {
          this.errorOptions.replaceEnrollError = false;
        }
      }
    },
    'settings.checkEnabled': {
      handler(val) {
        // 审核设置关闭，审核设置为免审核
        if (!val) {
          this.settings.checkType = 0;
        }
        // 如果审核设置开启了 & 审核是免审核时打开审核设置，默认到部门经理审核
        if (val && this.settings.checkType === 0) {
          this.settings.checkType = 1;
        }

        if (!val && this.settings.checkType === 3) {
          this.errorOptions.auditEnrollError = false;
        }
      }
    },

    'settings.positionEnabled'(val) {
      if (!val) {
        this.errorOptions.positionError = false;
      }
    },
    'settings.gradeEnabled'(val) {
      if (!val) {
        this.errorOptions.gradeError = false;
      }
    },
    'settings.cerEnabled'(val) {
      if (!val) {
        this.errorOptions.cerError = false;
      }
    },
    'settings.examEnabled'(val) {
      if (!val) {
        this.errorOptions.examError = false;
      }
    },
    'settings.studyEnabled'(val) {
      if (!val) {
        this.errorOptions.scoreError = false;
      }
    },
    'settings.hireEnabled'(val) {
      if (!val) {
        this.errorOptions.hireError = false;
      }
    },
    'settings.surveyEnabled'(val) {
      if (!val) {
        this.errorOptions.surveyFormError = false;
      }
    },
    'settings.abandonEnabled'(val) {
      if (!val) {
        this.errorOptions.abandonEndTimeError = false;
      }
    }
  },
  methods: {
    getSettings(enrollId) {
      getSettings(enrollId)
        .then(res => {
          if (res && res.id) {
            // enrollRange: [], // 报名范围，非接口字段，上传到oss后地址赋到 draftRangeStr
            // excludeRange: [], // 报名排除范围，非接口字段，上传到oss后地址赋到 draftRangeStr
            // enrollTime: null, // 自定义报名时间，非接口字段，转换为 startTime, endTime
            // checkEnabled: null, // 是否开启审核，非接口字段，转换为 checkType
            // surveies: [] // 报名表单列表，非接口字段，转换为 surveyTemplateId, surveyTemplateName
            for (const key in this.settings) {
              if (res.hasOwnProperty(key) && res[key] !== null) {
                this.settings[key] = res[key];
              }
            }
            this.initialSetting.checkType = res.checkType;
            this.initialSetting.checkUsers = _.cloneDeep(res.checkUsers);
            if (
              this.settings.pointLevelVo &&
              this.settings.pointLevelVo.value
            ) {
              this.settings.pointLevelVo.value = ~~this.settings.pointLevelVo
                .value;
            }
            if (res.draftRangeStr) {
              const rangeRes = JSON.parse(res.draftRangeStr);
              if (rangeRes) {
                const enrollRange = [];
                const excludeRange = [];
                for (const r of rangeRes.rangeList) {
                  enrollRange.push({
                    id: r.objId,
                    name: r.objName,
                    _type: r.objType,
                    includeAll: r.objLevel,
                    userOrgId: r.userOrgId
                  });
                }
                if (rangeRes.rangeExcludeList) {
                  for (const e of rangeRes.rangeExcludeList) {
                    excludeRange.push({
                      id: e.objId,
                      name: e.objName,
                      _type: e.objType,
                      userOrgId: e.userOrgId
                    });
                  }
                }
                this.settings.enrollRange = enrollRange;
                this.settings.excludeRange = excludeRange;
                this.showExclude = !!this.settings.excludeRange.length;
                this.initialSetting.enrollRange = _.cloneDeep(enrollRange);
                this.initialSetting.excludeRange = _.cloneDeep(excludeRange);
                this.initialSetting.showExclude = !!this.settings.excludeRange
                  .length;
              }
            }
            if (res.startTime && res.endTime) {
              this.settings.enrollTime = [res.startTime, res.endTime];
              this.initialSetting.enrollTime = [res.startTime, res.endTime];
            }
            if (res.checkType !== 0) {
              this.settings.checkEnabled = 1;
              this.initialSetting.checkEnabled = 1;
            } else {
              this.initialSetting.checkEnabled = 0;
            }
            if (res.surveyTemplateId && res.surveyTemplateName) {
              this.settings.surveies = [
                {
                  id: res.surveyTemplateId,
                  name: res.surveyTemplateName
                }
              ];
            }
            if (
              res.replaceUsers.length &&
              res.replaceType === 3 &&
              res.replaceEnabled
            ) {
              this.settings.agentCheckTypeRange = res.replaceUsers;
            }
          }
        })
        .catch(() => {
          this.$message.error(this.$t('pc_biz_enroll_msg_getenrolldataerror'));
        });
    },
    showDrawer(name) {
      // 打开抽屉
      const target = this.drawers[name];
      if (target) {
        if (target.hasOwnProperty('value')) {
          target.value = this.settings[name] ? [...this.settings[name]] : [];
        }
        target.show = true;
      }
    },
    confirmDrawer(name, count, value) {
      // 确认抽屉选择
      if (name === 'enrollRange' || name === 'excludeRange') {
        this.enrollRangeChanged = true;
      }
      const target = this.drawers[name];
      if (target) {
        if (name === 'surveies' && !value) {
          // 调查模板取消选择
          value = [];
        }
        if (target.hasOwnProperty('value')) {
          value = target.value;
        }
        const temp =
          value instanceof Array
            ? count > 0
              ? value.splice(0, count)
              : value
            : [value];
        if (count > 0 && value.length > 0) {
          // 超过count，只取前面几个
          this.$message.warning(
            this.$t('pc_biz_enroll_msg_maxselectnum', [count])
          );
        }
        const result = [];
        console.log(temp, 55);
        for (const item of temp) {
          const v = {
            id: item.id,
            name: item.name || item.fullname,
            userOrgId: item.userOrgId || item.orgId || localStorage.orgId
          };
          if (item.hasOwnProperty('_type')) {
            v._type = item._type;
          }
          if (item.hasOwnProperty('includeAll')) {
            v.includeAll = item.includeAll;
          }
          if (item.hasOwnProperty('userCount')) {
            v.userCount = item.userCount || 0;
          }
          if (item.hasOwnProperty('orgId') || item.hasOwnProperty('userOrgId')) {
            v.userOrgId = item.userOrgId || item.orgId || localStorage.orgId;
          }
          result.push(v);
        }
        console.log(result, 555);
        this.settings[name] = result;
      }
      this.closeDrawer(name);
    },
    closeDrawer(name) {
      // 关闭抽屉
      const target = this.drawers[name];
      target && (target.show = false);
    },
    deleteChecked(checked, { tag, index }, name) {
      // 删除选中
      if (name === 'enrollRange' || name === 'excludeRange') {
        this.enrollRangeChanged = true;
      }
      checked.splice(index, 1);
    },

    isEmpty(val) {
      return val === undefined || val === null || val === '';
    },

    conditionReturn(condition, optionRange, allValid = []) {
      if (condition) {
        allValid.push(false);

        if (optionRange) {
          this.errorOptions[optionRange] = true;
        }
      } else {
        this.errorOptions[optionRange] = false;
      }
    },

    validateForm() {
      // 数据校验
      const datas = this.settings;
      let allValid = [];
      if (this.fillActualUser) {
        this.conditionReturn(!datas.actualUserCount, false, allValid);
      }
      if (this.fillMaxUser) {
        this.conditionReturn(!datas.maxUserCount, false, allValid);
      }
      if (this.fillActualUser && this.fillMaxUser) {
        this.conditionReturn(
          datas.maxUserCount < datas.actualUserCount,
          false,
          allValid
        );
      }
      this.conditionReturn(
        !datas.enrollRange || datas.enrollRange.length === 0,
        'enrollRangeError',
        allValid
      );

      if (this.enrollTimeRequired && !datas.enrollTime) {
        allValid.push(false);
      } else {
        if (datas.enrollTime && datas.enrollTime.length > 0) {
          let currentTime = new Date().getTime();
          let deadlineTime = toDate(
            datas.enrollTime[1].replace(/-/g, '/')
          ).getTime();
          if (
            datas.enrollTime[0] === datas.enrollTime[1] ||
            currentTime > deadlineTime
          ) {
            allValid.push(false);
          }
        } else if (this.enrollTimeRequired && datas.enrollTime.length === 0) {
          allValid.push(false);
        }
      }
      this.$refs.settingsForm.validateField('enrollTime');

      this.conditionReturn(
        this.showExclude && datas.excludeRange.length === 0,
        'enrollExcludeRange',
        allValid
      );
      this.conditionReturn(
        datas.replaceEnabled &&
          datas.replaceType === 3 &&
          datas.agentCheckTypeRange.length === 0,
        'replaceEnrollError',
        allValid
      );
      this.conditionReturn(
        datas.checkEnabled &&
          datas.checkType === 3 &&
          (!datas.checkUsers || datas.checkUsers.length === 0),
        'auditEnrollError',
        allValid
      );
      this.conditionReturn(
        datas.positionEnabled &&
          (!datas.positions || datas.positions.length === 0),
        'positionError',
        allValid
      );
      this.conditionReturn(
        datas.gradeEnabled && (!datas.grades || datas.grades.length === 0),
        'gradeError',
        allValid
      );
      // 证书的功能点 （未被隐藏）
      if (!this.enrollCerShow.hidden) {
        this.conditionReturn(
          datas.cerEnabled && (!datas.cers || datas.cers.length === 0),
          'cerError',
          allValid
        );
      }
      this.conditionReturn(
        datas.examEnabled && (!datas.exams || datas.exams.length === 0),
        'examError',
        allValid
      );
      this.conditionReturn(
        datas.studyEnabled &&
          datas.studyScoreVo &&
          this.isEmpty(datas.studyScoreVo.value),
        'scoreError',
        allValid
      );
      this.conditionReturn(
        datas.hireEnabled && !datas.hireDateVo.value,
        'hireError',
        allValid
      );

      // 报名表单-调研的功能点（未被隐藏 && 未被置灰）
      if (!this.enrollSurveyShow.hidden && !this.enrollSurveyShow.disabled) {
        this.conditionReturn(
          datas.surveyEnabled &&
            (!datas.surveies || datas.surveies.length === 0),
          'surveyFormError',
          allValid
        );
      }
      // allValid是否数组里全部都是true
      const someValid = allValid.some(item => {
        return !item;
      });

      if (someValid) {
        this.$message.error(
          this.$t('pc_biz_enroll_msg_inputrequire' /* 请检查必填项 */)
        );
      }

      return !someValid;
    },
    async uploadEnrollRange(enrollRange, excludeRange) {
      // 上传报名范围到oss
      // const ossConfig = await getSts();
      // if (!ossConfig || !ossConfig.securityToken) {
      //   throw new Error('getSts error!');
      // }

      const ossData = {
        // oss上传数据
        rangeList: [],
        rangeExcludeList: []
      };
      for (const r of this.settings.enrollRange) {
        ossData.rangeList.push({
          objId: r.id,
          objName: r.name,
          objType: r._type,
          objLevel: r.includeAll,
          userOrgId: r.userOrgId
        });
      }
      for (const e of this.settings.excludeRange) {
        ossData.rangeExcludeList.push({
          objId: e.id,
          objName: e.name,
          objType: e._type,
          userOrgId: e.userOrgId
        });
      }

      return JSON.stringify(ossData);

      // try {
      //   const fileName = `${ossConfig.uploadPath}/c${this.masterType}-${this.masterId}/${new Date().getTime()}-${~~(Math.random() * 1000000)}.json`; // 定义唯一的文件名
      //   // oss客户端
      //   const OSS = require('ali-oss');
      //   const ossClient = new OSS({
      //     region: ossConfig.region,
      //     secure: window.location.protocol === 'https:',
      //     accessKeyId: ossConfig.accessKeyId,
      //     accessKeySecret: ossConfig.accessKeySecret,
      //     stsToken: ossConfig.securityToken,
      //     bucket: ossConfig.bucket
      //   });
      //   const result = await ossClient.put(fileName, new OSS.Buffer(JSON.stringify(ossData)));
      //   return ossConfig.prefix + result.name;
      // } catch (ex) {
      //   throw ex;
      // }
    },

    save() {
      // 保存报名设置
      return new window.Promise(async(resolve, reject) => {
        if (!this.validateForm()) {
          // 数据校验失败
          reject(new Error('validate failed!!'));
          return false;
        }

        // enrollRange: [], // 报名范围，非接口字段，上传到oss后地址赋到 draftRangeStr
        // excludeRange: [], // 报名排除范围，非接口字段，上传到oss后地址赋到 draftRangeStr
        // enrollTime: null, // 自定义报名时间，非接口字段，转换为 startTime, endTime
        // checkEnabled: null, // 是否开启审核，非接口字段，转换为 checkType
        // surveies: [] // 报名表单列表，非接口字段，转换为 surveyTemplateId, surveyTemplateName
        // agentCheckTypeRange
        const {
          enrollRange,
          excludeRange,
          enrollTime,
          checkEnabled,
          surveies,
          agentCheckTypeRange,
          ...saveData
        } = Object.assign({}, this.settings);
        saveData.id = this.enrollId;
        saveData.name = this.name;

        // if (this.enrollRangeChanged) {
        //   try {
        //     saveData.draftRangeStr = this.settings.draftRangeStr = await this.uploadEnrollRange(enrollRange, excludeRange);
        //     this.enrollRangeChanged = false;
        //   } catch (ex) {
        //     reject(new Error('save enrollRange error!'));
        //     return false;
        //   }
        // }
        saveData.draftRangeStr = this.settings.draftRangeStr = await this.uploadEnrollRange(
          enrollRange,
          excludeRange
        );
        saveData.scheduleTimeEnabled = 0; // 需求改动：报名时间自定义设置

        // 如果代报名开启&&报名方式为指定人员
        if (saveData.replaceEnabled) {
          saveData.replaceUsers =
            saveData.replaceType === 3
              ? agentCheckTypeRange.map(ele => {
                return {
                  name: ele.name,
                  id: ele.id,
                  userOrgId: ele.userOrgId
                };
              })
              : [];
        }
        if (enrollTime && enrollTime.length === 2) {
          saveData.startTime = enrollTime[0];
          saveData.endTime = enrollTime[1];
        }
        if (!checkEnabled) {
          saveData.checkType = 0;
        }
        if (surveies && surveies.length > 0) {
          saveData.surveyTemplateId = surveies[0].id;
          saveData.surveyTemplateName = surveies[0].name;
        }
        saveData.point = saveData.point || 0;
        this.isGroup && (saveData.groupFlag = 1);
        this.createOrgId && (saveData.createOrgId = this.createOrgId);
        saveSettings(saveData)
          .then(res => {
            this.getSettings(this.enrollId || res.id);
            resolve(this.enrollId || res.id);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // 活动中心校验数据是否有变更
    checkUpdate() {
      let {
        enrollRange,
        excludeRange,
        enrollTime,
        checkEnabled,
        checkType,
        checkUsers
      } = Object.assign({}, this.settings);
      if (!checkEnabled) {
        checkType = 0;
      }
      const currentSetting = {
        enrollRange, // 报名范围
        excludeRange, // 报名排除范围
        enrollTime: enrollTime || [], // 自定义报名时间
        checkEnabled, // 是否开启审核，非接口字段，转换为 checkType
        checkType, // 审核类型,0：免审，1：部门经理（直接领导）审核，2：直属经理审核，3：指定人员审核
        checkUsers, // 审核人
        showExclude: this.showExclude // 是否打开排除人员
      };
      this.initialSetting.enrollTime = this.initialSetting.enrollTime || [];
      let hasUpdate = false;
      for (let p in currentSetting) {
        if (['enrollRange', 'excludeRange', 'checkUsers'].includes(p)) {
          if (
            currentSetting[p].length !== this.initialSetting[p].length ||
            _.differenceWith(
              currentSetting[p],
              this.initialSetting[p],
              function(currentVal, initialVal) {
                return currentVal.id === initialVal.id;
              }
            ).length > 0
          ) {
            hasUpdate = true;
            break;
          }
        } else if (['enrollTime'].includes(p)) {
          if (
            currentSetting[p].length !== this.initialSetting[p].length ||
            _.difference(currentSetting[p], this.initialSetting[p]).length > 0
          ) {
            hasUpdate = true;
            break;
          }
        } else {
          if (currentSetting[p] !== this.initialSetting[p]) {
            hasUpdate = true;
            break;
          }
        }
      }
      return hasUpdate;
    },

    rangeBeforeClose(done) {
      let refName = '';
      if (this.drawers.enrollRange.show) {
        refName = 'enrollRange';
      }
      if (this.drawers.excludeRange.show) {
        refName = 'excludeRange';
      }
      if (this.drawers.checkUsers.show) {
        refName = 'checkUsers';
      }
      if (this.drawers.positions.show) {
        refName = 'positions';
      }
      if (this.drawers.grades.show) {
        refName = 'grades';
      }
      if (this.drawers.agentCheckTypeRange.show) {
        refName = 'agentCheckTypeRange';
      }

      done(this.$refs[refName].showTip);
    },

    changeExclude(v) {
      !v &&
        this.settings.excludeRange.length &&
        this.showWarningExcludeDialog();
    },

    // 取消排除人员的dialog
    showWarningExcludeDialog() {
      this.$confirm(
        this.$t(
          'pc_biz_enroll_msg_exclude_user_text' /* 取消勾选“添加排除人员”选项，已添加的人员将会一起清除 */
        ),
        this.$t(
          'pc_biz_enroll_tit_exclude_user_dialog' /* 确认取消勾选此选项吗 */
        ),
        {
          confirmButtonText: this.$t('pc_biz_enroll_btn_confirm' /* 确定 */),
          cancelButtonText: this.$t('pc_biz_enroll_btn_cancle' /* 取消 */),
          type: 'warning'
        }
      )
        .then(() => {
          this.showExclude = false;
          this.settings.excludeRange = [];
        })
        .catch(() => {
          this.showExclude = true;
        });
    },

    // 展示人数设置的drawer
    showPersonSetDrawer() {
      this.drawers.personSet.show = true;
    },
    // 确认报名人数范围设置
    // confirmPersonSet() {
    //   console.log(this.settings.enrollRange);
    // }

    changeTimeEvent(val) {
      this.errorOptions.abandonEndTimeError = Boolean(!val);
    },

    blurTimeEvent() {
      this.errorOptions.abandonEndTimeError = !this.settings.abandonEndTime;
    },

    changeHireTimeEvent(val) {
      this.errorOptions.hireError = Boolean(!val);
    },

    blurHireTimeEvent() {
      this.errorOptions.hireError =
        this.settings.hireEnabled && !this.settings.hireDateVo.value;
    },

    changeScoreNumber(score) {
      this.errorOptions.scoreError = this.isEmpty(score);
    }
  }
};
</script>
