var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-enroll-settings color-gray-9" },
    [
      _c(
        "yxt-form",
        {
          ref: "settingsForm",
          attrs: {
            model: _vm.settings,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "right",
          },
        },
        [
          _vm.fillActualUser
            ? _c(
                "yxt-form-item",
                {
                  attrs: {
                    label: _vm.$t("pc_biz_enroll_lbl_officialquota"),
                    prop: "actualUserCount",
                  },
                },
                [
                  _c("yxt-input-number", {
                    staticClass: "w128",
                    attrs: {
                      "controls-position": "right",
                      "step-strictly": "",
                      min: 1,
                      max: 999999999,
                    },
                    model: {
                      value: _vm.settings.actualUserCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "actualUserCount", $$v)
                      },
                      expression: "settings.actualUserCount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.fillMaxUser
            ? _c(
                "yxt-form-item",
                {
                  attrs: {
                    label: _vm.$t("pc_biz_enroll_lbl_numallowed"),
                    prop: "maxUserCount",
                  },
                },
                [
                  _c("yxt-input-number", {
                    staticClass: "w128",
                    attrs: {
                      "controls-position": "right",
                      "step-strictly": "",
                      min: 1,
                      max: 999999999,
                    },
                    model: {
                      value: _vm.settings.maxUserCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "maxUserCount", $$v)
                      },
                      expression: "settings.maxUserCount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.fillPoint
            ? _c(
                "yxt-form-item",
                {
                  attrs: {
                    label: _vm.$t("pc_biz_enroll_lbl_integral"),
                    prop: "point",
                    "label-tooltip": _vm.$t("pc_biz_enroll_tip_zeroforfree"),
                    "label-tooltip-width": "200",
                  },
                },
                [
                  _c("yxt-input-number", {
                    staticClass: "w128",
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      max: 99999,
                      "step-strictly": "",
                      placeholder: _vm.$t("pc_biz_enroll_tip_zeroforfree"),
                    },
                    model: {
                      value: _vm.settings.point,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "point", $$v)
                      },
                      expression: "settings.point",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "yxt-form-item",
            {
              attrs: {
                label: _vm.$t("pc_biz_enroll_lbl_enrollrange"),
                prop: "enrollRange",
              },
            },
            [
              _c("settings-checked", {
                staticClass: "w700",
                attrs: {
                  list: _vm.settings.enrollRange,
                  placeholder: _vm.$t(
                    "pc_biz_enroll_lbl_errollrange_placeholder" /*请选择报名范围*/
                  ),
                  error: _vm.errorOptions.enrollRangeError,
                  errorText: _vm.$t(
                    "pc_biz_enroll_msg_allfollowup" /* 报名范围不能为空 */
                  ),
                },
                on: {
                  "update:error": function ($event) {
                    return _vm.$set(
                      _vm.errorOptions,
                      "enrollRangeError",
                      $event
                    )
                  },
                  click: function ($event) {
                    return _vm.showDrawer("enrollRange")
                  },
                  delete: function ($event) {
                    return _vm.deleteChecked(
                      _vm.settings.enrollRange,
                      $event,
                      "enrollRange"
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c("yxt-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "lh22" },
              [
                _c(
                  "yxt-checkbox",
                  {
                    on: { change: _vm.changeExclude },
                    model: {
                      value: _vm.showExclude,
                      callback: function ($$v) {
                        _vm.showExclude = $$v
                      },
                      expression: "showExclude",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_addexclusions")))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "yxt-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showExclude,
                  expression: "showExclude",
                },
              ],
              attrs: {
                label: _vm.$t("pc_biz_enroll_lbl_exclude_user" /* 排除人员 */),
                prop: "excludeRange",
              },
            },
            [
              _c("settings-checked", {
                staticClass: "w700",
                attrs: {
                  placeholder: _vm.$t(
                    "pc_biz_enroll_lbl_exclude_user_placeholder" /*请选择排除人员*/
                  ),
                  list: _vm.settings.excludeRange,
                  error: _vm.errorOptions.enrollExcludeRange,
                  errorText: _vm.$t(
                    "pc_biz_enroll_lbl_exclude_user_placeholder" /* 报名范围不能为空 */
                  ),
                },
                on: {
                  "update:error": function ($event) {
                    return _vm.$set(
                      _vm.errorOptions,
                      "enrollExcludeRange",
                      $event
                    )
                  },
                  click: function ($event) {
                    return _vm.showDrawer("excludeRange")
                  },
                  delete: function ($event) {
                    return _vm.deleteChecked(
                      _vm.settings.excludeRange,
                      $event,
                      "excludeRange"
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "yxt-form-item",
            {
              attrs: {
                label: _vm.$t("pc_biz_enroll_lbl_enrolltime"),
                prop: "enrollTime",
              },
            },
            [
              _c(
                "div",
                [
                  _c("yxt-date-picker", {
                    staticClass: "w424",
                    attrs: {
                      type: "datetimerange",
                      "range-separator": _vm.$t("pc_biz_enroll_to"),
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                    },
                    model: {
                      value: _vm.settings.enrollTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "enrollTime", $$v)
                      },
                      expression: "settings.enrollTime",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.fillReplaceEnabled
            ? _c(
                "yxt-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "pc_biz_enroll_lbl_open_agent" /* 代报名设置 */
                    ),
                    prop: "replaceEnabled",
                    "label-tooltip": _vm.$t("pc_biz_enroll_tip_replaceEnroll"),
                    "label-tooltip-width": "200",
                  },
                },
                [
                  _c("yxt-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      width: 28,
                    },
                    model: {
                      value: _vm.settings.replaceEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "replaceEnabled", $$v)
                      },
                      expression: "settings.replaceEnabled",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "pc_biz_enroll_lbl_open_agent_set" /* 开启代报名 */
                        )
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.settings.replaceEnabled,
                          expression: "settings.replaceEnabled",
                        },
                      ],
                      staticClass: "yxtbiz-enroll-settings__agent mt6",
                      class: { "pb24-impt": _vm.settings.replaceType === 3 },
                    },
                    [
                      _c(
                        "yxt-radio-group",
                        {
                          attrs: { direction: "row" },
                          model: {
                            value: _vm.settings.replaceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "replaceType", $$v)
                            },
                            expression: "settings.replaceType",
                          },
                        },
                        [
                          _c(
                            "yxt-radio",
                            { staticClass: "lh22", attrs: { label: 1 } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_enroll_lbl_check_type1" /* 部门经理代报名 */
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "yxt-radio",
                            { staticClass: "lh22 mt16", attrs: { label: 2 } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_enroll_lbl_check_type2" /* 直属经理代报名 */
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "yxt-radio",
                            { staticClass: "lh22 mt16", attrs: { label: 3 } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_enroll_lbl_check_type3" /* 指定人员代报名 */
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("settings-checked", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.settings.replaceType === 3,
                            expression: "settings.replaceType === 3",
                          },
                        ],
                        staticClass: "mt16 yxtbiz-enroll-settings__audituser",
                        attrs: {
                          placeholder: _vm.$t(
                            "pc_biz_enroll_lbl_check_type_placeholder" /* 请选择代报名人员，最多30人 */
                          ),
                          error: _vm.errorOptions.replaceEnrollError,
                          errorText: _vm.$t(
                            "pc_biz_enroll_msg_replaceUsersnotnull" /* 请设置代报名人员 */
                          ),
                          list: _vm.settings.agentCheckTypeRange,
                        },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(
                              _vm.errorOptions,
                              "replaceEnrollError",
                              $event
                            )
                          },
                          click: function ($event) {
                            return _vm.showDrawer("agentCheckTypeRange")
                          },
                          delete: function ($event) {
                            return _vm.deleteChecked(
                              _vm.settings.agentCheckTypeRange,
                              $event,
                              "agentCheckTypeRange"
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "yxt-form-item",
            { attrs: { label: _vm.$t("pc_biz_enroll_lbl_auditsettings") } },
            [
              _c("yxt-switch", {
                attrs: { "active-value": 1, "inactive-value": 0, width: 28 },
                model: {
                  value: _vm.settings.checkEnabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "checkEnabled", $$v)
                  },
                  expression: "settings.checkEnabled",
                },
              }),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_openaudit"))),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.settings.checkEnabled,
                      expression: "settings.checkEnabled",
                    },
                  ],
                  staticClass: "yxtbiz-enroll-settings__agent mt6",
                  class: { "pb24-impt": _vm.settings.checkType === 3 },
                },
                [
                  _c(
                    "yxt-radio-group",
                    {
                      attrs: { direction: "row" },
                      model: {
                        value: _vm.settings.checkType,
                        callback: function ($$v) {
                          _vm.$set(_vm.settings, "checkType", $$v)
                        },
                        expression: "settings.checkType",
                      },
                    },
                    [
                      _c(
                        "yxt-radio",
                        { staticClass: "lh22", attrs: { label: 1 } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_enroll_lbl_auditdepartment"))
                          ),
                        ]
                      ),
                      _c(
                        "yxt-radio",
                        { staticClass: "lh22 mt16", attrs: { label: 2 } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pc_biz_enroll_lbl_auditdirectmanager")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "yxt-radio",
                        { staticClass: "lh22 mt16", attrs: { label: 3 } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pc_biz_enroll_lbl_auditdesignatedpeop")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.settings.checkType === 3,
                          expression: "settings.checkType === 3",
                        },
                      ],
                      staticClass: "mt16",
                    },
                    [
                      _c("settings-checked", {
                        staticClass: "yxtbiz-enroll-settings__audituser",
                        attrs: {
                          list: _vm.settings.checkUsers,
                          placeholder: _vm.$t(
                            "pc_biz_enroll_tip_maxcheckusers",
                            [10]
                          ),
                          error: _vm.errorOptions.auditEnrollError,
                          errorText: _vm.$t(
                            "pc_biz_enroll_msg_auditusernotnull" /* 请设置审核人员 */
                          ),
                        },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(
                              _vm.errorOptions,
                              "auditEnrollError",
                              $event
                            )
                          },
                          click: function ($event) {
                            return _vm.showDrawer("checkUsers")
                          },
                          delete: function ($event) {
                            return _vm.deleteChecked(
                              _vm.settings.checkUsers,
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.fillPreconditions
            ? _c(
                "yxt-form-item",
                { attrs: { label: _vm.$t("pc_biz_enroll_lbl_preconditions") } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "yxt-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.settings.positionEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "positionEnabled", $$v)
                            },
                            expression: "settings.positionEnabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_position")))]
                      ),
                      _c(
                        "yxt-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.settings.gradeEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "gradeEnabled", $$v)
                            },
                            expression: "settings.gradeEnabled",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_enroll_lbl_positionlevel"))
                          ),
                        ]
                      ),
                      !_vm.enrollCerShow.hidden
                        ? _c(
                            "yxt-checkbox",
                            {
                              attrs: {
                                "true-label": 1,
                                "false-label": 0,
                                disabled: _vm.showDisabled,
                              },
                              model: {
                                value: _vm.settings.cerEnabled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.settings, "cerEnabled", $$v)
                                },
                                expression: "settings.cerEnabled",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("pc_biz_enroll_lbl_hadcer")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "yxt-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.settings.examEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "examEnabled", $$v)
                            },
                            expression: "settings.examEnabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_passexam")))]
                      ),
                      _c(
                        "yxt-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.settings.studyEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "studyEnabled", $$v)
                            },
                            expression: "settings.studyEnabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_studyscore")))]
                      ),
                      _c(
                        "yxt-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.settings.hireEnabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "hireEnabled", $$v)
                            },
                            expression: "settings.hireEnabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_entrydate")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "yxtbiz-enroll-settings__tip color-gray-7" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("pc_biz_enroll_lbl_enrollconditionstip")
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm.showEnrollConditions
                    ? _c(
                        "div",
                        { staticClass: "yxtbiz-enroll-settings__condition" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.settings.positionEnabled,
                                  expression: "settings.positionEnabled",
                                },
                              ],
                              staticClass: "mt24",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "yxtbiz-enroll-settings__content-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("pc_biz_enroll_lbl_jobscope"))
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "yxt-form-item__required mr5" },
                                [_c("i", { staticClass: "required-dot" })]
                              ),
                              _c("settings-checked", {
                                staticClass: "w566 display-in-block",
                                attrs: {
                                  list: _vm.settings.positions,
                                  placeholder: _vm.$t(
                                    "pc_biz_enroll_tip_maxforauditpeop",
                                    [10]
                                  ),
                                  error: _vm.errorOptions.positionError,
                                  errorText: _vm.$t(
                                    "pc_biz_enroll_msg_jobnotnull" /* 请选择岗位范围 */
                                  ),
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(
                                      _vm.errorOptions,
                                      "positionError",
                                      $event
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.showDrawer("positions")
                                  },
                                  delete: function ($event) {
                                    return _vm.deleteChecked(
                                      _vm.settings.positions,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.settings.gradeEnabled,
                                  expression: "settings.gradeEnabled",
                                },
                              ],
                              staticClass: "mt24",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "yxtbiz-enroll-settings__content-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pc_biz_enroll_lbl_positionlevel")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "yxt-form-item__required mr5" },
                                [_c("i", { staticClass: "required-dot" })]
                              ),
                              _c("settings-checked", {
                                staticClass: "w566 display-in-block",
                                attrs: {
                                  list: _vm.settings.grades,
                                  placeholder: _vm.$t(
                                    "pc_biz_enroll_tip_maxforauditpeop",
                                    [10]
                                  ),
                                  error: _vm.errorOptions.gradeError,
                                  errorText: _vm.$t(
                                    "pc_biz_enroll_msg_positionlevelnotnull" /* 请选择所在职级 */
                                  ),
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(
                                      _vm.errorOptions,
                                      "gradeError",
                                      $event
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.showDrawer("grades")
                                  },
                                  delete: function ($event) {
                                    return _vm.deleteChecked(
                                      _vm.settings.grades,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.settings.cerEnabled &&
                                    !_vm.enrollCerShow.hidden,
                                  expression:
                                    "settings.cerEnabled && !enrollCerShow.hidden",
                                },
                              ],
                              staticClass: "mt24",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "yxtbiz-enroll-settings__content-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("pc_biz_enroll_lbl_hadcer"))
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "yxt-form-item__required mr5" },
                                [_c("i", { staticClass: "required-dot" })]
                              ),
                              _c("settings-checked", {
                                staticClass: "w566 display-in-block",
                                attrs: {
                                  list: _vm.settings.cers,
                                  disabled: _vm.enrollCerShow.disabled,
                                  placeholder: _vm.$t(
                                    "pc_biz_enroll_tip_maxforauditpeop",
                                    [10]
                                  ),
                                  error: _vm.errorOptions.cerError,
                                  errorText: _vm.$t(
                                    "pc_biz_enroll_msg_cernotnull" /* 请选择拥有证书 */
                                  ),
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(
                                      _vm.errorOptions,
                                      "cerError",
                                      $event
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.showDrawer("cers")
                                  },
                                  delete: function ($event) {
                                    return _vm.deleteChecked(
                                      _vm.settings.cers,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.settings.examEnabled,
                                  expression: "settings.examEnabled",
                                },
                              ],
                              staticClass: "mt24",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "yxtbiz-enroll-settings__content-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("pc_biz_enroll_lbl_passexam"))
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "yxt-form-item__required mr5" },
                                [_c("i", { staticClass: "required-dot" })]
                              ),
                              _c("settings-checked", {
                                staticClass: "w566 display-in-block",
                                attrs: {
                                  list: _vm.settings.exams,
                                  placeholder: _vm.$t(
                                    "pc_biz_enroll_tip_maxforauditpeop",
                                    [10]
                                  ),
                                  error: _vm.errorOptions.examError,
                                  errorText: _vm.$t(
                                    "pc_biz_enroll_msg_passexamnotnull" /* 请选择通过考试 */
                                  ),
                                },
                                on: {
                                  "update:error": function ($event) {
                                    return _vm.$set(
                                      _vm.errorOptions,
                                      "examError",
                                      $event
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.showDrawer("exams")
                                  },
                                  delete: function ($event) {
                                    return _vm.deleteChecked(
                                      _vm.settings.exams,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.settings.studyEnabled,
                                  expression: "settings.studyEnabled",
                                },
                              ],
                              staticClass: "mt24",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "yxtbiz-enroll-settings__content-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pc_biz_enroll_lbl_studyscore")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "yxt-form-item__required mr5" },
                                [_c("i", { staticClass: "required-dot" })]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "display-in-block",
                                  class: {
                                    "yxtbiz-enroll-settings__inputerror":
                                      _vm.errorOptions.scoreError,
                                  },
                                },
                                [
                                  _c("yxt-input-number", {
                                    staticClass: "w263",
                                    class: {
                                      "border-error":
                                        _vm.errorOptions.scoreError,
                                    },
                                    attrs: {
                                      "step-strictly": "",
                                      "controls-position": "right",
                                      min: 0,
                                      max: 999999,
                                    },
                                    on: { change: _vm.changeScoreNumber },
                                    model: {
                                      value: _vm.settings.studyScoreVo.value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings.studyScoreVo,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "settings.studyScoreVo.value",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "yxtbiz-enroll-settings__content-separator",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("pc_biz_enroll_lbl_and"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "yxt-select",
                                    {
                                      staticClass: "w263",
                                      class: {
                                        "border-error":
                                          _vm.errorOptions.scoreError,
                                      },
                                      model: {
                                        value: _vm.settings.studyScoreVo.around,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.settings.studyScoreVo,
                                            "around",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "settings.studyScoreVo.around",
                                      },
                                    },
                                    [
                                      _c("yxt-option", {
                                        attrs: {
                                          value: 0,
                                          label: _vm.$t(
                                            "pc_biz_enroll_lbl_above"
                                          ),
                                        },
                                      }),
                                      _c("yxt-option", {
                                        attrs: {
                                          value: 1,
                                          label: _vm.$t(
                                            "pc_biz_enroll_lbl_following"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.errorOptions.scoreError
                                    ? _c(
                                        "span",
                                        { staticClass: "input-error-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "pc_biz_ote_msg_studyscorenotnull" /* 请输入学分 */
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.settings.hireEnabled,
                                  expression: "settings.hireEnabled",
                                },
                              ],
                              staticClass: "mt24",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "yxtbiz-enroll-settings__content-label",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pc_biz_enroll_lbl_entrydate")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "yxt-form-item__required mr5" },
                                [_c("i", { staticClass: "required-dot" })]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "display-in-block",
                                  class: {
                                    "yxtbiz-enroll-settings__inputerror":
                                      _vm.errorOptions.hireError,
                                  },
                                },
                                [
                                  _c("yxt-date-picker", {
                                    staticClass: "w263",
                                    class: {
                                      "border-error":
                                        _vm.errorOptions.hireError,
                                    },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      clearable: "",
                                    },
                                    on: {
                                      change: _vm.changeHireTimeEvent,
                                      blur: _vm.blurHireTimeEvent,
                                    },
                                    model: {
                                      value: _vm.settings.hireDateVo.value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings.hireDateVo,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression: "settings.hireDateVo.value",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "yxtbiz-enroll-settings__content-separator",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("pc_biz_enroll_lbl_and"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "yxt-select",
                                    {
                                      staticClass: "w263",
                                      class: {
                                        "border-error":
                                          _vm.errorOptions.hireError,
                                      },
                                      model: {
                                        value: _vm.settings.hireDateVo.around,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.settings.hireDateVo,
                                            "around",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "settings.hireDateVo.around",
                                      },
                                    },
                                    [
                                      _c("yxt-option", {
                                        attrs: {
                                          value: 1,
                                          label: _vm.$t(
                                            "pc_biz_enroll_lbl_before"
                                          ),
                                        },
                                      }),
                                      _c("yxt-option", {
                                        attrs: {
                                          value: 0,
                                          label: _vm.$t(
                                            "pc_biz_enroll_lbl_after"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.errorOptions.hireError
                                    ? _c(
                                        "span",
                                        { staticClass: "input-error-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "pc_biz_enroll_msg_jointimenotnull" /* 请设置入职日期 */
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.fillRegistrationForm &&
          !_vm.enrollSurveyShow.hidden &&
          !_vm.enrollSurveyShow.disabled &&
          !_vm.settings.replaceEnabled
            ? _c(
                "yxt-form-item",
                {
                  attrs: {
                    label: _vm.$t("pc_biz_enroll_lbl_registrationform"),
                    "label-tooltip": _vm.$t(
                      "pc_biz_enroll_lbl_registrationformforstu"
                    ),
                    "label-tooltip-width": "200",
                  },
                },
                [
                  _c("yxt-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      width: 28,
                    },
                    model: {
                      value: _vm.settings.surveyEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "surveyEnabled", $$v)
                      },
                      expression: "settings.surveyEnabled",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("pc_biz_enroll_lbl_needregistrationform"))
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.settings.surveyEnabled,
                          expression: "settings.surveyEnabled",
                        },
                      ],
                      staticClass: "mt6 yxtbiz-enroll-settings__bg",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "color-gray-10 yxtbiz-enroll-settings__tip",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_enroll_lbl_registrationform"))
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "yxt-form-item__required mr5 yxtbiz-enroll-settings__center",
                        },
                        [_c("i", { staticClass: "required-dot" })]
                      ),
                      _c("settings-checked", {
                        staticClass: "w566",
                        attrs: {
                          list: _vm.settings.surveies,
                          placeholder: _vm.$t(
                            "pc_biz_enroll_msg_enrollformnotnull"
                          ),
                          error: _vm.errorOptions.surveyFormError,
                          errorText: _vm.$t(
                            "pc_biz_enroll_msg_enrollformnotnull" /* 请选择报名表单 */
                          ),
                        },
                        on: {
                          "update:error": function ($event) {
                            return _vm.$set(
                              _vm.errorOptions,
                              "surveyFormError",
                              $event
                            )
                          },
                          click: function ($event) {
                            return _vm.showDrawer("surveies")
                          },
                          delete: function ($event) {
                            return _vm.deleteChecked(
                              _vm.settings.surveies,
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.fillGiveup
            ? _c(
                "yxt-form-item",
                { attrs: { label: _vm.$t("pc_biz_enroll_lbl_giveup") } },
                [
                  _c("yxt-switch", {
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      width: 28,
                    },
                    model: {
                      value: _vm.settings.abandonEnabled,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "abandonEnabled", $$v)
                      },
                      expression: "settings.abandonEnabled",
                    },
                  }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_allowgiveup"))),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.settings.abandonEnabled,
                          expression: "settings.abandonEnabled",
                        },
                      ],
                      staticClass: "mt6 yxtbiz-enroll-settings__bg",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "color-gray-10 yxtbiz-enroll-settings__tip",
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_lbl_endtime")))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "yxt-form-item__required mr5 yxtbiz-enroll-settings__center",
                        },
                        [_c("i", { staticClass: "required-dot" })]
                      ),
                      _c(
                        "span",
                        { staticClass: "yxtbiz-enroll-settings__inputerror" },
                        [
                          _c("yxt-date-picker", {
                            staticClass: "w200",
                            class: {
                              "border-error":
                                _vm.errorOptions.abandonEndTimeError,
                            },
                            attrs: {
                              placeholder: _vm.$t("pc_biz_enroll_lbl_endtime"),
                              type: "datetime",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              clearable: "",
                            },
                            on: {
                              change: _vm.changeTimeEvent,
                              blur: _vm.blurTimeEvent,
                            },
                            model: {
                              value: _vm.settings.abandonEndTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "abandonEndTime", $$v)
                              },
                              expression: "settings.abandonEndTime",
                            },
                          }),
                          _vm.errorOptions.abandonEndTimeError
                            ? _c("span", { staticClass: "input-error-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "pc_biz_enroll_lbl_abandontime" /* 请输入截止时间 */
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "ml12 color-gray-7" }, [
                        _vm._v(
                          _vm._s(_vm.$t("pc_biz_enroll_lbl_intimeallowgiveup"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            "destroy-on-close": true,
            visible: _vm.drawers.enrollRange.show,
            size: "960px",
            "header-border": false,
            "before-close": _vm.rangeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.enrollRange, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-check-person-range", {
            ref: "enrollRange",
            staticClass: "pb24",
            attrs: {
              options: _vm.personRangeOptions,
              tabs: ["department", "usergroup", "person", "import"],
              personRange: _vm.drawers.enrollRange.value,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.rangePermissionCode,
              enableGroupCorp: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            on: {
              "update:personRange": function ($event) {
                return _vm.$set(_vm.drawers.enrollRange, "value", $event)
              },
              "update:person-range": function ($event) {
                return _vm.$set(_vm.drawers.enrollRange, "value", $event)
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("enrollRange")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer("enrollRange")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            "destroy-on-close": true,
            visible: _vm.drawers.excludeRange.show,
            size: "960px",
            "before-close": _vm.rangeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.excludeRange, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-check-person-range", {
            ref: "excludeRange",
            staticClass: "pb24",
            attrs: {
              tabs: ["person", "import"],
              personRange: _vm.drawers.excludeRange.value,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.rangePermissionCode,
              enableGroupCorp: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            on: {
              "update:personRange": function ($event) {
                return _vm.$set(_vm.drawers.excludeRange, "value", $event)
              },
              "update:person-range": function ($event) {
                return _vm.$set(_vm.drawers.excludeRange, "value", $event)
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("excludeRange")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer("excludeRange")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_enroll_tit_selectaudit"),
            "destroy-on-close": true,
            visible: _vm.drawers.checkUsers.show,
            size: "960px",
            "before-close": _vm.rangeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.checkUsers, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-check-person-range", {
            ref: "checkUsers",
            staticClass: "pb24",
            attrs: {
              tabs: ["person"],
              personRange: _vm.drawers.checkUsers.value,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.rangePermissionCode,
              enableGroupCorp: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            on: {
              "update:personRange": function ($event) {
                return _vm.$set(_vm.drawers.checkUsers, "value", $event)
              },
              "update:person-range": function ($event) {
                return _vm.$set(_vm.drawers.checkUsers, "value", $event)
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("checkUsers")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer("checkUsers", 10)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_enroll_tit_selectjob"),
            "destroy-on-close": true,
            visible: _vm.drawers.positions.show,
            size: "960px",
            "before-close": _vm.rangeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.positions, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-check-person-range", {
            ref: "positions",
            staticClass: "pb24",
            attrs: {
              tabs: ["position"],
              personRange: _vm.drawers.positions.value,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.rangePermissionCode,
              enableGroupCorp: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            on: {
              "update:personRange": function ($event) {
                return _vm.$set(_vm.drawers.positions, "value", $event)
              },
              "update:person-range": function ($event) {
                return _vm.$set(_vm.drawers.positions, "value", $event)
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("positions")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer("positions", 10)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_enroll_tit_selectpositionlevel"),
            "destroy-on-close": true,
            visible: _vm.drawers.grades.show,
            size: "960px",
            "before-close": _vm.rangeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.grades, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-check-person-range", {
            ref: "grades",
            staticClass: "pb24",
            attrs: {
              tabs: ["level"],
              personRange: _vm.drawers.grades.value,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.rangePermissionCode,
              enableGroupCorp: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            on: {
              "update:personRange": function ($event) {
                return _vm.$set(_vm.drawers.grades, "value", $event)
              },
              "update:person-range": function ($event) {
                return _vm.$set(_vm.drawers.grades, "value", $event)
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("grades")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer("grades", 10)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_enroll_tit_selectcer"),
            "destroy-on-close": true,
            visible: _vm.drawers.cers.show,
            size: "960px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.cers, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-certificate-selector", {
            staticClass: "pb24",
            attrs: {
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.cerPermissionCode,
              groupSelect: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            model: {
              value: _vm.drawers.cers.value,
              callback: function ($$v) {
                _vm.$set(_vm.drawers.cers, "value", $$v)
              },
              expression: "drawers.cers.value",
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("cers")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer(
                        "cers",
                        10,
                        _vm.drawers.cers.value
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_enroll_tit_selectexam"),
            "destroy-on-close": true,
            visible: _vm.drawers.exams.show,
            size: "960px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.exams, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-exam-arrange-selector", {
            staticClass: "pb24",
            attrs: {
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.examPermissionCode,
              groupSelect: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            model: {
              value: _vm.drawers.exams.value,
              callback: function ($$v) {
                _vm.$set(_vm.drawers.exams, "value", $$v)
              },
              expression: "drawers.exams.value",
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("exams")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer(
                        "exams",
                        10,
                        _vm.drawers.exams.value
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_enroll_tit_selectenroll"),
            "destroy-on-close": true,
            visible: _vm.drawers.surveies.show,
            size: "960px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.surveies, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-survey-template", {
            staticClass: "pb24",
            attrs: {
              type: 0,
              selected: _vm.selectedSurveyId,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.surveyPermissionCode,
            },
            on: {
              select: function ($event) {
                return _vm.confirmDrawer("surveies", null, $event)
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("surveies")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t(
              "pc_biz_enroll_tit_select_person_enroll" /* 选择人员代报名 */
            ),
            "destroy-on-close": true,
            visible: _vm.drawers.agentCheckTypeRange.show,
            size: "960px",
            "before-close": _vm.rangeBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawers.agentCheckTypeRange, "show", $event)
            },
          },
        },
        [
          _c("yxtbiz-check-person-range", {
            ref: "agentCheckTypeRange",
            staticClass: "pb24",
            attrs: {
              tabs: ["person"],
              personRange: _vm.drawers.agentCheckTypeRange.value,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.rangePermissionCode,
              enableGroupCorp: _vm.isGroup,
              targetOrgId: _vm.orgId,
            },
            on: {
              "update:personRange": function ($event) {
                return _vm.$set(
                  _vm.drawers.agentCheckTypeRange,
                  "value",
                  $event
                )
              },
              "update:person-range": function ($event) {
                return _vm.$set(
                  _vm.drawers.agentCheckTypeRange,
                  "value",
                  $event
                )
              },
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDrawer("agentCheckTypeRange")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_cancle")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDrawer("agentCheckTypeRange", 30)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_enroll_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }