import { fileApi } from 'yxt-biz-pc/packages/api';
let orgId = window.localStorage && window.localStorage.getItem('orgId');

export const getFileInfo = id => {
  return fileApi.get('/fileInfo/detail', {params: {id}});
};

export const getPlayInfo = (params, configs = {}) => {
  return fileApi.get('/play/detailAndWatermark', {...params, orgId}, configs);
};

// 获取文件是否转码中
export const getFileTransformState = (fileId) => {
  return fileApi.get('/play/convertStatus', {params: {fileId}});
};

export const getFileHtml = fileId => {
  return fileApi.get('/config/getParam4Html', {params: {fileId}});
};

export const getUploadConfig = (configKey) => {
  return fileApi.get('/config/get/' + configKey);
};

export const getFileDownloadUrl = fileId => {
  return fileApi.post('/download/saveas', [fileId]);
};

// 获取水印配置
export const getWatermarkConfig = () => {
  return fileApi.get('watermark/config');
};

// 获取机构的服务器组
export const getGroupList = () => {
  return fileApi.get(`local/serviceGroupUploadConfigByOrgid?orgId=${orgId}`);
};

// 根据服务器组的id,获取上传域名
export const getSecretEndpointById = (groupName) => {
  return fileApi.get(`local/uploadConfig?orgId=${orgId}&groupName=${groupName}`);
};

// 获取服务器组的主服务器
export const getMainServerList = (groupId) => {
  return fileApi.get(`local/serviceConfigById?orgId=${orgId}&groupId=${groupId}`);
};

// 获取机构混部的基础信息，机构是否只开启了只允许上传内网课件
export const getMixingInfo = () => {
  return fileApi.get(`/local/baseConfigById?orgId=${orgId}`);
};
