<template>
  <div class="tencent-info">
    <div ref="doc" class="yxtbiz-doc-viewer--tencent"></div>
    <yxtbiz-watermark v-if="isMarquee" :version="version" :app-code="appCode" :option="watermarkConfig"></yxtbiz-watermark>
  </div>
</template>

<script>
import 'whatwg-fetch';
import { getFileHtml } from '../service';
import '../tencent-sdk.js';
export default {
  props: {
    current: {
      type: Number,
      default: 1
    },
    fileId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'current'
  },
  computed: {
    isMarquee() {
      return this.watermarkConfig && this.watermarkConfig.enabled && this.watermarkConfig.type === 0;
    }
  },
  data() {
    return {
      instance: null,
      app: null,
      watermarkConfig: this.$parent.watermarkConfig,
      version: this.$parent.version,
      appCode: this.$parent.appCode,
      timer: null
    };
  },
  mounted() {
    const defaultContainerList = document.getElementsByClassName('web-office-default-container');
    this.wasDefaultContainerExisted = defaultContainerList.length > 0;
    Array.prototype.forEach.call(defaultContainerList, el => el.remove());
    this.init();
  },
  methods: {
    async init() {
      try {
        const fileUrl = await getFileHtml(this.fileId);
        let previewUrl = `${fileUrl}&ci-process=doc-preview&dsttype=html&weboffice_url=1&sign=AuthString&versionid=1`;
        const safe_chinese_url = str => window.btoa(window.unescape(window.encodeURI(str))).replace(/\+/g, '-').replace(/\//g, '_');
        const safe_url = str => window.btoa(str).replace(/\+/g, '-').replace(/\//g, '_');
        if (this.watermarkConfig && this.watermarkConfig.type) {
          // 密度计算
          const {htmlhorizontal, htmlvertical} = this.getDensityConfig(this.watermarkConfig.density);
          const waterWord = safe_chinese_url(this.watermarkConfig.text);
          const waterStyle = this.watermarkConfig.color ? `&htmlfillstyle=${safe_url(this.getRgba())}` : '';
          const waterFront = this.watermarkConfig.fontSize ? `&htmlfront=${safe_url(this.watermarkConfig.fontSize + 'px')}` : '';

          previewUrl += `&htmlhorizontal=${htmlhorizontal}&htmlvertical=${htmlvertical}&htmlwaterword=${waterWord}${waterStyle}${waterFront}`;
        };
        const response = await fetch(previewUrl);
        console.log('4.responseresponse', response, this.$refs.doc);
        const res = await response.json();
        console.log('4.1 res', res);
        if (res && res.PreviewUrl) {
          if (this.wasDefaultContainerExisted) {
            window.COSDocPreviewSDK && window.COSDocPreviewSDK.config({
              mount: this.$refs.doc,
              mode: 'simple', // 'normal'
              url: res.PreviewUrl,
              commonOptions: {
                isShowTopArea: true,
                isShowHeader: false
              },
              cooperUserAttribute: {
                isCooperUsersAvatarVisible: false
              }
            });
          }

          this.instance = window.COSDocPreviewSDK && window.COSDocPreviewSDK.config({
            mount: this.$refs.doc,
            mode: 'simple', // 'normal'
            url: res.PreviewUrl,
            commonOptions: {
              isShowTopArea: true,
              isShowHeader: false
            },
            cooperUserAttribute: {
              isCooperUsersAvatarVisible: false
            }
          });

          try {
            await this.instance.ready();
          } catch (e) {
            console.log('ready err', e);
          }
          // 当前页改变事件
          console.log('5.instance', this.instance, this.current);
          if (this.instance.PPTApplication) {
            this.initPptType();
          } else if (this.instance.WordApplication) {
            this.initWordType();
          } else if (this.instance.ExcelApplication) {
            this.initExcelType();
          } else if (this.instance.PDFApplication) {
            this.initPdfType();
          }
          // setTimeout(() => this.navigate(this.current), 100);
        } else {
          console.log('elseelse');
        }
      } catch (e) {
        console.error('errerrerr', e);
      }
    },
    getDensityConfig(density) {
      const densityValues = {
        0: { htmlhorizontal: 450, htmlvertical: 350 },
        1: { htmlhorizontal: 250, htmlvertical: 350 },
        2: { htmlhorizontal: 50, htmlvertical: 100 }
      };

      return densityValues[density] || { htmlhorizontal: 250, htmlvertical: 200 };
    },
    async initPptType() {
      this.app = this.instance.PPTApplication();
      console.log('6.ppt 逻辑', this.app);
      this.app.Sub.SlideSelectionChanged = this.pageChange;
      console.log('7.----------');
      this.app.Sub.SlideShowOnNext = this.pageChange;
      console.log('8.----------');
      this.$emit('totalPage', await this.app.ActivePresentation.Slides.Count);
      console.log('9.----------');
      this.navigate = page => this.app.ActivePresentation.SlideShowWindow.View.GotoSlide(page);
    },
    async initWordType() {
      this.app = this.instance.WordApplication();
      this.app.Sub.CurrentPageChange = this.pageChange;
      const Enum = this.app.Enum;
      const wdInfoEnum = Enum.WdInformation.wdNumberOfPagesInDocument;
      const wdGotoEnum = Enum.WdGoToItem.wdGoToPage;
      const wdGoToAbsolute = Enum.WdGoToDirection.wdGoToAbsolute;
      const getPagesCount = () => {
        this.timer = setTimeout(async() => {
          const pages = await this.app.ActiveDocument.Range.Information(wdInfoEnum);
          this.$emit('totalPage', pages.PagesCount);
          if (!pages.End) {
            getPagesCount();
          }
        }, 200);
      };
      getPagesCount();
      this.navigate = page => this.app.ActiveDocument.Selection.GoTo(wdGotoEnum, wdGoToAbsolute, page);
    },
    async initExcelType() {
      this.app = this.instance.ExcelApplication();
      this.app.Sub.Worksheet_Activate = this.pageChange;
      const count = await this.app.Sheets.Count;
      this.excelSheetNames = [];
      this.$emit('totalPage', count);
      await this.app.For(1, count, 1, async(Index) => {
        this.excelSheetNames.push(await this.app.Sheets.Item(Index).Name);
      });
      this.navigate = page => this.app.Sheets.Item(page).Activate();
    },
    async initPdfType() {
      this.app = this.instance.PDFApplication();
      this.app.Sub.CurrentPageChange = this.pageChange;
      this.$emit('totalPage', await this.app.ActivePDF.PagesCount);
      this.navigate = page => this.app.ActivePDF.JumpToPage({ PageNum: page });
    },
    navigate(num) { // 跳转到指定页
      // 空方法，给parent调用
    },
    async pageChange(e) {
      if (this.excelSheetNames) {
        const name = await this.app.ActiveSheet.Name;
        e = this.excelSheetNames.findIndex(v => v === name) + 1;
      } else if (this.instance.PDFApplication) {
        e += 1;
      }
      this.$emit('pageChange', e);
    },
    getRgba() {
      const hex = this.watermarkConfig.color;
      const opacity = this.watermarkConfig.opacity;
      let r, g, b;
      r = parseInt(hex.substr(1, 2), 16);
      g = parseInt(hex.substr(3, 2), 16);
      b = parseInt(hex.substr(5, 2), 16);
      return `rgb(${r},${g},${b}, ${opacity / 100})`;
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.instance && this.instance.destroy && this.instance.destroy();
  }
};
</script>
<style>
.tencent-info {
  height: 100%;
}
.yxtbiz-doc-viewer--tencent,
.yxtbiz-doc-viewer--tencent iframe {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
}
.web-office-default-container{
  display: none;
}
</style>
