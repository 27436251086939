var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-doc-viewer",
      class: [
        "yxtbiz-doc-viewer--" + _vm.modeValue,
        _vm.preview ? "preview" : "",
      ],
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "yxtbiz-doc-viewer__loading" })
        : _vm._e(),
      _vm.error
        ? _c("div", { staticClass: "yxtbiz-doc-viewer__error" })
        : _vm._e(),
      _vm.type === "pdf" || _vm.previewSingleImg
        ? _c("pdf-viewer", {
            ref: "viewer",
            attrs: {
              initRotate: _vm.initRotate,
              imgScale: _vm.initScale,
              scale: _vm.scaleLevel,
            },
            on: { "zoom-visible-change": _vm.zoomVisibleChange },
            model: {
              value: _vm.current,
              callback: function ($$v) {
                _vm.current = $$v
              },
              expression: "current",
            },
          })
        : _vm._e(),
      _vm.type === "ppt"
        ? _c("ppt-viewer", {
            ref: "viewer",
            attrs: { scale: _vm.scaleLevel },
            on: { "zoom-visible-change": _vm.zoomVisibleChange },
            model: {
              value: _vm.current,
              callback: function ($$v) {
                _vm.current = $$v
              },
              expression: "current",
            },
          })
        : _vm._e(),
      _vm.isTencentType && !_vm.previewSingleImg
        ? _c("tencent-viewer", {
            ref: "viewer",
            attrs: { fileId: _vm.fileId },
            on: { pageChange: _vm.onPageChange, totalPage: _vm.syncPageCount },
            model: {
              value: _vm.current,
              callback: function ($$v) {
                _vm.current = $$v
              },
              expression: "current",
            },
          })
        : _vm._e(),
      _vm.type === "ppt" && !_vm.preview
        ? [
            _c(
              "div",
              {
                staticClass: "yxtbiz-doc-viewer__ppt-arrow--left",
                class: _vm.current === 1 ? "" : "yxtbiz-doc-viewer__ppt-arrow",
                on: { click: _vm.prevClick },
              },
              [_c("yxt-svg", { attrs: { "icon-class": "arrow-left" } })],
              1
            ),
            _c(
              "div",
              {
                staticClass: "yxtbiz-doc-viewer__ppt-arrow--right",
                class:
                  _vm.current === _vm.count
                    ? ""
                    : "yxtbiz-doc-viewer__ppt-arrow",
                on: { click: _vm.nextClick },
              },
              [_c("yxt-svg", { attrs: { "icon-class": "arrow-right" } })],
              1
            ),
          ]
        : _vm._e(),
      !_vm.preview
        ? _c(
            "div",
            { staticClass: "yxtbiz-doc-viewer__toolbar" },
            [
              _vm.showMode
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.modeValue === "sun",
                          expression: "modeValue === 'sun'",
                        },
                      ],
                      staticClass: "mr32 lh0",
                      on: {
                        click: function ($event) {
                          return _vm.modeChange("night")
                        },
                      },
                    },
                    [
                      _c("yxtf-svg", {
                        attrs: {
                          "icon-class": "f_night",
                          width: "22px",
                          height: "22px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showMode
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.modeValue === "night",
                          expression: "modeValue === 'night'",
                        },
                      ],
                      staticClass: "mr32 lh0",
                      on: {
                        click: function ($event) {
                          return _vm.modeChange("sun")
                        },
                      },
                    },
                    [
                      _c("yxtf-svg", {
                        attrs: {
                          "icon-class": "f_sun",
                          width: "22px",
                          height: "22px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showZoom && !_vm.isTencentType
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.zoomVisible && _vm.width,
                          expression: "zoomVisible && width",
                        },
                      ],
                      staticClass: "mr32 lh0",
                      class:
                        _vm.scaleLevel === 10
                          ? "yxtbiz-doc-viewer__icon--disabled"
                          : "",
                      on: {
                        click: function ($event) {
                          return _vm.zoom(_vm.scaleLevel + 1)
                        },
                      },
                    },
                    [
                      _c("yxtf-svg", {
                        attrs: {
                          "icon-class": "f_zoom-in-2",
                          width: "20px",
                          height: "20px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showZoom && !_vm.isTencentType
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.zoomVisible && _vm.width,
                          expression: "zoomVisible && width",
                        },
                      ],
                      staticClass: "mr32 lh0",
                      class:
                        _vm.scaleLevel === -5
                          ? "yxtbiz-doc-viewer__icon--disabled"
                          : "",
                      on: {
                        click: function ($event) {
                          return _vm.zoom(_vm.scaleLevel - 1)
                        },
                      },
                    },
                    [
                      _c("yxtf-svg", {
                        attrs: {
                          "icon-class": "f_zoom-out-2",
                          width: "20px",
                          height: "20px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFullscreen
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.zoomVisible && !_vm.fullscreen,
                          expression: "zoomVisible && !fullscreen",
                        },
                      ],
                      staticClass: "mr32 lh0",
                      on: { click: _vm.intoFullscreen },
                    },
                    [
                      _c("yxtf-svg", {
                        attrs: {
                          "icon-class": "f_fullscreen",
                          width: "18px",
                          height: "18px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFullscreen
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.zoomVisible && _vm.fullscreen,
                          expression: "zoomVisible && fullscreen",
                        },
                      ],
                      staticClass: "mr32 lh0",
                      on: { click: _vm.exitFullscreen },
                    },
                    [
                      _c("yxtf-svg", {
                        attrs: {
                          "icon-class": "f_fullscreen-exit",
                          width: "18px",
                          height: "18px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "ml24 mr24 lh0",
                  class: [
                    _vm.current === 1
                      ? "yxtbiz-doc-viewer__icon--disabled"
                      : "",
                    !_vm.showMode && !_vm.showZoom && !_vm.showFullscreen
                      ? "ml24"
                      : "",
                  ],
                  on: { click: _vm.prevClick },
                },
                [
                  _c("yxtf-svg", {
                    attrs: {
                      "icon-class": "f_up-circle-o",
                      width: "20px",
                      height: "20px",
                    },
                  }),
                ],
                1
              ),
              _c("yxt-input", {
                on: {
                  blur: function ($event) {
                    _vm.input = _vm.current
                  },
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.currentInput.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.input,
                  callback: function ($$v) {
                    _vm.input = $$v
                  },
                  expression: "input",
                },
              }),
              _c(
                "span",
                { staticClass: "mr24 yxtbiz-doc-viewer__toolbar-text" },
                [
                  _vm._v(
                    "/ " +
                      _vm._s(_vm.count) +
                      " " +
                      _vm._s(_vm.$t("pc_biz_doc_lbl_page"))
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "mr24 lh0",
                  class:
                    _vm.current === _vm.count
                      ? "yxtbiz-doc-viewer__icon--disabled"
                      : "",
                  on: { click: _vm.nextClick },
                },
                [
                  _c("yxtf-svg", {
                    attrs: {
                      "icon-class": "f_down-circle-o",
                      width: "20px",
                      height: "20px",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.preview && _vm.fileType !== "excel"
        ? _c("div", { staticClass: "yxtbiz-doc-viewer__toolbar preview" }, [
            _c(
              "div",
              { staticClass: "preview-info" },
              [
                !_vm.previewSingleImg || _vm.isTencentType
                  ? _c(
                      "yxtf-tooltip",
                      {
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_doc_lbl_prevPage" /* 上一页 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ml8 mr16 lh0",
                            class:
                              _vm.current === 1
                                ? "yxtbiz-doc-viewer__icon--disabled"
                                : "",
                            on: { click: _vm.prevClick },
                          },
                          [
                            _c("yxtf-svg", {
                              attrs: {
                                "icon-class": "f_up-circle-o",
                                width: "20px",
                                height: "20px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.previewSingleImg || _vm.isTencentType
                  ? _c(
                      "yxtf-tooltip",
                      {
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_input_toturn" /* 输入页面可跳转 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c("yxt-input", {
                          on: {
                            blur: function ($event) {
                              _vm.input = _vm.current
                            },
                          },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.currentInput.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.input,
                            callback: function ($$v) {
                              _vm.input = $$v
                            },
                            expression: "input",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.previewSingleImg || _vm.isTencentType
                  ? _c(
                      "span",
                      { staticClass: "mr16 yxtbiz-doc-viewer__toolbar-text" },
                      [_vm._v("/ " + _vm._s(_vm.count) + "页")]
                    )
                  : _vm._e(),
                !_vm.previewSingleImg || _vm.isTencentType
                  ? _c(
                      "yxtf-tooltip",
                      {
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_doc_lbl_nextPage" /* 下一页 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "mr16 lh0",
                            class:
                              _vm.current === _vm.count
                                ? "yxtbiz-doc-viewer__icon--disabled"
                                : "",
                            on: { click: _vm.nextClick },
                          },
                          [
                            _c("yxtf-svg", {
                              attrs: {
                                "icon-class": "f_down-circle-o",
                                width: "20px",
                                height: "20px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.showFullscreen
                  ? _c(
                      "yxtf-tooltip",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.zoomVisible && !_vm.fullscreen,
                            expression: "zoomVisible && !fullscreen",
                          },
                        ],
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_imgviewer_btn_fullscreen" /* 全屏 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "lh0",
                            on: { click: _vm.intoFullscreen },
                          },
                          [
                            _c("yxtf-svg", {
                              attrs: {
                                "icon-class": "f_fullscreen",
                                width: "18px",
                                height: "18px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.showFullscreen
                  ? _c(
                      "yxtf-tooltip",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.zoomVisible && _vm.fullscreen,
                            expression: "zoomVisible && fullscreen",
                          },
                        ],
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_imgviewer_btn_nofullscreen" /* 退出全屏 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "lh0",
                            on: { click: _vm.exitFullscreen },
                          },
                          [
                            _c("yxtf-svg", {
                              attrs: {
                                "icon-class": "f_fullscreen-exit",
                                width: "18px",
                                height: "18px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.previewSingleImg &&
                _vm.initList.length &&
                _vm.initList.length === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxtf-tooltip",
                          {
                            attrs: {
                              content: _vm.$t(
                                "pc_biz_imgviewer_btn_scale_in" /* 放大 */
                              ),
                              placement: "top",
                              effect: "ellipsis",
                              "open-filter": false,
                              "max-width": 200,
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon-scale1",
                              attrs: { "data-viewer-action": "zoom-in" },
                              on: {
                                click: function ($event) {
                                  return _vm.scale("in")
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.previewSingleImg &&
                _vm.initList.length &&
                _vm.initList.length === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxtf-tooltip",
                          {
                            attrs: {
                              content: _vm.$t(
                                "pc_biz_imgviewer_btn_scale_out" /* 缩小 */
                              ),
                              placement: "top",
                              effect: "ellipsis",
                              "open-filter": false,
                              "max-width": 200,
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon-scale2",
                              attrs: { "data-viewer-action": "zoom-out" },
                              on: {
                                click: function ($event) {
                                  return _vm.scale("out")
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.previewSingleImg && !_vm.fullscreen
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxtf-tooltip",
                          {
                            attrs: {
                              content: _vm.$t(
                                "pc_biz_imgviewer_btn_fullscreen" /* 全屏 */
                              ),
                              placement: "top",
                              effect: "ellipsis",
                              "open-filter": false,
                              "max-width": 200,
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon-full-screen",
                              attrs: { "data-viewer-action": "play" },
                              on: { click: _vm.intoFullscreen },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.previewSingleImg && _vm.fullscreen
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxtf-tooltip",
                          {
                            attrs: {
                              content: _vm.$t(
                                "pc_biz_imgviewer_btn_init" /* 适应页面 */
                              ),
                              placement: "top",
                              effect: "ellipsis",
                              "open-filter": false,
                              "max-width": 200,
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon-reset-img",
                              attrs: { "data-viewer-action": "reset" },
                              on: { click: _vm.exitFullscreen },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.previewSingleImg &&
                _vm.initList.length &&
                _vm.initList.length === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxtf-tooltip",
                          {
                            attrs: {
                              content: _vm.$t(
                                "pc_biz_imgviewer_btn_rotate" /* 旋转 */
                              ),
                              placement: "top",
                              effect: "ellipsis",
                              "open-filter": false,
                              "max-width": 200,
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "icon-turn-transform",
                              attrs: { "data-viewer-action": "rotate-left" },
                              on: { click: _vm.transform },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "lines" }),
                _c(
                  "div",
                  [
                    _c(
                      "yxtf-tooltip",
                      {
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_import_btn_download" /* 下载 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "icon-download",
                          on: { click: _vm.download },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "yxtf-tooltip",
                      {
                        attrs: {
                          content: _vm.$t(
                            "pc_biz_lbl_joinlib" /* 加入知识库 */
                          ),
                          placement: "top",
                          effect: "ellipsis",
                          "open-filter": false,
                          "max-width": 200,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "icon-folder-add",
                          on: { click: _vm.addToKngStore },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }