<template>
  <div :style="style" class="yxtbiz-amap">
    <div class="yxtbiz-amap__main map" id="yxtbiz-amap-container" tabindex="0"></div>
    <yxt-scrollbar v-if="poi" class="yxtbiz-amap__panel">
      <div>
        <yxt-input v-if="poiInput" @input="onPoiChange" id="yxtbiz-amap-input" v-model="input" :placeholder="$t('pc_biz_amap_tip_search'/* 请输入内容 */)"></yxt-input>
        <div v-if="poiResult" id="yxtbiz-amap-results" class="yxtbiz-amap__results"></div>
      </div>
    </yxt-scrollbar>
  </div>
</template>

<script>
import { getAreas } from './service';
import { loadScript } from 'yxt-biz-pc/src/utils/util';
import { Scrollbar } from 'yxt-pc';
const MAP_URL = 'https://webapi.amap.com/maps?v=1.4.15&key=cc21f7a5ddf690207742f3c3698fa51d&plugin=AMap.Geocoder';
const MAP_UI_URL = 'https://webapi.amap.com/ui/1.0/main.js?v=1.0.11';
let map = null;
let poiPicker = null;
let geocoder = null;
let marker = null;
let positionPicker = null;
let location = null;
let provinceList = [];
let areaList = [];

export default {
  name: 'YxtbizAmap',
  components: {
    YxtScrollbar: Scrollbar
  },
  props: {
    width: {
      type: String | Number,
      default: 400
    },
    height: {
      type: String | Number,
      default: 300
    },
    address: {
      type: String,
      default: ''
    },
    poi: {
      type: Boolean,
      default: false
    },
    poiInput: {
      type: Boolean | String,
      default: true
    },
    poiResult: {
      type: Boolean | String,
      default: true
    },
    geolocation: Array,
    draggable: Boolean,
    lang: {
      type: String,
      default: 'zh_CN'
    }
  },
  watch: {
    address(val) {
      if (this.poi) {
        this.input = val;
      } else {
        this.geoCode();
      }
    }
  },
  data() {
    return {
      input: this.address
    };
  },
  computed: {
    style() {
      return `width:${this.width}px;height:${this.height}px;`;
    }
  },
  mounted() {
    const checkUISdk = () => (this.draggable || this.poi) && !window.AMapUI
      ? loadScript(MAP_UI_URL).then(() => {
        this.getAreaList();
        checkDraggable();
      }) : checkDraggable();
    const checkDraggable = () => this.draggable
      ? this.dragPos()
      : this.init();
    window.AMap ? checkUISdk() : loadScript(MAP_URL).then(checkUISdk);
  },
  methods: {
    init() {
      map = new window.AMap.Map('yxtbiz-amap-container', {
        resizeEnable: true,
        zoom: 16,
        lang: this.lang === 'en' ? 'en' : 'zh_cn',
        citylimit: false
      });
      window.AMap.plugin(['AMap.ToolBar'], function() {
        map.addControl(new window.AMap.ToolBar({
          map: map
        }));
      });
      geocoder = new window.AMap.Geocoder({
        radius: 1000
      });
      marker = new window.AMap.Marker();
      this.poi && this.poiCode();
      this.geolocation ? this.regeoCode() : this.geoCode();
    },
    getLocation(str) {
      if (!geocoder) {
        geocoder = new window.AMap.Geocoder({ radius: 1000 });
      }
      return new window.Promise((resolve, reject) => {
        geocoder.getLocation(str, (status, result) => {
          if (status === 'complete' && result.geocodes.length) {
            location = result.geocodes[0].location;
            resolve([location.lng, location.lat]);
          } else {
            reject();
            console.error('根据地址查询位置失败');
          }
        });
      });
    },
    geoCode() {
      if (geocoder) {
        geocoder.getLocation(this.address, (status, result) => {
          if (status === 'complete' && result.geocodes.length) {
            location = result.geocodes[0].location;
            if (positionPicker) {
              positionPicker.start(location);
            } else {
              this.setMarker(location);
              this.$emit('complete', { name: this.address, location: [location.lng, location.lat] });
            }
          } else {
            console.error('根据地址查询位置失败');
          }
        });
      }
    },
    regeoCode() {
      this.setMarker(this.geolocation);
      geocoder.getAddress(this.geolocation, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          this.$emit('complete', { name: result.regeocode.formattedAddress });
        } else {
          console.error('根据经纬度查询地址失败');
        }
      });
    },
    poiCode() {
      if (window.AMapUI) {
        window.AMapUI.loadUI(['misc/PoiPicker'], (PoiPicker) => {
          if (PoiPicker) {
            let config = {
              input: typeof this.poiInput === 'string' ? this.poiInput : 'yxtbiz-amap-input',
              placeSearchOptions: {
                lang: this.lang === 'en' ? 'en' : 'zh_cn',
                map: map,
                pageSize: 5
              }
            };
            if (this.poiResult) {
              config.searchResultsContainer = typeof this.poiResult === 'string' ? this.poiResult : 'yxtbiz-amap-results';
            }
            poiPicker = new PoiPicker(config);
            poiPicker.on('poiPicked', this.poiPicked);
          } else {
            this.poiCode();
          }
        });
      } else {
        setTimeout(this.poiCode, 200, 3);
      }
    },
    poiPicked(poiResult) {
      poiPicker && poiPicker.clearSearchResults();
      let item = poiResult.item;
      if (poiResult.source !== 'search') {
        item.adcode && poiPicker.setCity(item.adcode);
        poiPicker.searchByKeyword(item.name);
      } else {
        location = item.location;
        if (positionPicker) {
          positionPicker.start(location);
        } else {
          this.setMarker(location);
          let cityCode = this.getCityCode(item.adcode);
          this.$emit('complete', {
            name: item.name,
            location: [location.lng, location.lat],
            codes: [item.pcode, cityCode, item.adcode],
            names: this.getNames(item.pcode, cityCode, item.adcode)
          });
        }
      }
    },
    onPoiChange() {
      poiPicker.clearSearchResults();
    },
    setMarker(location) {
      marker.setPosition(location);
      map.add(marker);
      map.setFitView(marker);
    },
    getCityCode(code) {
      code = code.substr(0, 4) + '00';
      return code === '500200' ? '500100' : code;
    },
    getAreaList() {
      getAreas().then(res => {
        if (res && res.datas) {
          areaList = res.datas;
          provinceList = areaList.map(item => { return { id: item.id, name: item.name }; });
        }
      });
    },
    getProvinceCodeByName(name) {
      let province = provinceList.find(item => item.name === name);
      return province ? province.id : '';
    },
    getNames(pcode, citycode, adcode) {
      let names = [];
      const findItem = (list, code) => list ? list.find(item => item.id === code) : null;
      let pItem = findItem(areaList, pcode);
      pItem && names.push(pItem.name);
      let cityItem = findItem(pItem.children, citycode);
      cityItem && names.push(cityItem.name);
      let adItem = findItem(cityItem.children, adcode);
      adItem && names.push(adItem.name);
      return names;
    },
    dragPos() {
      window.AMapUI.loadUI(['misc/PositionPicker'], (PositionPicker) => {
        if (!PositionPicker) {
          setTimeout(this.dragPos, 200, 3);
          return;
        }
        this.init();
        positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', this.dragHandler);
        positionPicker.on('fail', this.dragHandler);
        positionPicker.start();
      });
    },
    dragHandler(result) {
      if (result && result.info === 'OK') {
        let item = result.regeocode.addressComponent;
        const provinceCode = this.getProvinceCodeByName(item.province);
        const cityCode = this.getCityCode(item.adcode);
        this.$emit('complete', {
          name: result.address,
          location: [result.position.lng, result.position.lat],
          codes: [provinceCode, cityCode, item.adcode],
          names: this.getNames(provinceCode, cityCode, item.adcode)
        });
      }
    }
  }
};
</script>
