import { render, staticRenderFns } from "./outputting-dot.vue?vue&type=template&id=8895647e&scoped=true"
var script = {}
import style0 from "./outputting-dot.vue?vue&type=style&index=0&id=8895647e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8895647e",
  null
  
)

export default component.exports