var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "yxtbiz-ai-project-assistant-dot__wrapper" },
      [
        _c("li", {
          staticClass:
            "yxtbiz-ai-project-assistant-dot yxtbiz-ai-project-assistant-dot--one",
        }),
        _c("li", {
          staticClass:
            "yxtbiz-ai-project-assistant-dot yxtbiz-ai-project-assistant-dot--two",
        }),
        _c("li", {
          staticClass:
            "yxtbiz-ai-project-assistant-dot yxtbiz-ai-project-assistant-dot--three",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }