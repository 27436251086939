var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled && _vm.version === "v1"
    ? _c(
        "div",
        {
          class: {
            "yxtbiz-watermark": true,
            "yxtbiz-watermark--show": _vm.show,
          },
          style: _vm.style,
        },
        [
          _vm.isWatermark
            ? _vm._l(_vm.watermarks, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "yxtbiz-watermark__item",
                    style: item,
                  },
                  [_vm._v("\n      " + _vm._s(_vm.option.text) + "\n    ")]
                )
              })
            : _c(
                "div",
                { ref: "marquee", staticClass: "yxtbiz-watermark__marquee" },
                [_vm._v("\n    " + _vm._s(_vm.option.text) + "\n  ")]
              ),
        ],
        2
      )
    : _vm.enabledV2 && _vm.version === "v2"
    ? _c("div", { ref: "watermarkRef", staticClass: "yxtbiz-watermark-v2" })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }