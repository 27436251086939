<template>
  <Cropper
    v-if="noDrawer"
    ref="cropper"
    :src="imgUrl"
    v-bind="$attrs"
    @loading="v=>$emit('loading', v)"
    @save-disable="v=>$emit('disable', v)"
    @complete="onComplete"
  ></Cropper>
  <yxt-drawer
    v-else
    :title="$t('pc_biz_imgcropper_title'/* 上传图片 */)"
    :visible.sync="visible"
    :before-close="handleClose"
    size="960px"
  >
    <Cropper ref="cropper"
      v-bind="$attrs"
      :src="imgUrl"
      @complete="onComplete"
      @loading="v => loading = v"
      @save-disable="v => disable = v"></Cropper>
    <span slot="footer" class="dialog-footer">
      <yxt-button plain @click="handleClose">{{$t('pc_biz_core_btn_cancel'/* 取消 */)}}</yxt-button>
      <yxt-button type="primary" :loading="loading" @click="() => this.$refs.cropper.save()" :disabled="disable"
        >{{$t('pc_biz_core_btn_confirm'/* 确定 */)}}</yxt-button>
    </span>
  </yxt-drawer>
</template>

<script>
import { Drawer } from 'yxt-pc';
import Cropper from './cropper';

export default {
  name: 'YxtbizImageCropper',
  components: {
    YxtDrawer: Drawer,
    Cropper
  },
  props: {
    visible: Boolean,
    src: String,
    noDrawer: Boolean
  },
  data() {
    return {
      imgUrl: this.src,
      disable: true,
      loading: false
    };
  },
  watch: {
    src(val) {
      this.imgUrl = val;
    },
    visible(val) {
      this.$refs.cropper && val && this.$refs.cropper.init(this.imgUrl);
    }
  },
  methods: {
    save() {
      this.$refs.cropper.save();
    },
    onComplete(url, file) {
      this.$emit('complete', url, file);
      !this.front && this.$emit('update:visible', false);
    },
    handleClose() {
      if (this.noEdit) {
        this.onComplete();
        return;
      }
      this.$confirm(this.$t('pc_biz_core_msg_confirmcancletip'/* 当前操作尚未保存，是否确认取消？ */), this.$t('pc_biz_core_msg_cancel'/* 确认取消？ */), { type: 'warning' })
        .then(() => {
          this.onComplete();
        }).catch(() => {});
    }
  }
};
</script>
