<template>
  <div class="yxtbiz-doc-viewer-pdf" @copy="() => false" :class="[preview ? 'preview' : '', wideScreen ? 'preview-width' : '', !previewSingleImg ? 'multiple-image' : '']">
    <div class="yxtbiz-doc-viewer-pdf__pages" :style="{width: (list.length && list.length === 1 && previewSingleImg) ? null : scaleWidth ? scaleWidth + 'px' : null}">
      <div class="yxtbiz-doc-viewer-pdf__scroll" :class="[list.length && list.length === 1 && previewSingleImg ? 'preview' : 'preview-more']" ref="scroll">
        <template v-if="list.length">
          <div class="yxtbiz-doc-viewer-pdf__page" :class="[previewSingleImg ? 'preview-single': 'preview-multiple']" v-for="(item, index) in list" :ref="`page_${index + 1}`" :data-index="index"
            :key="index + 'pdf'">
            <template v-if="item.status === 'loading' || item.status === 'loaded'">
              <img :src="item.url" :class="[previewSingleImg && wideScreen ? 'img-width-screen' : 'img-narrow-screen']" :alt="index + 1" @load="imageLoad($event, item, true)" :style="{ display: !placeholderHeight || item.status === 'loaded' ? 'block' : 'none', transform: 'scale(' + initScale + ') rotateZ(' + imgRotate + 'deg)' }">
              <yxtbiz-watermark :id="uuid" v-if="!isMarquee && item.status === 'loaded'" :version="version" :app-code="appCode" :option="watermarkConfig"></yxtbiz-watermark>
            </template>
            <div v-if="item.status !== 'loaded'" class="yxtbiz-doc-viewer-pdf__placeholder" :style="{height: placeholderHeight ? placeholderHeight + 2 + 'px' : null, 'backgroundImage': loadingUrl ? `url(${loadingUrl})` : null}"></div>
          </div>
        </template>
      </div>
      <yxtbiz-watermark v-if="isMarquee" :version="version" :app-code="appCode" :option="watermarkConfig"></yxtbiz-watermark>
    </div>
  </div>
</template>

<script>
import YxtbizWatermark from 'yxt-biz-pc/packages/watermark';
import { throttle } from 'throttle-debounce';
import { getFileList } from '../service';

const cacheTime = 3000; // 混布链接缓存时间

/**
 * status 状态说明：
 *   waiting：等待获取地址
 *   pending：获取地址中
 *   canLoad：已有地址，可以加载
 *   loading：加载中
 *   loaded：已加载
 */
export default {
  name: 'PdfViewer',
  components: {
    YxtbizWatermark
  },
  props: {
    current: Number,
    scale: Number,
    showZoom: Boolean,
    imgScale: Number,
    initRotate: Number
  },
  model: {
    prop: 'current',
    event: 'currentChange'
  },
  data() {
    return {
      list: null, // 页面列表数据
      pageWidth: 0, // 页面宽度
      scaleWidth: 0, // 缩放宽度
      placeholderHeight: 0, // 占位层高度
      loadingUrl: this.$parent.loadingUrl,
      watermarkConfig: this.$parent.watermarkConfig,
      version: this.$parent.version,
      appCode: this.$parent.appCode,
      initScale: this.$parent.initScale,
      imgRotate: this.$parent.initRotate,
      preview: this.$parent.preview,
      previewSingleImg: this.$parent.previewSingleImg,
      uuid: this.uuid(),
      wideScreen: true // 是否宽屏 大于1200为宽屏
    };
  },
  computed: {
    isMarquee() {
      return this.watermarkConfig && this.watermarkConfig.enabled && this.watermarkConfig.type === 0;
    }
  },
  watch: {
    current() {
      this.currentChange();
    },
    scale() {
      this.setScaleWidth();
      this.navigate(this.current);
    },
    imgScale() {
      this.initScale = this.imgScale;
    },
    initRotate() {
      this.imgRotate = this.initRotate;
    }
  },
  created() {
    // 取得需要用到的父组件的一些值
    this.count = this.$parent.count;
    this.width = this.$parent.width;
    this.localPlay = this.$parent.localPlay;

    // 生成列表数据
    const temp = [];
    const initList = this.$parent.initList;
    const length = initList.length;
    if (this.localPlay) {
      this.fileId = this.$parent.fileInfo.fileId;
      this.serviceGroup = this.$parent.fileInfo.serverGroupId;
      for (let i = 0; i < this.count; i++) {
        temp.push({
          url: '',
          status: 'waiting'
        });
      }
      this.tempList = initList;
      setTimeout(() => {
        this.tempList = undefined;
      }, cacheTime);
    } else {
      for (let i = 0; i < this.count; i++) {
        if (i < length) {
          temp.push({
            url: initList[i].url,
            status: 'canLoad'
          });
        } else {
          temp.push({
            url: '',
            status: 'waiting'
          });
        }
      }
    }
    this.list = temp;
  },
  mounted() {
    this.container = this.$parent.$el; // 父容器
    this.scroll = this.$refs.scroll; // 滚动容器
    this.bindEvent();
    this.setWidth();
    this.currentChange();
    window.onresize = () => {
      this.computedWidth();
    };
    this.throttleLoadImage = throttle(300, this.loadImage);
  },
  beforeDestroy() {
    this.unBindEvent();
  },
  destroyed() {
    this.unBindEvent();
  },
  methods: {
    uuid() {
      var temp_url = URL.createObjectURL(new Blob());
      var uuid = temp_url.toString();
      URL.revokeObjectURL(temp_url);
      return uuid.substr(uuid.lastIndexOf('/') + 1);
    },
    computedWidth() {
      // 计算屏幕宽度适配UI
      const width = document.documentElement.clientWidth || document.body.clientWidth;
      if (width >= 1200) {
        console.log('大于1200');
        this.wideScreen = true;
      } else {
        console.log('小于1200');
        this.wideScreen = false;
      }
    },
    bindEvent() { // 绑定事件
      document.addEventListener('keydown', this.keydownEvent);
      window.addEventListener('resize', this.resizeEvent);
      this.scroll.addEventListener('scroll', this.scrollEvent);
      this.scroll.addEventListener('mouseover', () => { this.mouseInContainer = true; });
      this.scroll.addEventListener('mouseout', () => { this.mouseInContainer = false; });
    },
    unBindEvent() { // 解除绑定事件
      document.removeEventListener('keydown', this.keydownEvent);
      window.removeEventListener('resize', this.resizeEvent);
    },
    currentChange() { // 当前页改变
      if (this.localPlay) {
        this.loadLocalPage(this.current);
      } else {
        this.loadPage(this.current);
        this.loadPage(this.current - 1);
        this.loadPage(this.current + 1);
      }
    },
    loadPage(num) { // 加载指定页
      if (!num || num < 0 || num > this.count) { // num值不合法
        return;
      }
      const page = this.list[num - 1];
      if (page.status === 'canLoad') {
        page.status = 'loading';
      }
    },
    loadLocalPage(num) { // 加载指定页,混布播放用
      if (!num || num < 0 || num > this.count) { // num值不合法
        return;
      }

      const prevPage = this.list[num - 2];
      const nextPage = this.list[num];
      const currentPage = this.list[num - 1];
      const isWaiting = (page) => page && page.status === 'waiting';

      if (isWaiting(currentPage) || isWaiting(prevPage) || isWaiting(nextPage)) {
        const needLoadPage = [];
        if (isWaiting(currentPage)) {
          needLoadPage.push(num);
        }
        let preIndex = num - 1;
        let nextIndex = num + 1;
        while ((preIndex > 0 || nextIndex <= this.count) && needLoadPage.length < 5) {
          if (isWaiting(this.list[preIndex - 1])) {
            needLoadPage.push(preIndex);
          }
          if (isWaiting(this.list[nextIndex - 1])) {
            needLoadPage.push(nextIndex);
          }
          preIndex--;
          nextIndex++;
        }
        console.log('needLoadPage', needLoadPage);

        for (const idx of needLoadPage) {
          const page = this.list[idx - 1];
          if (isWaiting(page)) {
            page.status = 'pending';
          }
        }
        this.$nextTick(() => { this.throttleLoadImage(); });
      }
    },
    async loadImage() {
      if (!this.tempList) {
        try {
          const {playDetails} = await getFileList({
            fileId: this.fileId,
            serviceGroup: this.serviceGroup
          });
          this.tempList = playDetails;
          setTimeout(() => {
            this.tempList = undefined;
          }, cacheTime);
        } catch (error) {}
      }
      this.list.forEach((page, index) => {
        if (page.status === 'pending') {
          if (this.tempList && this.tempList.length > index) {
            page.url = this.tempList[index].url;
            page.status = 'loading';
          }
        }
      });
    },
    setWidth() { // 设置页面宽度
      const containerWidth = this.container.clientWidth;
      if (this.width && containerWidth > this.width) { // 容器宽度大于文档宽度，文档居中，显示缩放、全屏按钮
        this.pageWidth = this.width;
        this.setScaleWidth();
        this.$emit('zoom-visible-change', true);
      } else { // 容器宽度小于文档宽度，页面宽度100%，隐藏缩放、全屏按钮，缩放级别还原为0
        this.pageWidth = containerWidth;
        this.setScaleWidth(0);
        this.$emit('zoom-visible-change', false);
      }
    },
    setScaleWidth(value) { // 设置文档缩放宽度
      if (value !== undefined) {
        this.scaleWidth = value;
      } else {
        this.scaleWidth = Math.floor(this.pageWidth * (1 + this.scale * (this.scale > 0 ? 0.3 : 0.1)));
      }
    },
    imageLoad(e, item, preview = false) { // 图片加载成功
      if (this.placeholderHeight === 0) { // 首张图片加载成功，设置页面占位层高度为该图片高度
        !preview && (this.placeholderHeight = e.target.clientHeight);
        this.$nextTick(() => {
          this.navigate(this.current);
        });
      }
      item.status = 'loaded';
    },
    navigate(num, unNoticePage) { // 滚动到指定页
      const page = this.$refs[`page_${num}`][0];
      this.unNoticePage = unNoticePage;
      if (page && page.offsetTop !== void 0) {
        if (this.scroll.scrollTo) {
          this.scroll.scrollTo(0, page.offsetTop);
        } else {
          this.scroll.scrollTop = page.offsetTop;
        }
      }
    },
    scrollEvent(e) { // 滚动事件
      const page = this.$refs[`page_${this.current}`][0];
      if (page) {
        const pageLine = page.offsetTop + page.offsetHeight + 10 - (this.scroll.scrollTop + this.scroll.clientHeight / 2);
        if (pageLine < 0) { // 下滑一页
          this.nextPage();
        } else if (pageLine > page.offsetHeight + 10) {// 上滑一页
          this.prevPage();
        }
      }

      // 如果禁用了滚动页面变更通知，则在滚动结束后重置 unNoticePage
      if (this.unNoticePage) {
        if (this.scrollTimeout) {
          clearTimeout(this.scrollTimeout);
          this.scrollTimeout = null;
        } else {
          this.scrollTimeout = setTimeout(()=>{
            this.unNoticePage = null;
          }, 30);
        }
      }
    },
    keydownEvent(e) { // 键盘按下事件
      if (this.mouseInContainer) {
        e.preventDefault();
        if (e && e.keyCode === 38 || e && e.keyCode === 37) { // 上,左
          this.prevPage(true);
          return false;
        }
        if (e && e.keyCode === 40 || e && e.keyCode === 39) { // 下,右
          this.nextPage(true);
          return false;
        }
      }
    },
    prevPage(navigate) { // 上一页
      if (this.current === 1) return;
      const prev = this.current - 1;
      this.unNoticePage || this.$emit('currentChange', prev);
      navigate && this.navigate(prev);
    },
    nextPage(navigate) { // 下一页
      if (this.current === this.count) return;
      const next = this.current + 1;
      this.unNoticePage || this.$emit('currentChange', next);
      navigate && this.navigate(next);
    },
    resizeEvent() { // 窗口大小改变
      this.resizeTimeout && clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(this.setWidth, 50);
    }
  }
};
</script>
