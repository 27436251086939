var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-doc-viewer-ppt", on: { copy: () => false } },
    [
      _c(
        "div",
        {
          ref: "pages",
          staticClass: "yxtbiz-doc-viewer-ppt__pages",
          style: { width: _vm.scaleWidth ? _vm.scaleWidth + "px" : null },
        },
        [
          _c(
            "div",
            {
              staticClass: "yxtbiz-doc-viewer-ppt__placeholder",
              style: {
                height: this.pageHeight ? this.pageHeight + "px" : null,
                paddingBottom: _vm.count > 1 ? null : "10px",
                backgroundImage: _vm.loadingUrl
                  ? `url(${_vm.loadingUrl})`
                  : null,
              },
            },
            [_c("img", { attrs: { src: _vm.placeholderSrc, alt: "" } })]
          ),
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: `page_${index + 1}`,
                ref: `page_${index + 1}`,
                refInFor: true,
                staticClass: "yxtbiz-doc-viewer-ppt__page",
                class:
                  _vm.current === index + 1
                    ? "yxtbiz-doc-viewer-ppt__page--show"
                    : null,
                attrs: { "data-index": index },
              },
              [
                item.status === "loading" || item.status === "loaded"
                  ? _c("img", {
                      attrs: { src: item.url, alt: index + 1 },
                      on: {
                        load: (e) => {
                          _vm.imageLoad(e, item, index)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
          _vm.count > 1
            ? _c("yxt-slider", {
                attrs: { min: 1, max: _vm.count },
                model: {
                  value: _vm.silder,
                  callback: function ($$v) {
                    _vm.silder = $$v
                  },
                  expression: "silder",
                },
              })
            : _vm._e(),
          _c("yxtbiz-watermark", {
            attrs: {
              version: _vm.version,
              "app-code": _vm.appCode,
              option: _vm.watermarkConfig,
            },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }