var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-ai-project-assistant" }, [
    _c(
      "div",
      { ref: "aiRefs", staticClass: "yxtbiz-ai-project-assistant__main" },
      [
        _vm.showTitle
          ? _c(
              "div",
              { staticClass: "yxtbiz-ai-project-assistant__title" },
              [
                _c("yxt-svg", {
                  staticClass: "yxtbiz-ai-project-assistant__title-icon",
                  attrs: {
                    "remote-url": _vm.mediaPath,
                    "icon-class": "o2o-ai-project-assistant-avatar",
                  },
                }),
                _vm._v(
                  "\n   " +
                    _vm._s(
                      _vm.$t("pc_biz_ai_project_assistant" /* 智能项目助手 */)
                    ) +
                    "\n  "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.tipVisible
          ? _c(
              "div",
              { staticClass: "yxtbiz-ai-project-assistant__tip" },
              [
                _c("i", {
                  staticClass:
                    "yxt-icon-close yxtbiz-ai-project-assistant__tip-close",
                  on: { click: _vm.handleTipCloseClick },
                }),
                _c("ClampText", {
                  ref: "ClampText",
                  attrs: {
                    limitHeight: 44,
                    textClassName: "yxtbiz-ai-project-assistant__tip-text",
                    text: _vm.$t(
                      "pc_biz_ai_project_tip" /* 智能项目助手可以生成培训方案、设计项目名称等，建议描述你的培训目标、培训时间、培训对象、培训评估等培训相关信息，帮助项目助手更好的为你生成结果。 */
                    ),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("BuiltInQuestions", {
          staticClass: "yxtbiz-ai-project-assistant__question",
          on: { click: _vm.handleQuestionClick },
        }),
        _c("InputChat", {
          staticClass: "yxtbiz-ai-project-assistant__input-chat",
          attrs: { "send-disabled": _vm.sendDisabled },
          on: { send: _vm.handleSendClick },
          model: {
            value: _vm.inputVal,
            callback: function ($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal",
          },
        }),
        _vm.answerShow
          ? _c("div", { staticClass: "yxtbiz-ai-project-assistant__answer" }, [
              _c(
                "div",
                {
                  staticClass:
                    "yxtbiz-ai-project-assistant__outputting-title ellipsis",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.currentQuestion) + "\n      "
                  ),
                ]
              ),
              _c(
                "pre",
                {
                  staticClass:
                    "yxtbiz-ai-project-assistant__outputting-content",
                },
                [_vm._v("        " + _vm._s(_vm.currentAnswer) + "\n      ")]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm.operateShow
      ? _c(
          "div",
          {
            staticClass: "yxtbiz-ai-project-assistant__operate",
            style:
              _vm.qaStatus === "preparing"
                ? {
                    "border-top-left-radius": "4px",
                    "border-top-right-radius": "4px",
                  }
                : {},
          },
          [
            _c(
              "div",
              [
                ["preparing", "outputting"].includes(_vm.qaStatus)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "yxtbiz-ai-project-assistant__operate-outputting",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "yxtbiz-ai-project-assistant__outputting-text",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("pc_biz_outputting" /* 正在输出 */))
                            ),
                          ]
                        ),
                        _c("OutputtingDot"),
                      ],
                      1
                    )
                  : _vm.qaStatus === "outputted" && _vm.answerList.length > 1
                  ? _c("PageTurning", {
                      style: {
                        visibility:
                          _vm.qaStatus !== "outputted" ? "hidden" : "visible",
                      },
                      attrs: {
                        current: _vm.currentPage,
                        total: _vm.answerList.length,
                      },
                      on: { "update:current": _vm.handleCurrentPageChange },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "yxtbiz-ai-project-assistant__operate-button" },
              [
                ["preparing", "outputting"].includes(_vm.qaStatus)
                  ? _c("OperateButton", {
                      attrs: {
                        "icon-class": "yxt-icon-video-pause",
                        text: _vm.$t("pc_biz_stop" /* 停止 */),
                      },
                      on: { click: _vm.handleStopClick },
                    })
                  : _vm._e(),
                _vm.qaStatus === "outputted"
                  ? _c(
                      "OperateButton",
                      {
                        staticClass: "color-bff",
                        attrs: {
                          "icon-class": "yxt-icon-video-pause",
                          text: _vm.$t("pc_biz_build_project").d("生成项目"),
                        },
                      },
                      [
                        _c("yxt-svg", {
                          staticClass: "mr8 color-bff",
                          attrs: {
                            slot: "icon",
                            width: "16px",
                            height: "16px",
                            "icon-class": "language",
                          },
                          slot: "icon",
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.qaStatus !== "preparing"
                  ? _c("OperateButton", {
                      attrs: {
                        "icon-class": "yxt-icon-refresh",
                        text: _vm.$t("pc_biz_retry" /* 重试 */),
                      },
                      on: { click: _vm.handleRetryClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }