var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.firstLoad
    ? _c(
        "article",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxtbiz-ai-project-assistant-modal",
          staticStyle: { "padding-top": "2px" },
        },
        [
          _c(
            "header",
            { staticClass: "yxtbiz-ai-project-assistant-modal__drag-handle" },
            [
              _c(
                "div",
                { staticClass: "yxtbiz-ai-project-assistant-modal__title" },
                [
                  _c("yxt-svg", {
                    staticClass: "yxtbiz-ai-project-assistant-modal__avatar",
                    attrs: {
                      "remote-url": _vm.mediaPath,
                      "icon-class": "o2o-ai-project-assistant-avatar",
                    },
                  }),
                  _vm._v(
                    _vm._s(
                      _vm.$t("pc_biz_ai_project_assistant" /* 智能项目助手 */)
                    ) + "\n   "
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "yxtbiz-ai-project-assistant-modal__operate" },
                _vm._l(_vm.operateList, function (operate) {
                  return _c(
                    "yxt-tooltip",
                    {
                      key: operate.name,
                      attrs: {
                        disabled: !operate.tooltip,
                        content: operate.tooltip,
                        placement: "top",
                      },
                    },
                    [
                      _c("yxt-svg", {
                        staticClass:
                          "yxtbiz-ai-project-assistant-modal__operate-icon",
                        attrs: {
                          "remote-url": _vm.mediaPath,
                          "icon-class": operate.iconClass,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleOperateClick(operate)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          ),
          _c("yxt-divider", {
            staticClass: "yxtbiz-ai-project-assistant-modal__divider",
          }),
          _c("BaseAI", {
            ref: "BaseAI",
            staticClass: "yxtbiz-ai-project-assistant-modal__main",
            attrs: {
              highPriorityQuestionType: _vm.highPriorityQuestionType,
              "show-title": false,
            },
            on: { "answer-list-change": _vm.handleAnswerListChange },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }