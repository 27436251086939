var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-dropdown",
    {
      staticClass: "yxtbiz-ai-project-assistant-input-shortcut",
      attrs: { trigger: "click" },
      on: { command: ($event) => _vm.$emit("change", $event) },
    },
    [
      _c("yxt-svg", {
        staticClass:
          "color-primary-6 yxtbiz-ai-project-assistant-input-shortcut__icon",
        attrs: {
          "remote-url": _vm.mediaPath,
          "icon-class": "o2o-ai-project-asistant-shortcut",
          width: "24px",
          height: "24px",
        },
      }),
      _c(
        "yxt-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.dropDownItem, function (item, index) {
          return _c(
            "yxt-dropdown-item",
            { key: index, attrs: { command: item } },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }