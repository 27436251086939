import { fileApi } from 'yxt-biz-pc/packages/api';
const orgid = window.localStorage && window.localStorage.getItem('orgId');

export const fileUpload = (appCode, config, data) => {
  if (config.isV1) {
    return fileApi.post(`/yxt/upload/${config.orgCode}/${config.moduleName}/${config.funcName}${data}`);
  } else {
    fileApi.defaults.headers.appCode = appCode || 'demo';
    return fileApi.post(`/upload/${config.orgCode}/${config.moduleName}/${config.funcName}${data}`);
  }
};

// 获取机构混部的基础信息，机构是否只开启了只允许上传内网课件
export const getMixingInfo = () => {
  return fileApi.get(`/local/baseConfigByIdNew?orgid=${orgid}`);
};

export const setInitImg = (url, callback) => {
  let img = url;
  let imgRes;
  let userAgent = navigator.userAgent;
  let isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  if (isIE11) {
    getDataUrlBySrc(url).then(b64 => {
      imgRes = dataURLtoFile(b64, 'base64.png');
      callback(imgRes);
    });
  } else {
    getBase64(img, (dataURL) => {
      imgRes = dataURLtoFile(dataURL, 'base64.png');
      callback(imgRes);
    });
  }
};

function getDataUrlBySrc(src) {
  return new Promise((resolve, reject) => {
    const xmlHTTP = new XMLHttpRequest();
    xmlHTTP.open('GET', src, true);
    // 以 ArrayBuffer 的形式返回数据
    xmlHTTP.responseType = 'arraybuffer';
    xmlHTTP.onreadystatechange = function() {
      if (xmlHTTP.readyState === 4) { // 完成
        if (xmlHTTP.status >= 200 && xmlHTTP.status < 300 || xmlHTTP.status === 304) {
          // 1. 将返回的数据存储在一个 8 位无符号整数值的类型化数组里面
          const arr = new Uint8Array(xmlHTTP.response);
          // 2. 转为 charCode 字符串
          const raw = Array.prototype.map
            .call(arr, charCode => String.fromCharCode(charCode))
            .join('');
          // 3. 将二进制字符串转为 base64 编码的字符串
          const b64 = btoa(raw);
          const dataURL = 'data:image/jpeg;base64,' + b64;
          resolve(dataURL);
        } else {
          reject();
        }
      }
    };
    xmlHTTP.onerror = function(err) {
      reject(err);
    };
    xmlHTTP.send();
  });
}

function getBase64(url, callback) {
  // 通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
  let Img = new Image();
  let dataURL = '';
  Img.src = url + '?v=' + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
  Img.setAttribute('crossOrigin', 'Anonymous'); // 解决控制台跨域报错的问题
  Img.onload = function() {
    // 要先确保图片完整获取到，这是个异步事件
    let canvas = document.createElement('canvas');
    let width = Img.width; // 确保canvas的尺寸和图片一样
    let height = Img.height;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(Img, 0, 0, width, height); // 将图片绘制到canvas中
    dataURL = canvas.toDataURL('image/jpeg'); // 转换图片为dataURL
    callback ? callback(dataURL) : null; // 调用回调函数
  };
}

function dataURLtoFile(dataurl, filename) {
  // 将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
