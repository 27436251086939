/* Automatically generated by './build/bin/build-entry.js' */
import Amap from '../../packages/amap/index.js';
import DocViewer from '../../packages/doc-viewer/index.js';
import EnrollSettings from '../../packages/enroll-settings/index.js';
import ImageCropper from '../../packages/image-cropper/index.js';
import ImageViewer from '../../packages/image-viewer/index.js';
import Richeditor from '../../packages/richeditor/index.js';
import Watermark from '../../packages/watermark/index.js';
import CoursePlayer from '../../packages/course-player/index.js';
import AiProjectAssistant from '../../packages/ai-project-assistant/index.js';
import commonUtil from '../../packages/common-util';

const components = [
  Amap,
  DocViewer,
  EnrollSettings,
  ImageCropper,
  ImageViewer,
  Richeditor,
  Watermark,
  CoursePlayer,
  AiProjectAssistant
];

components.forEach(Component => {
  Component.mixins = Component.mixins || [];
  Component.mixins.push({
    created() {
      // 业务组件埋点统计
      // from：组件名
      // aspect：事件发生描述
      // version：组件库版本
      window.YxtFeLog && window.YxtFeLog.track('e_component', {
        properties: {
          from: Component.name,
          aspect: 'load',
          version: '1.17.14'
        }
      });
    }
  });
});

const setStaticCdnUrl = function(Vue) {
  try {
    if (Vue) {
      const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);
      Vue.prototype.$imagesBaseUrl = (baseCommon && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
      Vue.prototype.$staticBaseUrl = (baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      Vue.prototype.$isChinese = ['zh', 'ha'].includes(commonUtil.getLanguage());
    }
  } catch (e) {
    console.log(e);
  }
};

var installed = false;
const install = function(Vue, config = {}) {
  setStaticCdnUrl(Vue);

  if (installed) return;
  installed = true;
  components.forEach(component => {
    Vue.component(component.name, component);
  });
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const allModule = {
  Amap,
  DocViewer,
  EnrollSettings,
  ImageCropper,
  ImageViewer,
  Richeditor,
  Watermark,
  CoursePlayer,
  AiProjectAssistant
};

const exportModule = {
  version: '1.17.14',
  install,
  ...allModule
};

if (window.YXTBIZ) {
  Object.assign(window.YXTBIZ, allModule);
}

export default exportModule;
