var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-project-assistant-questions" },
    [
      _c(
        "div",
        { staticClass: "yxtbiz-ai-project-assistant-questions__title" },
        [
          _c("div", [
            _vm._v(_vm._s(_vm.$t("pc_biz_guess_your_interest" /* 可以试试 */))),
          ]),
          _c(
            "yxt-button",
            { attrs: { type: "text" }, on: { click: _vm.handleChangeClick } },
            [
              _c("i", {
                staticClass:
                  "yxt-icon-refresh yxtbiz-ai-project-assistant-questions__change",
              }),
              _vm._v(_vm._s(_vm.$t("pc_biz_change_up" /* 换一换 */))),
            ]
          ),
        ],
        1
      ),
      _c(
        "yxt-button",
        {
          staticClass: "yxtbiz-ai-project-assistant-questions__text",
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              return _vm.$emit("click", _vm.currentQuestion)
            },
          },
        },
        [_vm._v(_vm._s(_vm.currentQuestion))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }