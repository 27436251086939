const appname = window.feConfig.appName;
const base = Object.assign(
  {},
  window.feConfig['common'],
  window.feConfig[appname]
);

export const apiBaseUrl = module => {
  return base[module];
};

export const domainBaseUrl = module => {
  return base[module];
};

export default base;
