var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-container",
    [
      _c("yxt-aside", { attrs: { width: "524px" } }, [
        _c(
          "div",
          { staticClass: "yxtbiz-image-cropper__left" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.status == 3,
                    expression: "status==3",
                  },
                ],
                staticClass: "yxtbiz-image-cropper__left-preview",
              },
              [
                _c(
                  "yxt-image",
                  { attrs: { src: _vm.previewUrl, fit: "contain" } },
                  [
                    _c("div", {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error",
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "yxt-image",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.status != 3,
                    expression: "status != 3",
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "cropper-elm",
                staticClass: "yxtbiz-image-cropper__left-main",
                attrs: { src: _vm.imgUrl, fit: "contain" },
              },
              [
                !_vm.noUpdate
                  ? _c(
                      "yxtbiz-upload",
                      {
                        ref: "bizUpload",
                        staticClass: "mr10",
                        attrs: {
                          slot: "error",
                          "config-key": "ImageConfigKey",
                          appCode: _vm.appCode,
                          source: _vm.source,
                          "module-name": _vm.moduleName,
                          "function-name": _vm.functionName,
                          filters: _vm.filters,
                          "max-size": _vm.size,
                          "files-added": _vm.onFileAdded,
                          "file-filtered": _vm.onFileAdded,
                          "on-uploaded": _vm.onUploaded,
                          isV1: _vm.isV1,
                        },
                        slot: "error",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "yxtbiz-image-cropper__tips image-slot hand",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "pc_biz_imgcropper_tip_upload" /* 请上传图片 */
                                )
                              ) + "..."
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.status == 2,
                    expression: "status == 2",
                  },
                ],
                staticClass: "yxtbiz-image-cropper__left-coordinate",
              },
              [
                _vm._v(
                  _vm._s(_vm.coordinate.w) + "*" + _vm._s(_vm.coordinate.h)
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "yxt-main",
        { staticClass: "yxtbiz-image-cropper__main" },
        [
          _c("yxt-row", { staticClass: "yxtbiz-image-cropper__info" }, [
            _c("div", [
              _c("label", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("pc_biz_imgcropper_lbl_suggest" /* 建议尺寸 */)
                  ) + "："
                ),
              ]),
              _c("span", {
                staticClass: "color-primary-6",
                domProps: { textContent: _vm._s(_vm.suggestSize) },
              }),
            ]),
            _c("div", [
              _c("label", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(_vm.$t("pc_biz_imgcropper_lbl_filter" /* 格式 */)) +
                    "："
                ),
              ]),
              _c("span", { domProps: { textContent: _vm._s(_vm.filterStr) } }),
            ]),
            _c("div", [
              _c("label", { staticClass: "mr5" }, [
                _vm._v(
                  _vm._s(_vm.$t("pc_biz_imgcropper_lbl_size" /* 大小限制 */)) +
                    "："
                ),
              ]),
              _c("span", { domProps: { textContent: _vm._s(_vm.sizeStr) } }),
            ]),
          ]),
          _c(
            "yxt-row",
            { staticClass: "mt10" },
            [
              !_vm.noUpdate
                ? _c(
                    "yxtbiz-upload",
                    {
                      ref: "bizUpload",
                      staticClass: "mr8",
                      attrs: {
                        "config-key": "ImageConfigKey",
                        appCode: _vm.appCode,
                        source: _vm.source,
                        "module-name": _vm.moduleName,
                        "function-name": _vm.functionName,
                        filters: _vm.filters,
                        "max-size": _vm.size,
                        "files-added": _vm.onFileAdded,
                        "file-filtered": _vm.onFileAdded,
                        "on-uploaded": _vm.onUploaded,
                        isV1: _vm.isV1,
                      },
                    },
                    [
                      _c("yxt-button", {
                        attrs: {
                          type:
                            _vm.btnDisable.upload || _vm.status == 0
                              ? "primary"
                              : "",
                          disabled: _vm.btnDisable.upload,
                          plain: _vm.status > 0,
                        },
                        domProps: { textContent: _vm._s(_vm.uploadBtnStr) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.forceCrop && !_vm.isLocal
                ? _c("yxt-button", {
                    attrs: {
                      type:
                        _vm.btnDisable.crop || _vm.status == 1 ? "primary" : "",
                      disabled: _vm.btnDisable.crop,
                      plain: _vm.status != 1,
                    },
                    domProps: { textContent: _vm._s(_vm.cropBtnStr) },
                    on: { click: _vm.startOrResetCrop },
                  })
                : _vm._e(),
              !_vm.forceCrop
                ? _c("yxt-button", {
                    staticClass: "yxtbiz-image-cropper__btn-preview",
                    attrs: {
                      type:
                        _vm.btnDisable.preview || _vm.status == 2
                          ? "primary"
                          : "",
                      disabled: _vm.btnDisable.preview,
                      plain: _vm.status != 2,
                    },
                    domProps: { textContent: _vm._s(_vm.previewBtnStr) },
                    on: { click: _vm.showPreview },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.noRate
            ? _c(
                "yxt-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.status === 2 && !this.forceCrop,
                      expression: "status === 2 && !this.forceCrop",
                    },
                  ],
                  staticClass: "mt16",
                },
                [
                  _c(
                    "yxt-button-group",
                    [
                      _c(
                        "yxt-button",
                        {
                          attrs: {
                            size: "medium",
                            type: _vm.scaleType === 1 ? "primary" : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeScale(1)
                            },
                          },
                        },
                        [_vm._v("1:1")]
                      ),
                      _c(
                        "yxt-button",
                        {
                          attrs: {
                            size: "medium",
                            type: _vm.scaleType === 2 ? "primary" : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeScale(2)
                            },
                          },
                        },
                        [_vm._v("4:3")]
                      ),
                      _c(
                        "yxt-button",
                        {
                          attrs: {
                            size: "medium",
                            type: _vm.scaleType === 3 ? "primary" : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeScale(3)
                            },
                          },
                        },
                        [_vm._v("16:9")]
                      ),
                      _c(
                        "yxt-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.scaleType !== 4,
                              expression: "scaleType !== 4",
                            },
                          ],
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.changeScale(4)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "pc_biz_imgcropper_lbl_custom" /* 自定义 */
                              )
                            )
                          ),
                        ]
                      ),
                      _c("yxt-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scaleType === 4,
                            expression: "scaleType === 4",
                          },
                        ],
                        staticClass: "yxtbiz-image-cropper__number",
                        attrs: { size: "medium" },
                        on: { change: _vm.setCropBox },
                        model: {
                          value: _vm.cropBoxData,
                          callback: function ($$v) {
                            _vm.cropBoxData = $$v
                          },
                          expression: "cropBoxData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == 2,
                  expression: "status == 2",
                },
              ],
              staticClass: "yxtbiz-image-cropper__zoom",
            },
            [
              _c("div", { staticClass: "yxtbiz-image-cropper__zoom-minus" }, [
                _c("i", {
                  staticClass: "yxt-icon-zoom-in",
                  on: { click: _vm.zoomPlus },
                }),
              ]),
              _c("yxt-slider", {
                staticClass: "yxtbiz-image-cropper__zoom-slider",
                attrs: {
                  vertical: "",
                  min: 0,
                  max: 1,
                  step: 0.1,
                  height: "152px",
                  "show-tooltip": false,
                },
                on: { change: _vm.sliderChange },
                model: {
                  value: _vm.scale,
                  callback: function ($$v) {
                    _vm.scale = $$v
                  },
                  expression: "scale",
                },
              }),
              _c("div", { staticClass: "yxtbiz-image-cropper__zoom-plus" }, [
                _c("i", {
                  staticClass: "yxt-icon-zoom-out",
                  on: { click: _vm.zoomMinus },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }