var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-project-assistant-button" },
    [
      _c(
        "div",
        { on: { click: _vm.handleClick } },
        [
          _c("yxt-svg", {
            staticClass: "yxtbiz-ai-project-assistant-button__avatar",
            attrs: {
              "remote-url": _vm.mediaPath,
              "icon-class": "o2o-ai-project-assistant-avatar",
            },
          }),
          _vm._v(
            _vm._s(_vm.$t("pc_biz_ai_assistant" /* 智能助手 */)) + "\n    "
          ),
        ],
        1
      ),
      _c("ModalMode", {
        attrs: {
          ":highPriorityQuestionType": _vm.highPriorityQuestionType,
          zIndex: _vm.modalZIndex,
          visible: _vm.modalVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.modalVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }