import { enrollApi, sspApi } from 'yxt-biz-pc/packages/api';

export const getPointLevels = () => {
  return sspApi.get('/level/org');
};

export const getSettings = (id) => {
  return enrollApi.get('/component/projects/' + id);
};

export const saveSettings = (data) => {
  const params = { usebody: 1 };
  // 集团版增加入参
  if (data.groupFlag) {
    params.groupFlag = 1;
  }
  // 集团版增加创建机构Id入参
  if (data.createOrgId) {
    params.createOrgId = data.createOrgId;
  }
  if (data.id) {
    return enrollApi.put('/component/projects/' + data.id, data, {
      params
    });
  } else {
    return enrollApi.post('/component/projects', data, {
      params
    });
  }
};

export const getSts = () => {
  return enrollApi.get('/stss');
};

