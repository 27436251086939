<template>
  <component v-bind="$attrs" v-on="$listeners" :is="currentComponent" />
</template>

<script>
import BaseMode from './base/index.vue';
import ModalMode from './modal/index.vue';
import ButtonMode from './button/index.vue';
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default {
  name: 'YxtbizAiProjectAssistant',
  props: {
    mode: {
      type: String,
      validator: value => ['base', 'modal', 'button'].includes(value),
      default: 'button'
    }
  },
  components: {
    BaseMode,
    ModalMode,
    ButtonMode
  },
  data() {
    return {
    };
  },
  computed: {
    currentComponent: ({mode}) => `${capitalizeFirstLetter(mode)}Mode`,
    mediaPath: ({$staticBaseUrl}) => `${$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`
  }
};
</script>