<template>
  <div class="yxtbiz-ai-project-assistant-questions">
      <div class="yxtbiz-ai-project-assistant-questions__title">
        <div>{{$t('pc_biz_guess_your_interest' /* 可以试试 */)}}</div>
        <yxt-button @click="handleChangeClick" type="text"><i class="yxt-icon-refresh yxtbiz-ai-project-assistant-questions__change"></i>{{$t('pc_biz_change_up' /* 换一换 */)}}</yxt-button>
      </div>
      <yxt-button class="yxtbiz-ai-project-assistant-questions__text" @click="$emit('click', currentQuestion)" type="text">{{currentQuestion}}</yxt-button>
    </div>
</template>

<script>
import { getQuestionsOnTrainingContent } from './questions';
// import { getQuestionsOnTrainingName, getQuestionsOnTrainingPlan } from './questions';
// import { highPriorityQuestionTypes } from '../../common.js';

export default {
  inject: ['highPriorityQuestionType'],
  data() {
    this.questions = this.getCombinedQuestions();
    this.usedQuestions = [];
    return {
      currentQuestion: this.getRandomQuestion()
    };
  },
  methods: {
    getCombinedQuestions() {
      const questions = Object.keys(getQuestionsOnTrainingContent);
      // const questionsOnTrainingPlan = getQuestionsOnTrainingName();
      // const questionsOnTrainingName = getQuestionsOnTrainingPlan();
      // let questions = [];
      // switch (this.highPriorityQuestionType) {
      //   case highPriorityQuestionTypes.TRAINING_NAME:
      //     questions = [[...questionsOnTrainingName], [...questionsOnTrainingPlan]];
      //     break;
      //   case highPriorityQuestionTypes.TRAINING_PLAN:
      //     questions = [[...questionsOnTrainingPlan], [...questionsOnTrainingName]];
      //     break;
      //   default:
      //     questions = [[...questionsOnTrainingName, ...questionsOnTrainingPlan]];
      // }
      return [[...questions]];
    },
    handleChangeClick() {
      this.currentQuestion = this.getRandomQuestion();
    },
    getRandomQuestion() {
      if (this.questions.length === 0) {
        this.questions = [[...this.usedQuestions]];
        this.usedQuestions = [];
      }
      const randomIndex = Math.floor(Math.random() * this.questions[0].length);
      const question = this.questions[0].splice(randomIndex, 1)[0];
      if (this.questions[0].length === 0) {
        this.questions.shift();
      }
      this.usedQuestions.push(question);
      return question;
    }
  }
};
</script>

<style scoped lang="scss">
$root: '.yxtbiz-ai-project-assistant-questions';

#{$root} {
  &__title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;;

    & > :first-child {
      color: #757575;
      line-height: 22px;
    }
  }

  &__change {
    margin-right: 4px;
  }

  &__text {
    text-align: left !important;
    white-space: normal !important;
    margin-top: 8px;
  }
}
</style>