export const highPriorityQuestionTypes = {
  TRAINING_PLAN: 'trainingPlan',
  TRAINING_NAME: 'trainingName'
};

export const propsMixin = {
  props: {
    chatContext: { // ai对话背景
      type: String,
      default: '你是一个能自动帮助企业提供培训建议、制定培训计划的小助手。'
    },
    highPriorityQuestionType: {
      type: String,
      validator: value => !value || [highPriorityQuestionTypes.TRAINING_NAME, highPriorityQuestionTypes.TRAINING_PLAN].includes(value)
    }
  }
};
