<template>
   <yxt-dropdown class="yxtbiz-ai-project-assistant-input-shortcut" trigger="click" @command="$event => $emit('change', $event)">
        <yxt-svg
          :remote-url="mediaPath"
          icon-class="o2o-ai-project-asistant-shortcut"
          width="24px"
          height="24px"
          class="color-primary-6 yxtbiz-ai-project-assistant-input-shortcut__icon"
        ></yxt-svg>
        <yxt-dropdown-menu slot="dropdown">
        <yxt-dropdown-item :key="index" v-for="(item,index) in dropDownItem" :command='item'>{{item.name}}</yxt-dropdown-item>
      </yxt-dropdown-menu>
      </yxt-dropdown>
</template>

<script>
export default {
  data() {
    return {
      dropDownItem: [
        {
          command: 1,
          name: this.$t('pc_biz_generate_training_scheme' /* 生成培训方案 */)
        },
        {
          command: 2,
          name: this.$t('pc_biz_give_project_name' /* 起个项目名称 */)
        }
      ]
    };
  },
  computed: {
    mediaPath() {
      return `${this.$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`;
    }
  }
};
</script>
<style lang="scss" scoped>
$root: '.yxtbiz-ai-project-assistant-input-shortcut';
#{$root} {
  &__icon {
    border-radius: 4px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

</style>