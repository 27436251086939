<template>
<article v-if="!firstLoad" v-show="visible" class="yxtbiz-ai-project-assistant-modal" style="padding-top: 2px;">
  <header class="yxtbiz-ai-project-assistant-modal__drag-handle">
   <div class="yxtbiz-ai-project-assistant-modal__title">
     <yxt-svg
        :remote-url='mediaPath'
        icon-class="o2o-ai-project-assistant-avatar"
        class="yxtbiz-ai-project-assistant-modal__avatar"
     ></yxt-svg>{{$t('pc_biz_ai_project_assistant' /* 智能项目助手 */)}}
   </div>
   <div class="yxtbiz-ai-project-assistant-modal__operate">
     <yxt-tooltip :key="operate.name" v-for="operate in operateList" :disabled="!operate.tooltip" :content="operate.tooltip" placement="top">
      <yxt-svg
        @click.native="handleOperateClick(operate)"
        :remote-url='mediaPath'
        :icon-class="operate.iconClass"
        class="yxtbiz-ai-project-assistant-modal__operate-icon"
     ></yxt-svg>
    </yxt-tooltip>
   </div>
  </header>
  <yxt-divider class="yxtbiz-ai-project-assistant-modal__divider"></yxt-divider>
  <BaseAI ref="BaseAI" class="yxtbiz-ai-project-assistant-modal__main" :highPriorityQuestionType="highPriorityQuestionType" @answer-list-change="handleAnswerListChange" :show-title="false" />
</article>
</template>
<script>
import interact from 'interactjs';
import BaseAI from '../base/index.vue';
import {propsMixin} from '../common.js';

const CACHE_TOKEN = 'o2o-ai-project-assistant-modal';
const modalInitialSize = {
  width: 480,
  height: 600
};

export default {
  mixins: [propsMixin],
  components: {
    BaseAI
  },
  props: {
    visible: Boolean,
    zIndex: {
      type: Number,
      default: 200
    },
    newWindowUrl: String
  },
  data() {
    this.position = {x: 0, y: 0};
    return {
      firstLoad: true,
      extended: false,
      fullscreen: false
    };
  },

  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.firstLoad) {
            this.firstLoad = false;
            this.$nextTick(() => {
              this.appendToBody();
              this.addInteract();
              this.recoveryLastQa();
            });
          } else {
            this.createWebsocket();
          }
        }
      }
    }
  },
  methods: {
    createWebsocket() {
      this.$refs.BaseAI && this.$refs.BaseAI.createWebsocket();
    },
    updateClamp() {
      this.$refs.BaseAI && this.$refs.BaseAI.updateClamp();
    },
    handleOperateClick(operate) {
      const aMap = {
        closeModal: ()=> {
          this.$refs.BaseAI && this.$refs.BaseAI.detorySocket();
          this.$emit('update:visible', false);
        },
        extendModalVertically: () => {
          const modalDom = this.$el;
          Object.assign(this.position, {x: 0, y: 0});
          modalDom.style.width = `${modalInitialSize.width}px`;
          modalDom.style.height = '100%';
          modalDom.style.transform = 'translate(' + 0 + 'px,' + 0 + 'px)';
          this.extended = true;
          this.fullscreen = false;
          this.updateClamp();
        },
        lessenModalVertically: () => {
          this.resetModalOriginalState();
          this.extended = false;
          this.updateClamp();
        },
        fullscreen: () => {
          const {innerWidth, innerHeight} = window;
          const modalDom = this.$el;
          const x = -innerWidth / 10;
          const y = -innerHeight / 10;
          Object.assign(this.position, {x, y});

          modalDom.style.width = '80%';
          modalDom.style.height = '80%';
          modalDom.style.transform = `translate(${x}px, ${y}px)`;
          this.fullscreen = true;
          this.extended = false;
          this.updateClamp();
        },
        exitFullscreen: () => {
          this.resetModalOriginalState();
          this.fullscreen = false;
          this.updateClamp();
        }
      };
      aMap[operate.name]();
    },
    resetModalOriginalState() {
      const modalDom = this.$el;
      Object.assign(this.position, {x: 0, y: 0});
      modalDom.style.width = `${modalInitialSize.width}px`;
      modalDom.style.height = `${modalInitialSize.height}px`;
      modalDom.style.transform = 'translate(' + 0 + 'px,' + 0 + 'px)';
    },
    appendToBody() {
      const bodyDom = document.querySelector('body');
      bodyDom.appendChild(this.$el);
    },
    handleAnswerListChange() {
      this.cacheLastQa();
    },
    addInteract() {
      this.$el.style.zIndex = this.zIndex;
      const modalDom = this.$el;
      const position = this.position;
      interact(modalDom).draggable({
        inertia: true,
        maxPerElement: 2,
        allowFrom: '.yxtbiz-ai-project-assistant-modal__drag-handle',
        ignoreFrom: '.yxtbiz-ai-project-assistant-modal__operate-icon',
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'html',
            endOnly: true
          })],
        listeners: {
          move(event) {
            const x = position.x + event.dx;
            const y = position.y + event.dy;
            // 更新元素位置
            event.target.style.transform = `translate(${x}px, ${y}px)`;
            Object.assign(position, {
              x,
              y
            });
          }
        }
      })
        .resizable({
          maxPerElement: 2,
          inertia: true,
          edges: { left: true, right: true, bottom: true, top: true },
          modifiers: [
            interact.modifiers.restrictSize({ min: { ...modalInitialSize }, max: 'parent' })
            // interact.modifiers.restrictRect({
            //   restriction: 'html' // 限制区域后会导致元素处于边缘位置不能被缩小
            // }),
          ],
          listeners: {
            move(event) {
              const target = event.target;
              let {x, y} = position;
              target.style.width = `${event.rect.width}px`;
              target.style.height = `${event.rect.height}px`;
              x += event.deltaRect.right; // 右下定位处理
              y += event.deltaRect.bottom;
              target.style.transform = `translate(${x}px, ${y}px)`;
              Object.assign(position, {
                x,
                y
              });
            },
            end: (event) => {
              const {top} = event.target.getBoundingClientRect(); // 头部向上resize丢失后，用transform定位回来
              if (top < 0) {
                let {x, y} = position;
                y = y - top;
                event.target.style.transform = `translate(${x}px, ${y}px)`;
                Object.assign(position, {
                  x,
                  y
                });
              }
              this.updateClamp();
            }
          }
        });
    },
    recoveryLastQa() {
      const qa = this.getCacheLastQa();
      if (qa && qa.answerList && qa.answerList.length > 0) {
        this.$refs.BaseAI.setCurrentQa(qa);
      }
    },
    cacheLastQa() {
      const qa = this.$refs.BaseAI.getCurrentQa();
      localStorage.setItem(CACHE_TOKEN, JSON.stringify(qa));
    },
    getCacheLastQa() {
      return JSON.parse(localStorage.getItem(CACHE_TOKEN));
    }
  },
  computed: {
    mediaPath() {
      return `${this.$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`;
    },
    operateList() {
      const list = [];

      list.push(this.fullscreen
        ? {
          iconClass: 'ai-project-exit-fullscreen02',
          tooltip: this.$t('pc_biz_exit_maximization' /* 退出最大化 */),
          name: 'exitFullscreen'
        }
        : {
          iconClass: 'ai-project-fullscreen01',
          tooltip: this.$t('pc_biz_maximization' /* 最大化 */),
          name: 'fullscreen'
        });

      list.push(this.extended
        ? {
          iconClass: 'ai-project-assistant-less',
          tooltip: this.$t('pc_biz_exit_extension' /* 退出垂直最大化 */),
          name: 'lessenModalVertically'
        }
        : {
          iconClass: 'ai-project-assistant-extension',
          tooltip: this.$t('pc_biz_extension' /* 垂直最大化 */),
          name: 'extendModalVertically'
        });

      list.push({
        iconClass: 'ai-project-assistant-close',
        name: 'closeModal'
      });
      return list;
    }
  },
  beforeDestroy() {
    // 干掉body上的元素
    !this.firstLoad && document.querySelector('body').removeChild(this.$el);
  }
};
</script>

<style scoped lang="scss">
$root: '.yxtbiz-ai-project-assistant-modal';
#{$root} {
  height: 600px;
  width: 480px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 0;

  & > header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 20px 0 24px;
    user-select: none;
  }

  &__divider {
    margin: 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  &__operate {
    display: flex;
    align-items: center;


    &-icon {
      color: #8c8c8c;
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 4px;
      box-sizing: content-box;
      border-radius: 4px;


      &:hover {
        background: #F5F5F5;
      }

      &+& {
        margin-left: 8px;
      }
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  &__main {
    flex: 1;
    padding: 0 24px 24px;
  }

}

</style>