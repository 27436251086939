<template>
  <div
    v-if="enabled && version === 'v1'"
    :class="{ 'yxtbiz-watermark': true, 'yxtbiz-watermark--show': show }"
    :style="style"
  >
    <template v-if="isWatermark">
      <div
        v-for="(item, index) in watermarks"
        :key="index"
        class="yxtbiz-watermark__item"
        :style="item"
      >
        {{ option.text }}
      </div>
    </template>
    <div v-else class="yxtbiz-watermark__marquee" ref="marquee">
      {{ option.text }}
    </div>
  </div>
  <div
    v-else-if="enabledV2 && version === 'v2'"
    ref="watermarkRef"
    class="yxtbiz-watermark-v2"
  ></div>
</template>

<script>
import moment from 'moment';
import { getWatermarkSettings } from './service.js';
import WaterMarkCanvas from './watermark-canvas';
const cacheData = {};
export default {
  name: 'YxtbizWatermark',
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    version: {
      type: String,
      default: 'v1'
    },
    text: {
      // 水印文字
      type: String,
      default: ''
    },
    appCode: {
      type: String,
      default: ''
    },
    option: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: true
    },
    isShowHor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    enabled() {
      return this.option && this.option.enabled && !!this.option.text;
    },
    isWatermark() {
      return this.option && this.option.type === 1;
    },
    style() {
      const obj = {};
      if (this.option) {
        if (this.option.fontSize) {
          obj.fontSize = this.option.fontSize + 'px';
        }
        if (this.option.color) {
          obj.color = this.option.color;
        }
        if (this.option.opacity) {
          obj.opacity = this.option.opacity / 100;
        }
      }
      return obj;
    }
  },
  created() {
    if (this.version === 'v2') {
      this.getConfig();
    }
  },
  data() {
    return {
      enabledV2: true, // v2版本水印开关
      waterMarkCanvas: null, // 水印实例
      watermarks: [],
      waterMarkNameOpt: {
        DeptName: (localStorage.deptName && localStorage.deptName.split('->').pop()) || '',
        UserName: localStorage.username || '',
        CnName: localStorage.fullname || '',
        DateTime: moment().format('YYYY-MM-DD')
      }
    };
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.enabled && !this.isWatermark) {
          this.$nextTick(this.setAnimation);
        }
      }
    }
  },
  mounted() {
    if (this.version === 'v1') {
      if (this.enabled) {
        if (this.isWatermark) {
          if (this.$el.clientHeight > this.$el.clientWidth && !this.isShowHor) {
            // 竖向水印
            this.watermarks = [
              'top:16.7%;left:50%;',
              'top:50%;left:50%;',
              'top:83.3%;left:50%;'
            ];
          } else {
            // 横向水印
            this.watermarks = [
              'top:16.7%;left:16.7%;',
              'top:50%;left:50%;',
              'top:83.3%;left:83.3%;'
            ];
          }
        } else {
          this.marquee = this.$refs.marquee;
          this.marquee &&
            this.marquee.addEventListener(
              'webkitAnimationIteration',
              this.setAnimation
            );
          this.marquee &&
            this.marquee.addEventListener(
              'animationiteration',
              this.setAnimation
            );
        }
      }
    } else {
      if (this.enabledV2) {
        const watermarkRefDom = this.$refs.watermarkRef;
        this.observer = new ResizeObserver(this.handleResize);
        this.observer.observe(watermarkRefDom);
      }
    }
  },
  beforeDestroy() {
    if (this.enabled && !this.isWatermark) {
      this.marquee &&
        this.marquee.removeEventListener &&
        this.marquee.removeEventListener(
          'webkitAnimationIteration',
          this.setAnimation
        );
      this.marquee &&
        this.marquee.removeEventListener &&
        this.marquee.removeEventListener(
          'animationiteration',
          this.setAnimation
        );
    }
    if (this.observer) this.observer.disconnect();
  },
  methods: {
    setAnimation() {
      if (!this.marquee) return;
      if (this.show) {
        // marquee-1 左到右
        // marquee-2 右到左
        // marquee-3 上到下
        // marquee-4 左上到右下
        // marquee-5 右上到左下
        var n = Math.ceil(Math.random() * 5);
        if (n > 3) {
          this.marquee.style.top = this.marquee.style.left = 0;
        } else if (n === 3) {
          this.marquee.style.left =
            Math.random() * (this.$el.clientWidth - this.marquee.clientWidth) +
            'px';
        } else {
          this.marquee.style.top =
            Math.random() *
              (this.$el.clientHeight - this.marquee.clientHeight) +
            'px';
        }
        this.marquee.style.animation =
          'yxtpc-marquee-' + n + ' 10s linear infinite running';
      }
    },
    handleResize() {
      if (this.waterMarkCanvas) {
        this.waterMarkCanvas.draw();
      }
    },
    async getConfig() {
      const currOpts = this.option || {};
      if (currOpts.enabled === 0) {
        this.enabledV2 = false;
        return;
      }
      let res = null;

      if (this.id) {
        if (!cacheData[this.id]) {
          cacheData[this.id] = getWatermarkSettings();
        }
        res = await cacheData[this.id];
      } else {
        res = await getWatermarkSettings();
      }

      const config = {
        fontColor: currOpts.color || `#${res.otherColor}`,
        fontSize: currOpts.fontSize || res.otherFontSize,
        opacity: (currOpts.opacity || res.otherAlpha) / 100
      };
      const { DeptName, UserName, CnName, DateTime } = this.waterMarkNameOpt;
      const waterMarkText = res.valueRule.replace(/{DeptName}/, DeptName).replace(/{UserName}/, UserName).replace(/{CnName}/, CnName).replace(/{Date}/, DateTime);
      const currApp = res.fileWatermarkAppList.find(
        app => app.appCode === this.appCode
      );
      setTimeout(() => {
        const waterMarkCanvas = new WaterMarkCanvas({
          el: this.$refs.watermarkRef,
          platformType: 'pc',
          ...config
        }, 30);
        this.waterMarkCanvas = waterMarkCanvas;
        let drawOpt = {};
        if (currApp) {
          drawOpt = {
            density: typeof currOpts.density === 'number' ? currOpts.density : currApp.density,
            speed: typeof currOpts.speed === 'number' ? currOpts.speed : currApp.speed,
            randomRotate: currOpts.type === 0 || currApp.showType === 0
          };
          this.enabledV2 = !!(currOpts.enabled || currApp.enabled);
          if (!this.enabledV2) return;
        } else {
          // 加一条判断，如果拿不到接口数据，且自定义传参的没有强制开启水印（指的是enabled设置的为1，而不是未设置），那么水印不做显示
          if (currOpts.enabled !== 1) return;
          drawOpt = {
            randomRotate: currOpts.type === 0,
            density: typeof currOpts.density === 'number' ? currOpts.density : 0,
            speed: typeof currOpts.speed === 'number' ? currOpts.speed : 2
          };
        }
        waterMarkCanvas.draw({
          text: this.text || currOpts.text || waterMarkText,
          ...drawOpt
        });
        this.$emit('loaded');
      }, 30);
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-watermark-v2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
</style>
