var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.noDrawer
    ? _c(
        "Cropper",
        _vm._b(
          {
            ref: "cropper",
            attrs: { src: _vm.imgUrl },
            on: {
              loading: (v) => _vm.$emit("loading", v),
              "save-disable": (v) => _vm.$emit("disable", v),
              complete: _vm.onComplete,
            },
          },
          "Cropper",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_imgcropper_title" /* 上传图片 */),
            visible: _vm.visible,
            "before-close": _vm.handleClose,
            size: "960px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "Cropper",
            _vm._b(
              {
                ref: "cropper",
                attrs: { src: _vm.imgUrl },
                on: {
                  complete: _vm.onComplete,
                  loading: (v) => (_vm.loading = v),
                  "save-disable": (v) => (_vm.disable = v),
                },
              },
              "Cropper",
              _vm.$attrs,
              false
            )
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "yxt-button",
                { attrs: { plain: "" }, on: { click: _vm.handleClose } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_core_btn_cancel" /* 取消 */)))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    disabled: _vm.disable,
                  },
                  on: { click: () => this.$refs.cropper.save() },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_core_btn_confirm" /* 确定 */)))]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }