var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-doc-viewer-pdf",
      class: [
        _vm.preview ? "preview" : "",
        _vm.wideScreen ? "preview-width" : "",
        !_vm.previewSingleImg ? "multiple-image" : "",
      ],
      on: { copy: () => false },
    },
    [
      _c(
        "div",
        {
          staticClass: "yxtbiz-doc-viewer-pdf__pages",
          style: {
            width:
              _vm.list.length && _vm.list.length === 1 && _vm.previewSingleImg
                ? null
                : _vm.scaleWidth
                ? _vm.scaleWidth + "px"
                : null,
          },
        },
        [
          _c(
            "div",
            {
              ref: "scroll",
              staticClass: "yxtbiz-doc-viewer-pdf__scroll",
              class: [
                _vm.list.length && _vm.list.length === 1 && _vm.previewSingleImg
                  ? "preview"
                  : "preview-more",
              ],
            },
            [
              _vm.list.length
                ? _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index + "pdf",
                        ref: `page_${index + 1}`,
                        refInFor: true,
                        staticClass: "yxtbiz-doc-viewer-pdf__page",
                        class: [
                          _vm.previewSingleImg
                            ? "preview-single"
                            : "preview-multiple",
                        ],
                        attrs: { "data-index": index },
                      },
                      [
                        item.status === "loading" || item.status === "loaded"
                          ? [
                              _c("img", {
                                class: [
                                  _vm.previewSingleImg && _vm.wideScreen
                                    ? "img-width-screen"
                                    : "img-narrow-screen",
                                ],
                                style: {
                                  display:
                                    !_vm.placeholderHeight ||
                                    item.status === "loaded"
                                      ? "block"
                                      : "none",
                                  transform:
                                    "scale(" +
                                    _vm.initScale +
                                    ") rotateZ(" +
                                    _vm.imgRotate +
                                    "deg)",
                                },
                                attrs: { src: item.url, alt: index + 1 },
                                on: {
                                  load: function ($event) {
                                    return _vm.imageLoad($event, item, true)
                                  },
                                },
                              }),
                              !_vm.isMarquee && item.status === "loaded"
                                ? _c("yxtbiz-watermark", {
                                    attrs: {
                                      id: _vm.uuid,
                                      version: _vm.version,
                                      "app-code": _vm.appCode,
                                      option: _vm.watermarkConfig,
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        item.status !== "loaded"
                          ? _c("div", {
                              staticClass: "yxtbiz-doc-viewer-pdf__placeholder",
                              style: {
                                height: _vm.placeholderHeight
                                  ? _vm.placeholderHeight + 2 + "px"
                                  : null,
                                backgroundImage: _vm.loadingUrl
                                  ? `url(${_vm.loadingUrl})`
                                  : null,
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm.isMarquee
            ? _c("yxtbiz-watermark", {
                attrs: {
                  version: _vm.version,
                  "app-code": _vm.appCode,
                  option: _vm.watermarkConfig,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }