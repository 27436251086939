<template>
  <div class="yxtbiz-ai-project-assistant-operate-button" @click="$emit('click')">
    <slot name="icon" v-if="$slots.icon"></slot>
    <i v-else :class="[iconClass, 'yxtbiz-ai-project-assistant-operate-button__icon']"></i>
    {{text}}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.yxtbiz-ai-project-assistant-operate-button {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  color: #595959;
  padding: 0 12px;
  background-color: rgba(255,255,255,0.01);

  user-select: none;
  cursor: pointer;

  & + & {
    margin-left: 8px;
  }

  &__icon {
    margin-right: 8px;
    font-size: 16px;
  }

  &:hover {
    background: #E9E9E9;
  }
}
</style>