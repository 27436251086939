<template>
  <div class="yxtbiz-image-viewer__container">
    <ul v-show="!noList" ref="container" class="yxtbiz-image-viewer__image-wrapper">
      <li class="yxtbiz-image-viewer__slide"
          :class="[
            imageLiClass ? imageLiClass : '',
            previewLimit && index > previewLimit - 1 ? 'yxtbiz-image-viewer__slide--hide' : '',
            previewLimit && index === previewLimit - 1 ? 'yxtbiz-image-viewer__slide--last' : ''
          ]"
          v-for="(item, index) in data"
          :key="index">
        <img :data-original="item.url" fit="cover" :src="item.previewUrl || item.url" :alt="item.name || ''"/>
        <slot name="extra" :item="item" :index="index"></slot>
      </li>
    </ul>
    <div ref="toolbar" v-show="show && showToolbar" class="yxtbiz-image-viewer__toolbar">
      <div class="yxtbiz-image-viewer__viewer-toolbar">
        <div>
          <i data-viewer-action="play" class="yxtbiz-image-viewer__viewer-icon tool-fullscreen"></i>
          <span data-viewer-action="play">{{$t('pc_biz_imgviewer_btn_fullscreen'/* 全屏 */)}}</span>
        </div>
        <div @click="onReset">
          <i data-viewer-action="reset" class="yxtbiz-image-viewer__viewer-icon tool-reset"></i>
          <span data-viewer-action="reset">{{$t('pc_biz_imgviewer_btn_recover'/* 还原 */)}}</span>
        </div>
        <div>
          <i data-viewer-action="prev" class="yxtbiz-image-viewer__viewer-icon tool-prev"></i>
          <span data-viewer-action="prev">{{$t('pc_biz_imgviewer_btn_pre'/* 上一个 */)}}</span>
        </div>
        <div class="tool-scale">
          <i class="yxtbiz-image-viewer__viewer-icon tool-zoomIn" data-viewer-action="zoom-in"></i>
          <label ref="scaleText">100%</label>
          <i class="yxtbiz-image-viewer__viewer-icon tool-zoomOut" data-viewer-action="zoom-out"></i>
        </div>
        <div>
          <span data-viewer-action="next">{{$t('pc_biz_imgviewer_btn_next'/* 下一个 */)}}</span>
          <i data-viewer-action="next" class="yxtbiz-image-viewer__viewer-icon tool-next"></i>
        </div>
        <div>
          <i data-viewer-action="rotate-right" class="yxtbiz-image-viewer__viewer-icon tool-rotateR"></i>
          <span data-viewer-action="rotate-right">{{$t('pc_biz_imgviewer_btn_right'/* 右旋 */)}}</span>
        </div>
        <div>
          <i data-viewer-action="rotate-left" class="yxtbiz-image-viewer__viewer-icon tool-rotateL"></i>
          <span data-viewer-action="rotate-left">{{$t('pc_biz_imgviewer_btn_left'/* 左旋 */)}}</span>
        </div>
      </div>
    </div>
    <div ref="navbar" v-show="show && showNavbar">
      <i class="yxtbiz-image-viewer__viewer-icon yxtbiz-image-viewer__nav-prev" data-viewer-action="prev"></i>
      <div class="yxtbiz-image-viewer__nav-center">
        <div ref="navlist" class="yxtbiz-image-viewer__viewer-list viewer-transition">
          <div :class="activeIndex == index ? 'active' : ''" v-for="(item, index) in data" :key="index">
            <img data-viewer-action="view" :data-index="index" :src="item.previewUrl || item.url" :alt="item.name || ''" />
          </div>
        </div>
      </div>
      <i class="yxtbiz-image-viewer__viewer-icon yxtbiz-image-viewer__nav-next" data-viewer-action="next"></i>
    </div>
    <div ref="switchDiv" v-show="isFull" class="yxtbiz-image-viewer__player-switch">
      <i class="switch-prev" data-viewer-action="play" @click="fullscreenSwitch(-1)" @mouseleave="onMouseleave" @mouseenter="onMouseEnter"></i>
      <i class="switch-next" data-viewer-action="play" @click="fullscreenSwitch(1)" @mouseleave="onMouseleave"></i>
    </div>
  </div>
</template>

<script>
import Viewer from 'viewerjs';
import {
  PopupManager
} from 'yxt-pc';
let hideSwitchTimeout = null;
export default {
  name: 'YxtbizImageViewer',
  props: {
    data: {
      default: [],
      type: Array
    },
    imageLiClass: {
      type: String
    },
    previewLimit: {
      type: Number
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    showToolbar: {
      type: Boolean,
      default: true
    },
    showNavbar: {
      type: Boolean,
      default: true
    },
    noList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: 0,
      show: false,
      isFull: false,
      viewer: null
    };
  },
  mounted() {
    if (this.data && this.data.length) {
      // 空数组初始化的时候事件绑定有bug
      this.init();
    }
  },
  watch: {
    data() {
      if (this.viewer) {
        this.$nextTick(() => {
          this.viewer.update();
        });
      } else {
        this.$nextTick(() => {
          this.init();
        });
      }
    }
  },
  methods: {
    init() {
      this.viewer = new Viewer(this.$refs['container'], {
        url: 'data-original',
        navbar: false,
        toolbar: false,
        tooltip: false,
        title: this.showTitle,
        interval: 0,
        zIndex: PopupManager.nextZIndex(),
        ready: this.onReady,
        viewed: this.onViewed,
        zoomed: this.onZoomed,
        hidden: this.onHidden
      });
    },
    onReady() {
      // 添加云学堂的footer
      // 底部导航栏
      this.viewer.navbar.innerHTML = '';
      this.viewer.navbar.appendChild(this.$refs.navbar);
      if (!this.showNavbar) {
        this.viewer.navbar.style.display = 'none';
      }
      // 工具栏
      this.viewer.toolbar.innerHTML = '';
      this.viewer.scaleText = this.$refs.scaleText;
      this.viewer.toolbar.appendChild(this.$refs.toolbar);

      // 根据viewer.played属性赋值判断进入、退出全屏
      this.viewer.playIndex = -1;
      let viewerPlayed = false;
      const fullscreen = (v) => {
        this.setFullscreen(v);
      };
      Object.defineProperty(this.viewer, 'played', {
        get() {
          return viewerPlayed;
        },
        set(newValue) {
          viewerPlayed = newValue;
          fullscreen(newValue);
        }
      });
    },
    setFullscreen(status) {
      let hideSwitchTimeout = null;
      if (status) {
        // 全屏
        this.isFull = true;
        this.viewer.playIndex = this.viewer.index;
        document.addEventListener('keydown', this.fullscreenKeydown);

        // 添加全屏时的左右方向图标
        if (!this.viewer.playerSwitch) {
          this.viewer.playerSwitch = this.$refs.switchDiv;
          this.viewer.player.onmousemove = this.onMouseleave;
          this.viewer.viewer.appendChild(this.$refs['switchDiv']);
        }
      } else {
        // 退出全屏
        this.isFull = true;
        this.viewer.view(this.viewer.playIndex);
        this.viewer.playIndex = -1;
        hideSwitchTimeout && clearTimeout(hideSwitchTimeout);
        this.addClass(this.viewer.playerSwitch, 'viewer-hide');
        document.removeEventListener('keydown', this.fullscreenKeydown);
      }
    },
    onMouseEnter() {
      hideSwitchTimeout && clearTimeout(hideSwitchTimeout);
    },
    onMouseleave() {
      this.removeClass(this.viewer.playerSwitch, 'viewer-hide');
      hideSwitchTimeout && clearTimeout(hideSwitchTimeout);
      hideSwitchTimeout = setTimeout(() => {
        this.addClass(this.viewer.playerSwitch, 'viewer-hide');
      }, 3000);
    },
    onViewed() {
      if (this.viewer.canvas && this.viewer.canvas.parentNode) {
        // 更新z-index
        this.viewer.canvas.parentNode.style.zIndex = PopupManager.nextZIndex();
      }
      this.show = true;
      this.activeIndex = this.viewer.index;
      this.onZoomed();
      // 设置nav活动指示
      const count = this.viewer.length;
      if (count > 5) {
        let translate = 0;
        if (this.activeIndex < 3) {
          // 不往左移
          translate = 0;
        } else {
          if (this.activeIndex + 3 >= count) {
            // 左移到底
            translate = 5 - count;
          } else {
            translate = 3 - this.activeIndex - 1;
          }
        }
        this.$refs.navlist.style.transform = 'translateX(' + (translate * 60) + 'px)';
      }
    },
    onHidden() {
      this.isFull = false;
      this.show = false;
    },
    onReset() {
      this.$nextTick(() => {
        this.onZoomed();
      });
    },
    onZoomed() {
      let text = 100;
      if (this.viewer.imageData.naturalWidth) {
        text = Math.round(this.viewer.imageData.width * 100 / this.viewer.imageData.naturalWidth);
      }
      let scaleText = this.viewer.scaleText || this.$refs.scaleText;
      scaleText.innerHTML = text + '%';
    },
    fullscreenKeydown(event) {
      if (!this.viewer.played || this.viewer.playIndex === -1 || !(this.viewer.player && this.hasClass(this.viewer.player, 'viewer-show'))) {
        return;
      }
      switch (event.keyCode || event.which || event.charCode) {
        // ArrowLeft
        case 37:
          this.fullscreenSwitch(-1);
          break;
        // ArrowRight
        case 39:
          this.fullscreenSwitch(1);
          break;
        default:
          return;
      }
    },
    fullscreenSwitch(flag) {
      let newIndex = this.viewer.playIndex + flag;
      if (newIndex < 0) {
        newIndex = this.viewer.length - 1;
      } else if (newIndex >= this.viewer.length) {
        newIndex = 0;
      }
      this.removeClass(this.viewer.player.childNodes[this.viewer.playIndex], 'viewer-in');
      this.addClass(this.viewer.player.childNodes[newIndex], 'viewer-in');
      this.viewer.playIndex = newIndex;
    },
    hasClass(ele, cls) {
      return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    },
    addClass(ele, cls) {
      if (!this.hasClass(ele, cls)) ele.className += ' ' + cls;
    },
    removeClass(ele, cls) {
      if (this.hasClass(ele, cls)) {
        var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        ele.className = ele.className.replace(reg, ' ');
      }
    },
    view(index) {
      this.viewer && this.viewer.view(index);
    }
  },
  beforeDestroy() {
    if (this.viewer) {
      // 离开页面销毁实例
      this.viewer.destroy();
      this.viewer = null;
    }
  }
};
</script>
<style>
@import '~viewerjs/dist/viewer.css';
</style>