
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';

const getQuestionsTranslated = originQuestions => originQuestions.map(question => i18n.t(question));

export const getQuestionsOnTrainingPlan = () => getQuestionsTranslated([
  // 'pc_biz_ai_question0', // '希望针对制造业班组长组织一场培训，以提升他车间管理的综合技能，请帮我写一份培训计划'
  // 'pc_biz_ai_question1', // '希望针对刚入职的新员工组织一场培训，帮助他了解企业文化日常规章制度等，请帮我写一份培训计划'
  // 'pc_biz_ai_question2', // '希望针对零售业的门店店长组织一场培训，帮助他了解新上市的产品，请帮我写一份培训计划'
  // 'pc_biz_ai_question3', // 希望针对制造业车间员工组织一场安全生产培训，使他能够具备上岗要求，请帮我写一份培训计划
  // 'pc_biz_ai_question4', // 希望针对销售、客服岗位组织一场上岗话术培训，请帮我设计一下培训环节
  // 'pc_biz_ai_question5', // 希望针对企业的高管组织一场管理与领导力培训，请帮我写一份培训计划
  // 'pc_biz_ai_question6', // 目标：提高销售人员的销售技巧和业绩，根据目标生成培训计划
]);

export const getQuestionsOnTrainingName = () => getQuestionsTranslated([
  // 'pc_biz_ai_question7', // 将要组织一场新员工培训，帮我起一个具有吸引力不俗套的名字
  // 'pc_biz_ai_question8', // 将要组织一场党建培训，帮我起一个正式的名字
  // 'pc_biz_ai_question9' // 面向刚毕业入职的大学生组织一场培训，帮我起一个项目名字
]);

export const getQuestionsOnTrainingContent = {
  '想面向销售、CSM同学组织一场2.0 绚星产品功能培训，请帮忙设计一个培训项目': `培训主题： "绚星"产品2.0版本功能培训

  培训学员： 销售团队和客户成功经理（CSM）
  
  培训项目介绍：
  1. 产品简介与愿景：
       介绍"绚星"产品的核心理念和在市场中的定位。
       阐述新版本2.0的目标和更新背后的动机。
  2. 功能亮点介绍：
       详细介绍2.0版本的新功能，包括界面改进、新增功能模块等。
       通过演示和案例，展示每个新功能的具体作用和优势。
  3. 实际案例分析：
        提供实际销售和服务案例，让参与者思考如何在不同情境中应用2.0版本的功能。
  
  培训内容：
  阶段一：2.0企业大学平台讲解 
   1.【文档】2.0 平台综述 
  阶段二：我的企业
  1.【视频】我的企业 - 整体介绍 
  2.【视频】我的企业 - 企业信息
  3.【视频】我的企业 - 登录
  阶段三：平台运营
  1. 【视频】积分管理
  2. 【视频】学习设置
  3. 【视频】积分商城
  4. 【视频】学分管理
  阶段四：应用
  1. 【视频】社区
  2. 【视频】考试
  3. 【视频】讲师
  4. 【视频】培训
  阶段五：案例分析
  1. 【作业】实际情境案例分析
  阶段六：课后反馈
  1. 【调查】课后满意度评价问卷
  
  通过这样的培训项目，销售和CSM团队将能够深入了解"绚星"产品2.0版本的新功能，以更加有信心和专业的方式进行销售和客户服务。同时，实际案例将有助于将学到的知识应用于实际情境中。`,
  '想面向全体员工组织一场企业文化培训，请帮忙设计一个培训项目': `培训主题："共创企业文化，共铸美好未来" 企业文化培训

  培训对象：所有学员
  
  培训时间：半天至一天，根据内容深度和互动安排而定
  
  培训内容：
  阶段一：公司使命、愿景和价值观
  1. 【课程】云学堂企业文化第一课 - Peter讲企业文化
  
  阶段二：案例分享
  1. 【课程】云学堂企业文化故事
  
  阶段三：评估
  1. 【考试】价值观与行为准则测验
  2. 【调查】培训内容和互动反馈
  
  通过这样的培训项目，全体员工将能够更好地理解公司的核心价值观和企业文化，增强对公司的认同感，并将这些价值观融入到自己的工作中。这有助于营造积极向上的工作氛围，提升员工合作和创造力，进而为公司的长期发展贡献力量。`
};
