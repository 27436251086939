var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "text",
          class: [_vm.textClassName],
          style: {
            maxHeight: _vm.showAll ? `${_vm.limitHeight}px` : "none",
            overflow: "hidden",
          },
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _vm.showOperate
        ? _c(
            "yxt-button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.showAll = !_vm.showAll
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  this.$t(
                    _vm.showAll
                      ? "pc_biz_ai_expand_all" /* 展开全部 */
                      : "pc_biz_ai_retract" /* 收起 */
                  )
                )
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }